import React, { Component } from 'react';
import * as typeformEmbed from '@typeform/embed';
import Button from 'react-bootstrap/Button';

class RedistTypeform extends Component {

    componentDidMount(){
        const popup1 = typeformEmbed.makePopup(
            'https://admin656157.typeform.com/to/nkkX45',
            {
                mode: 'popup',
                autoClose: 3000,
                hideHeaders: true,
                hideFooters: true
            }
        )
        document.getElementById('bt-popup').addEventListener('click', function(){popup1.open()})
    }
    
    render() {
        return (
            <div>
                <Button variant='link' id='bt-popup' className="my-button">Question or issues?</Button>
            </div>
        )
    }
}

export default RedistTypeform;