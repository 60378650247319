import React from 'react';
import { connect } from 'react-redux';
import { Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import MenuConfig from "../../../_metronic/layout/MenuConfig.js";
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import { getUserReports } from '../../crud/report.crud';
import ReportEntry from './report-entry';
import Spinner from 'react-bootstrap/Spinner';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';

class MyReports extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      myReports: [],
      is_loading: true
    }
  }
  componentDidMount() {
    getUserReports()
      .then(res => {
        this.setState({
          myReports: res,
          is_loading: false
        })
      })
      .catch((error) => {
        // Handle error
        console.log('myreport comp error', error);
        console.log('error msg', error.message);
      });

    if(this.props.user.is_admin){
      this.props.setMenuConfig(AdminMenuConfig)
    } else {
      this.props.setMenuConfig(MenuConfig)
    }

    this.reloadInterval = setInterval(() => {
      window.location.reload();
    }, 1800000);
  
  }

  componentWillUnmount() {
    clearInterval(this.reloadInterval);
  }

  render() {
    if(this.state.is_loading){
      return(
        <div style={{position:'fixed', top:'50%', left:'50%', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Spinner animation='border' variant='info' style={{height:'60px', width:'60px'}}/>
        </div>
      )
    }

  const myReportList = this.state.myReports.length > 0 ? makeEntries(this.state.myReports) : <div><h2 style={{textAlign:'center'}}>You do not have any current projects</h2></div>
    return (
      <>
        <Container id='redist-container' fluid >
          {myReportList}
        </Container>
      </>
    );
  }
}

function makeEntries(reportsArr){
  const sortedArr = reportsArr.sort(function(a,b){
    return new Date(b.created_at) - new Date(a.created_at);
  });
  const result = sortedArr.map((e,i) => {
     if(e.compiled){
      return(
        <Link key={i} id='link-style' to={ '/project_id/' + e.id }>
          <ReportEntry
            key={i}
            reportNum={e.id}
            address={e.address}
            street_number ={e.street_number}
            route = {e.route}
            locality = {e.locality}
            sublocality = {e.sublocality}
            admin_lvl_1 = {e.admin_lvl_1}
            admin_lvl_2 = {e.admin_lvl_2}
            zcta = {e.zcta}
            borough = {e.borough}
            status={e.compiled}
            reportName = {e.project_name}
            requestedDate={e.created_at}
            point={e.point}
            programsCount={e.programs_count}
            censusTract={e.census_tract}
            outOfTristate={e.out_of_tristate}
          /> 
        </Link>)
        } else {
          return (
          <ReportEntry
            key={i}
            reportNum={e.id}
            address={e.address}
            street_number ={e.street_number}
            route = {e.route}
            locality = {e.locality}
            sublocality = {e.sublocality}
            admin_lvl_1 = {e.admin_lvl_1}
            admin_lvl_2 = {e.admin_lvl_2}
            zcta = {e.zcta}
            borough = {e.borough}
            status={e.compiled}
            reportName = {e.report_name}
            requestedDate={e.created_at}
            point={e.point}
            programsCount={e.programs_count}
            censusTract={e.census_tract}
            outOfTristate={e.out_of_tristate}
          /> )  
        }
  })
  return result
}

const mapStateToProps = state => {
  return {
  user: state.auth.user,
  builder: state.builder.menuConfig
  }
}

export default connect(mapStateToProps, builderActions)(MyReports);