export default {
    header: {
      self: {},
      items: [
        // {
        //   title: "Dashboards",
        //   root: true,
        //   alignment: "left",
        //   page: "dashboard",
        //   translate: "MENU.DASHBOARD"
        // }
        {
          title: 'Create Project',
          desc: 'Generates reports',
          root: true,
          page: 'create-project',
          icon: 'flaticon-edit-1'
        }
      ]
    },
    aside: {
      self: {},
      items: [
        // {
        //   title: "Dashboard",
        //   root: true,
        //   icon: "flaticon-home",
        //   page: "dashboard",
        //   translate: "MENU.DASHBOARD"
        // },
        {
          title: 'Create Project',
          desc: 'Generates reports',
          root: true,
          page: 'create-project',
          icon: 'flaticon-edit-1'
        },
        {
          title: 'Current Projects',
          desc: 'Some desc',
          root: true,
          page: 'current-projects',
          icon: 'flaticon-folder-1'
        },
        // {
        //   title: 'My Reports',
        //   desc: 'Some desc',
        //   root: true,
        //   page: 'my-reports',
        //   icon: 'flaticon-folder-1'
        // },
        // {
        //   title: 'Current Report',
        //   desc: 'nav to current report',
        //   root: true,
        //   icon: 'flaticon-file-2',
        //   page: 'current-report',
        //   focus: true
        // },
      ]
    }
  };
  