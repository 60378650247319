// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  // Register: "[Register] Action",
};

const initialAuthState = {
  authInitialized: false,
  user: undefined,
  authToken: undefined
};

export const reducer = 
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken} = action.payload;
        const { user } = action.payload;
        return { ...state, authToken, user, authInitialized: true };
      }

      // case actionTypes.Register: {
      //   const { authToken } = action.payload;

      //   return { authToken, user: undefined };
      // }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return { ...state, authInitialized: true };
      }

      default:
        return state;
    }
  }

export const actions = {
  login: (authToken, user) => ({ type: actionTypes.Login, payload: { authToken, user } }),
  // register: authToken => ({
  //   type: actionTypes.Register,
  //   payload: { authToken }
  // }),
  logout: () => ({ type: actionTypes.Logout })
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   // const { data: user } = yield getUserByToken();
  //   const name  = yield getUserByToken(store.getState().auth.authToken);

  //   yield put(actions.fulfillUser(name));
  // });

  // yield takeLatest(actionTypes.GetReports, function* getReportsSaga() {
  //   const reports = yield getUserReports(store.getState().auth.authToken);
  //   console.log('in saga for getrep', reports)
  //   yield put(actions.fillReports(reports));
  // });
}
