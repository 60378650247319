
export default {
    header: {
      self: {},
      items: [
        // {
        //   title: "Dashboards",
        //   root: true,
        //   alignment: "left",
        //   page: "dashboard",
        //   translate: "MENU.DASHBOARD"
        // }
        {
          title: 'Create Notion Project',
          desc: 'Generates Notion Project',
          root: true,
          page: 'generate-notion-project',
          icon: 'flaticon-upload-1'
        },
        {
          title: 'Create Project',
          desc: 'Generates reports',
          root: true,
          page: 'create-project',
          icon: 'flaticon-edit-1'
        }
      ]
    },
    aside: {
      self: {},
      items: [
        // {
        //   title: "Dashboard",
        //   root: true,
        //   icon: "flaticon-home",
        //   page: "dashboard",
        //   translate: "MENU.DASHBOARD"
        // },
        {
          title: 'Create Notion Project',
          desc: 'Generates Notion Project',
          root: true,
          page: 'generate-notion-project',
          icon: 'flaticon-upload-1'
        },
        {
          title: 'Create Project',
          desc: 'Generates reports',
          root: true,
          page: 'create-project',
          icon: 'flaticon-edit-1'
        },
        {
          title: 'Current Projects',
          desc: 'Some desc',
          root: true,
          page: 'current-projects',
          icon: 'flaticon-folder-1'
        },
        {
            title: 'Admin Interface',
            desc: 'edits programs',
            root: true,
            page: 'admin-interface',
            icon: 'flaticon-edit'
        }
      ]
    }
  };
  