import React, { Suspense} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Builder from "./Builder";
// import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import MyReports from './myReports';
import GenerateReport from './generate-report';
import GenerateNotionProject from './generate-notion-project';
// import Report from './report';
import ReportV1 from './reportV1';
import Program from './incentive';
import AdminInterface from './admin-interface';
import AdminProgram from './admin-program';
import AdminGeoTable from './admin-geo-table';
import adminUsers from "./admin-users";

// const GoogleMaterialPage = lazy(() =>
//   import("./google-material/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./react-bootstrap/ReactBootstrapPage")
// );

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/my-reports" />
          // <Redirect exact from="/" to="/dashboard" />
          <Redirect exact from="/" to="/generate-notion-project" />
        }
        {/* <Route path="/builder" component={Builder} /> */}
        {/* <Route path="/dashboard" component={Dashboard} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* <Route path="/docs" component={DocsPage} /> */}
        <Route path="/generate-notion-project" component={GenerateNotionProject}/>
        <Route path="/create-project" component={GenerateReport}/>
        <Route path="/current-projects" component={MyReports}/>
        <Route exact path="/project_id/:report_id/program_id/:program_id" component={Program}/>
        <Route exact path="/admin_program_id_view/:program_id" component={Program}/>
        <Route path="/project_id/:report_id" component={ReportV1} />
        {/* <Route path="/report_id/:report_id" component={Report} /> */}
        <Route exact path="/admin_program_id/:program_id" component={AdminProgram}/>
        <Route exact path="/admin_program_create/" component={AdminProgram}/>
        <Route exact path="/admin_geo_table_id/:program_id" component={AdminGeoTable}/>
        <Route exact path="/admin_users/" component={adminUsers}/>
        <Route path="/admin-interface" component={AdminInterface}/>
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}