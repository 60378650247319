import React from 'react';
// import { Link } from "react-router-dom";
import { createProjectParameters, createReportProjectParameters, getUserProjectParameters, deletePreset } from '../../crud/project.crud';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

class ProjectParameters extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            project_parameters: {},
            project_parameters_list: [],
            apply_project_parameter: null,
            preset_name: null,
            presetNameList: [],
            save_preset_show: false,
            apply_preset_show: false,
            additional_param_show: false,
            clear_param: false,
            type_empty: false,
            asset_empty: false,
        }
    }

    componentDidMount() {
        this.setState({
            project_parameters: this.props.project_parameters
        })

        getUserProjectParameters()
            .then(res => {
                this.setState({
                    project_parameters_list: res
                }) 
            })
            .catch(err => {
                console.log('err parameters', err.message)
            })
    }

    handleNameInputChange = (e) => {
        const eValue = e.target.value

        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                project_name: eValue
        }}))
    }

    handlePresetInputChange = (e) => {
        const eValue = e.target.value

        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                name: eValue
        }}))
    }

    handleSavePresetShowClick = () => {
        this.setState({
            save_preset_show: !this.state.save_preset_show
        })
    }

    handleApplyPresetShowClick = () => {
        this.setState({
            apply_preset_show: !this.state.apply_preset_show
        })
    }

    handlePresetSelect = (e) => {
        const eValue = e.target.value

        this.setState({
                apply_project_parameter: selectProjPara(eValue, this.state.project_parameters_list),
                preset_name: eValue
        })
    }

    handleApplyPresetSelect = () => {
        this.setState({
            project_parameters : this.state.apply_project_parameter,
            apply_preset_show: false,
        })
    }

    handleSavingPreset = () => {
    
        Promise.resolve().then(() => {    
            if(this.state.project_parameters.name === ''){
                alert('Please give your preset a name')
                return
            }
        })
        .then(() => {
            createProjectParameters(this.state.project_parameters)
            .then(res => {
                if(res){
                    console.log('Parameter successfully added')
                }
            })
            .catch(err => {
                console.log('error in handleProjectSubmitCreate:', err.message)
            }) 
            
        })
        .catch(err => {
            console.log('error in formatDataPromise:', err.message)
        }) 
    
        this.setState({
            save_preset_show: false,
        })

        setTimeout(() => {
            getUserProjectParameters()
            .then(res => {
                this.setState({
                    project_parameters_list: res
                }) 
            })
            .catch(err => {
                console.log('err parameters', err.message)
            })
        }, 2000)
    }

    handleMultiCheck = (e) => {
        const targetName = e.target.name
        const targetChecked = e.target.checked
        const eliArr = this.state.project_parameters['asset_class']
        let resArr = []

        if(targetChecked === false){
            resArr = eliArr.filter(wrd => wrd !== targetName)
        } else {
            resArr = eliArr
            resArr.push(targetName)
        }

        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                asset_class: resArr
            },
            clear_param: false,
            asset_empty: false
        }))
    }

    handleTypeChange = (e) => {
        const val = e

        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                type: [...val]
            },
            type_empty: false
        }))

    }

    handleOwnerAffHousingChange = (e) => {
        const val = e
        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                affordable_housing: val
            }
        }))

    }

    handleOwnerGreenChange = (e) => {
        const val = e
        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                green_building: val
            }
        }))
    }

    handleOwnerEnvChange = (e) => {
        const val = e
        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                env_remediation: val
            }
        }))
    }

    handleOwnerHistoricChange = (e) => {
        const val = e
        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                historic_preservation: val
            }
        }))
    }

    handleOwnerPublicChange = (e) => {
        const val = e
        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                public_non_profit_partner: val
            }
        }))
    }

    handleBenefiChange = (e) => {
        const val = e

        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                beneficiary: [...val]
            }
        }))
    }

    handleSwitch = (e) => {
        const checkedVal = e.target.checked
        const targetProp = e.target.value

        this.setState(prevState => ({
            project_parameters: {
                ...prevState.project_parameters,
                [targetProp]: checkedVal
            }
        }))
    }

    handleParamClear = () => {
        this.setState({
            project_parameters: newProjectParameters,
            clear_param: true,
            type_empty: false,
            asset_empty: false,
        })
    }

    handlePresetDelete = () => {
        const presetName = this.state.preset_name

        deletePreset(presetName)
            .then(res => {
            })
            .catch(err => {
            })
        
        setTimeout(() => {
            getUserProjectParameters()
                .then(res => {
                    this.setState({
                        project_parameters_list: res
                    }) 
                })
                .catch(err => {
                    console.log('err parameters', err.message)
                })
        },1000)
    }

    handleSubmit = () => {
        let pass = true

        if(this.state.project_parameters.type.length < 1){
            pass = false
            this.setState({
                type_empty: true
            })
        }
        if(this.state.project_parameters.asset_class.length < 1){
            pass = false
            this.setState({
                asset_empty: true
            })
        }
        
        if(pass){
            createReportProjectParameters(this.state.project_parameters, this.props.report_id)
            this.props.onHide()
            setTimeout(() => {
                this.props.paramPull()
            },500)
        }
    }

    handleMaxSliderChange = (event, newValue) => {
        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                affordable_max_percent_units: newValue
        }}))
    }
    handleMinSliderChange = (event, newValue) => {
        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                affordable_min_percent_ami: newValue
        }}))
    }

    handleMaxInputChange = (e) => {
        const eValue = e.target.value

        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                affordable_max_percent_units: eValue
        }}))
    }
    handleMinInputChange = (e) => {
        const eValue = e.target.value

        this.setState(prevState => ({
            project_parameters:{
                ...prevState.project_parameters,
                affordable_min_percent_ami: eValue
        }}))
    }

    handleMaxBlur = () =>{
        if (this.state.project_parameters.affordable_max_percent_units < 0) {
            this.setState(prevState => ({
                project_parameters:{
                    ...prevState.project_parameters,
                    affordable_max_percent_units: 0
            }}))
        } else if (this.state.project_parameters.affordable_max_percent_units > 100) {
            this.setState(prevState => ({
                project_parameters:{
                    ...prevState.project_parameters,
                    affordable_max_percent_units: 100
            }}))
        }
    }
    handleMinBlur = () =>{
        if (this.state.project_parameters.affordable_min_percent_ami < 0) {
            this.setState(prevState => ({
                project_parameters:{
                    ...prevState.project_parameters,
                    affordable_min_percent_ami: 0
            }}))
        } else if (this.state.project_parameters.affordable_min_percent_ami > 100) {
            this.setState(prevState => ({
                project_parameters:{
                    ...prevState.project_parameters,
                    affordable_min_percent_ami: 100
            }}))
        }
    }

    render() {
        const projectName = this.state.project_parameters.project_name ? this.state.project_parameters.project_name  : ''
        const projectAddress = this.props.address
        const savePresetShow = this.state.save_preset_show ? 'block' : 'none'
        const applyPresetShow = this.state.apply_preset_show ? 'block' : 'none'
        const projectParameters = this.state.project_parameters
        const projParaApplyList = this.state.project_parameters_list ? projParaList(this.state.project_parameters_list) : []
        const renderOtherCommTip = <Tooltip><p style={{textAlign: 'left'}}>Non-retail or office commercial properties (ie. Hotel, Special Purpose)</p></Tooltip>
        const renderOtherIndusTip = <Tooltip> <p style={{textAlign: 'left'}}>Non-manufacturing industrial properties (ie. Warehouse, Data Center)</p></Tooltip>
        const renderAffordHousingTip = <Tooltip>Income or rent restrictions</Tooltip>
        const renderMaxUnitsTip = <Tooltip>Indicate the lowest percentage of affordable units you are willing to support (%). Includes programs below this threshold</Tooltip>
        const renderMinAmiTip = <Tooltip>Indicate Minimum Area Median Income level you are willing to support (%). Includes programs above this threshold.</Tooltip>
        const renderGreenBuildingTip = <Tooltip>For energy-efficient and other environmentally-responsible construction</Tooltip>
        const renderEnvRemediTip = <Tooltip>For brownfields and contaminated sites requiring clean up</Tooltip>
        const renderHistoricPreTip = <Tooltip>For historic buildings and districts</Tooltip>
        const renderPublicTip = <Tooltip>Willingness to partner with government or nonprofit entity</Tooltip>
        const assetClassList = makeMultiTypeCheckbox(projectParameters, projectAssetClass, this.handleMultiCheck, renderOtherCommTip, renderOtherIndusTip)
        const typeEmptyMsg = this.state.type_empty ? <p style={{color:'red'}}>Selection required</p> : ''
        const assetEmptyMsg = this.state.asset_empty ? <p style={{color:'red'}}>Selection required</p> : ''
        const submitEmptyMsg = this.state.asset_empty || this.state.type_empty ? <p style={{color:'red', margin:0, fontSize:'13px'}}>*Please complete required selection(s) above</p> : ''

        return (
            <Modal
                size='lg'
                backdrop={this.props.backdrop}
                keyboard={false}
                show={this.props.show}
                onHide={this.props.onHide}
                address={this.props.address}
                report_id={this.props.report_id}
            >
                <Modal.Header closeButton={true}>
                </Modal.Header>
                <Modal.Body style={{padding: '1.25rem 0'}}>
                    <Form>
                        <div id='param-section'>
                            <Form.Group controlId="control-parameter-project_name" style={{display: 'flex', alignItems:'baseline'}}>
                                <Form.Label style={{minWidth: '200px'}}><b>Project Name</b></Form.Label>
                                <Form.Control ref='project_name' name="project_name" type="text" value={projectName} onChange={this.handleNameInputChange} placeholder={projectAddress} />
                            </Form.Group>

                            <Form.Group  controlId="control-parameter-project_type" style={{display: 'flex', alignItems:'baseline'}}>
                                <Form.Label style={{minWidth: '200px'}}><b>Type*</b><br/>{typeEmptyMsg}</Form.Label>
                                <ToggleButtonGroup type='checkbox' value={this.state.project_parameters.type} style={{width: '377px'}} onChange={this.handleTypeChange}>
                                    <ToggleButton variant='outline-info' value='New Construction'>New Construction</ToggleButton>
                                    <ToggleButton variant='outline-info' value='Renovation'>Renovation</ToggleButton>
                                    <ToggleButton variant='outline-info' value='Stabilized'>Stabilized</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_asset_class" style={{display:'flex'}}>
                                <Form.Label style={{minWidth: '200px', height: '20px'}}><b>Asset Class*</b><br/>{assetEmptyMsg}</Form.Label>
                                <div style={{height: '130px', width:'50%', display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
                                    {assetClassList}
                                </div>
                            </Form.Group>
{/* 
                            <Form.Group  controlId="control-parameter-project_status" style={{display: 'flex', alignItems:'baseline'}}>
                                <Form.Label style={{minWidth: '200px'}}><b>Commenced Work?</b><br/>{commencedEmptyMsg}</Form.Label>
                                <ToggleButtonGroup type="radio" name='project_status' value={this.state.project_parameters.status} onChange={this.handleStatusChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value='Post-development'>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value='Pre-development'>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group> */}
                        </div>

                        <div style={{paddingLeft: '1.25rem', margin: '20px 0', borderBottom:'1px solid #ebedf2'}}>
                            <h4 style={{margin:'0', fontSize:'16.9px'}}><b>Acceptable Project Obligations</b></h4>
                        </div>

                        <div id='param-section'>
                            <Form.Group controlId="control-parameter-project_affordable_housing" >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderAffordHousingTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Affordable Housing </b></Form.Label>
                                </OverlayTrigger>
                                <ToggleButtonGroup type="radio" name='affordable_housing' value={this.state.project_parameters.affordable_housing} onChange={this.handleOwnerAffHousingChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value={true}>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value={false}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_affordable_min_percent_ami" style={{display: 'flex', alignItems:'center', margin:'20px 0'}} >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderMinAmiTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Min % of Area Median Income</b></Form.Label>
                                </OverlayTrigger>
                                <InputGroup style={{width:'200px', marginLeft:'10px'}}>
                                    <div style={{display: 'flex', width: '100%'}}>
                                        <div style={{flex:2, marginRight:'20px'}}>
                                        <Slider
                                            style={{marginRight:'10px'}}
                                            value={parseInt(this.state.project_parameters.affordable_min_percent_ami)}
                                            onChange={this.handleMinSliderChange}
                                            max={170}
                                        />
                                        </div>
                                        <div style={{flex:1}}>
                                        <Input
                                            value={this.state.project_parameters.affordable_min_percent_ami}
                                            margin="dense"
                                            onChange={this.handleMinInputChange}
                                            onBlur={this.handleMinBlur}
                                            inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 170,
                                            type: 'number',
                                            }}
                                        />
                                        %
                                        </div>
                                    </div>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_affordable_max_percent_units" style={{display: 'flex', alignItems:'center', margin:'20px 0'}} >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderMaxUnitsTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Max % of Units Affordable</b></Form.Label>
                                </OverlayTrigger>
                                <InputGroup style={{width:'200px', marginLeft:'10px'}}>
                                    <div style={{display: 'flex', width: '100%'}}>
                                        <div style={{flex:2, marginRight:'20px'}}>
                                        <Slider
                                            name='affordable_max_percent_units'
                                            style={{marginRight:'10px'}}
                                            value={parseInt(this.state.project_parameters.affordable_max_percent_units)}
                                            onChange={this.handleMaxSliderChange}
                                            max={100}
                                        />
                                        </div>
                                        <div style={{}}>
                                        <Input
                                            value={this.state.project_parameters.affordable_max_percent_units}
                                            margin="dense"
                                            onChange={this.handleMaxInputChange}
                                            onBlur={this.handleMaxBlur}
                                            inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                            }}
                                        />
                                        %
                                        </div>
                                    </div>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_green_building" >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderGreenBuildingTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Green Building</b></Form.Label>
                                </OverlayTrigger>
                                <ToggleButtonGroup type="radio" name='green_building' value={this.state.project_parameters.green_building} onChange={this.handleOwnerGreenChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value={true}>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value={false}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_env_remediation" >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderEnvRemediTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Environmental Cleanup</b></Form.Label>
                                </OverlayTrigger>
                                <ToggleButtonGroup type="radio" name='env_remediation' value={this.state.project_parameters.env_remediation} onChange={this.handleOwnerEnvChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value={true}>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value={false}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_historic_preservation" >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderHistoricPreTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Historic Preservation</b></Form.Label>
                                </OverlayTrigger>
                                <ToggleButtonGroup type="radio" name='historic_preservation' value={this.state.project_parameters.historic_preservation} onChange={this.handleOwnerHistoricChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value={true}>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value={false}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_public_non_profit_partner" >
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={renderPublicTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Public or Non-Profit Partner</b></Form.Label>
                                </OverlayTrigger>
                                <ToggleButtonGroup type="radio" name='public_non_profit_partner' value={this.state.project_parameters.public_non_profit_partner} onChange={this.handleOwnerPublicChange} style={{width: '250px'}}>
                                    <ToggleButton variant='outline-info' value={true}>Yes</ToggleButton>
                                    <ToggleButton variant='outline-info' value={false}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_save_preset" style={{display: savePresetShow}}>
                                <Col md={8}>
                                <Card border='info'>
                                    <Card.Header as='h4'><b>Preset</b></Card.Header>
                                    <Card.Body>
                                        <div style={{display:'flex', alignItems: 'baseline', marginBottom:'20px'}}>
                                        <Form.Label style={{flex:'1'}}><b>Preset Name</b></Form.Label>
                                        <Form.Control type='text' ref='save_preset_name' name='save_preset_name' value={this.state.project_parameters.name} onChange={this.handlePresetInputChange} style={{flex:'2'}}/> 
                                        </div>
                                        <Button type='button' variant='outline-info' style={{float:'right'}} onClick={this.handleSavingPreset}>Save Preset</Button>
                                    </Card.Body>
                                </Card>
                                </Col>
                            </Form.Group>

                            <Form.Group controlId="control-parameter-project_apply_preset" style={{display: applyPresetShow}}>
                                <Col md={8}>
                                <Card border='info'>
                                    <Card.Header as='h4'><b> Apply Preset</b></Card.Header>
                                    <Card.Body>
                                        <div style={{display:'flex', alignItems: 'baseline', marginBottom:'20px'}}>
                                        <Form.Label style={{flex:'1'}}><b>Preset Name</b></Form.Label>
                                        <Form.Control as='select' ref='apply_preset_name' name='apply_preset_name' onChange={this.handlePresetSelect} style={{flex:'2'}}>
                                            <option>{''}</option>
                                            {projParaApplyList}
                                        </Form.Control> 
                                        </div>
                                        <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                        <Button type='button' variant='light' style={{marginRight: '10px'}} onClick={this.handlePresetDelete}><u>Delete</u></Button>
                                        <Button variant='outline-info' style={{width:'100px'}} onClick={this.handleApplyPresetSelect} >Apply</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </Col>
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent:'space-between'}}>
                    {/* fix saving preset and loading presets */}
                    <div>
                        {/* <Button type='button' onClick={this.handleSavePresetShowClick} style={{marginRight: '5px'}}>Save Preset</Button>
                        <Button type='button' onClick={this.handleApplyPresetShowClick}>Apply Preset</Button> */}
                        <Button type='button' variant='light' style={{marginRight: '10px'}} onClick={this.handleParamClear}><u>Clear Parameters</u></Button>
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                            {submitEmptyMsg}
                            <Button type='button' variant='info' style={{width:'100px', marginLeft:'10px'}} onClick={this.handleSubmit}>Submit</Button>
                    </div>
                </Modal.Footer>

            </Modal>
        );
    }
}

const newProjectParameters = {
    project_name: '',
    type: [],
    asset_class: [],
    affordable_housing: true,
    affordable_max_percent_units: 0,
    affordable_min_percent_ami: 0,
    green_building: true,
    env_remediation: true,
    historic_preservation: true,
    public_non_profit_partner: true
}

const projectAssetClass = ['Multifamily <50 units','Multifamily >50 units', 'Homeowner', 'Retail', 'Office', 'Other Commercial', 'Manufacturing', 'Other Industrial','Hospitality','Healthcare']

function makeMultiTypeCheckbox(state, arr, handleFunc, commTip, indusTip){
    let result = []

    arr.forEach(e => {
         if(state.asset_class && state.asset_class.includes(e)){
             if(e === 'Other Commercial'){
                 result.push(
                    <OverlayTrigger
                        placement='right'
                        delay={{show:250, hide:250}}
                        overlay={commTip}
                        key={e}>
                        <div style={{marginBottom: '3px'}}>
                            <Form.Check id={e} custom type='checkbox' name={e} label={e} checked={true} onChange={handleFunc} className='redistTip' style={{marginRight:'15px', height:'23px', width:'130px'}}/>
                        </div>
                    </OverlayTrigger>
                )
             } else if(e === 'Other Industrial'){
                result.push(
                   <OverlayTrigger
                       placement='right'
                       delay={{show:250, hide:250}}
                       overlay={indusTip}
                       key={e}>
                       <div style={{marginBottom: '3px'}}>
                           <Form.Check id={e} custom type='checkbox' name={e} className='redistTip' label={e} checked={true} onChange={handleFunc} style={{marginRight:'15px', height:'23px', width:'115px'}}/>
                       </div>
                   </OverlayTrigger>
               )
            } else {
                result.push(<Form.Check key={e} id={e} custom type='checkbox' name={e} label={e} checked={true} onChange={handleFunc} style={{marginRight:'15px'}}/>)
            }
        } else {
            if(e === 'Other Commercial'){
                result.push(
                   <OverlayTrigger
                       placement='right'
                       delay={{show:250, hide:250}}
                       overlay={commTip}
                       key={e}>
                       <div style={{marginBottom: '3px'}}>
                           <Form.Check id={e} custom type='checkbox' name={e} className='redistTip' label={e} checked={false} onChange={handleFunc} style={{marginRight:'15px', height:'23px', width:'130px'}}/>
                       </div>
                   </OverlayTrigger>
               ) 
            } else if(e === 'Other Industrial'){
                result.push(
                   <OverlayTrigger
                       placement='right'
                       delay={{show:250, hide:250}}
                       overlay={indusTip}
                       key={e}>
                       <div style={{marginBottom: '3px'}}>
                           <Form.Check id={e} custom type='checkbox' name={e} label={e} checked={false} onChange={handleFunc} className='redistTip' style={{marginRight:'15px', height:'23px', width:'115px'}}/>
                       </div>
                   </OverlayTrigger>
               ) 
            } else {
                result.push(<Form.Check key={e} id={e} custom type='checkbox' name={e} label={e} checked={false} onChange={handleFunc} style={{marginRight:'15px'}}/>)
            }
        }
    })
    return result
}

function projParaList(state){
    let res = []

    if(!state || state.length < 1){return res}
    
    state.forEach((e,i) => {
        res.push(<option key={i}>{e.name}</option>)
    })

    return res
}

function selectProjPara(name, state){
    let res = {}

    state.forEach(e => {
        if(e.name === name){
            res = e
        }
    })

    return res
}

export default ProjectParameters;
