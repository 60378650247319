import React from 'react';
import { connect } from 'react-redux';
import { actions as reportActions } from '../../store/ducks/report.duck';
// import { getUserReport, getReportPrograms } from '../../crud/report.crud';
import Card from 'react-bootstrap/Card'
import StaticMap from './static-map.js';
import ReportStatusBadge from './report-status-badge';
import Address from './address';
import '../../../_metronic/_assets/sass/pages/report/report-entry.scss';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


class ReportEntry extends React.Component{
    render(){
        if( !this.props || !this.props.point[0]){
            return null
        }

        const processed = this.props.status ? 'success': 'warning'
        const isOutOfTristate = this.props.outOfTristate
        const reqDate = new Date(this.props.requestedDate).toLocaleDateString()
        const entryLat = this.props.point[0]
        const entryLng = this.props.point[1]
        const truncLat = entryLat.length > 9 ? entryLat.slice(0,(9 - entryLat.length)) : entryLat
        const truncLng = entryLng.length > 9 ? entryLng.slice(0, (9 - entryLng.length)) : entryLng
        const programsCount = this.props.programsCount
        return (
            <>  
                {/* {processed === 'warning' && !isOutOfTristate &&
                    <>
                        <Card id='r-gen-card'>
                            <Card.Body>
                                <div className='card-container'>
                                    <div className='card-static-map'>
                                        <StaticMap
                                            staticLat={entryLat}
                                            staticLng={entryLng}
                                            staticZoom={'16'}
                                            staticHeight={'120px'}
                                        />
                                    </div>
                                    <div className='card-static-status'>
                                        <Card.Title>
                                            <h3>{this.props.reportName}</h3>
                                            <Address {...this.props}/>
                                        </Card.Title>
                                        <ReportStatusBadge
                                            status={this.props.status}
                                            programsCount={this.props.programsCount}
                                        />
                                    </div>
                                    <div className='card-static-info'>
                                        <div>
                                            <Card.Text as='h6' id='card-text--right-align'>CENSUS TRACT &nbsp; <b>{this.props.censusTract}</b></Card.Text>
                                            <Card.Text as='h6' id='card-text--right-align'>({truncLat}, {truncLng})</Card.Text>
                                        </div>
                                        <Card.Text as='h6' id='card-text--right-align'>REQUESTED ON {reqDate}</Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </>
                } */}
                { processed === 'success' && !isOutOfTristate &&
                    <>
                        <Card id='r-gen-card'>
                            <Card.Body>
                                <div className='card-container'>
                                    <div className='card-static-map'>
                                        <StaticMap
                                            staticLat={entryLat}
                                            staticLng={entryLng}
                                            staticZoom={'16'}
                                            staticHeight={'120px'}
                                        />
                                    </div>
                                    <div className='card-static-status'>
                                        <Card.Title>
                                            <h3>{this.props.reportName}</h3>
                                            <Address {...this.props}/>
                                        </Card.Title>
                                        <ReportStatusBadge
                                            status={this.props.status}
                                            programsCount={programsCount}
                                            variant={this.props.variant}
                                        />
                                    </div>
                                    <div className='card-static-info'>
                                        <div>
                                            {this.props.censusTract !== '' &&
                                                <Card.Text as='h6' id='card-text--right-align'>CENSUS TRACT &nbsp; <b>{this.props.censusTract}</b></Card.Text>
                                            }
                                            <Card.Text as='h6' id='card-text--right-align'>({truncLat}, {truncLng})</Card.Text>
                                        </div>
                                        <Card.Text as='h6' id='card-text--right-align'>REQUESTED ON {reqDate}</Card.Text>
                                    </div>
                                </div>  
                            </Card.Body>
                        </Card>
                    </>
                }
                {processed === 'warning' && isOutOfTristate &&
                    <>
                        <Card id='r-gen-card'>
                            <Card.Body>
                                <div className='card-container'>
                                    <div className='card-static-map'>
                                        <StaticMap
                                            staticLat={entryLat}
                                            staticLng={entryLng}
                                            staticZoom={'16'}
                                            staticHeight={'120px'}
                                        />
                                    </div>
                                    <div className='card-static-status'>
                                        <Card.Title>
                                            <h3>{this.props.reportName}</h3>
                                            <Address {...this.props}/>
                                        </Card.Title>
                                        <ReportStatusBadge
                                            status={this.props.status}
                                            programsCount={this.props.programsCount}
                                            isOutOfTristate={isOutOfTristate}
                                        />
                                    </div>
                                    <div className='card-static-info'>
                                        <div>
                                            <Card.Text as='h6' id='card-text--right-align'>({truncLat}, {truncLng})</Card.Text>
                                        </div>
                                        <Card.Text as='h6' id='card-text--right-align'>REQUESTED ON {reqDate}</Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </>
                }
            </>
        );
    }
}

export default connect(
    ({ report: { reports, programs } }) => ({ reports, programs }),
    reportActions
  )(ReportEntry);
