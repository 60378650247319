import React from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';
import { actions as authActions } from './store/ducks/auth.duck';
import { getUserByToken } from './/crud/auth.crud';

class AuthGate extends React.Component {
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.props.logout();
        return;
      }
      // Token of signed in user sent to server and varified with db firebase_id
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        // Send token to your backend via HTTPS
        return getUserByToken(idToken)
          .then(res => {
            this.props.login(idToken, res);
          })
      })
        .catch((error) => {
          // Handle error
          console.log('error', error);
          console.log('error msg', error.message);
        });
    })
  }

  render() {
    return this.props.authInitialized ? this.props.children : null;
  }
}

export default connect(
  ({ auth: { authInitialized } }) => ({ authInitialized }),
  authActions
)(AuthGate);