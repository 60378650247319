import React from 'react';
import { createEstimatorInput } from '../../crud/project.crud';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import InputGroup from 'react-bootstrap/InputGroup';

class Estimator extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            report_id: this.props.report_id,
            estimate_inputs: {},
            isEditing: {
                assess_value_land: false,
                assess_value_bldg: false,
                assess_value_total: false,
                prop_tax_total: false,
                prop_tax_land: false,
                morg_total: false,
                base_rent_annual: false,
                emp_cost: false,
                rd_exp: false,
                dev_cost_land: false,
                dev_cost_other_acq: false,
                dev_cost_hard: false,
                dev_cost_soft: false,
                dev_cost_total: false,
                proj_cost: false,
                dev_cost_mep_only: false,
                dev_cost_rehab_only: false,
                dev_cost_renew_only: false,
                env_cost_rem: false,
                env_cost_eng: false,
                afford_percent: false,
                afford_ami: false
            }
        }
    }
    componentDidMount(){
        let inputs = this.props.estimation_inputs
        let formatInputs = {}
        // console.log('input', inputs)
        for(let prop in inputs){
            if(inputs[prop] === null){
                formatInputs[prop] = ''
            } else {
                formatInputs[prop] = inputs[prop]
            }
        }

        if(formatInputs.lease_term === ''){
            formatInputs['lease_term_year'] = ''
            formatInputs['lease_term_month'] = ''
        } else {

            formatInputs['lease_term_year'] = convertMonths(formatInputs.lease_term).years
            formatInputs['lease_term_month'] = convertMonths(formatInputs.lease_term).months
            
        }
    
        this.setState({
            estimate_inputs: formatInputs
    })
    }

    handleEstimateInput = (e) =>{
        const target = e.target.name
        const value = e.target.value

        this.setState(prevState => ({
            estimate_inputs:{
                ...prevState.estimate_inputs,
                [target]: value
        }}))
    }

    handleLeaseTerm = (e) => {
        const target = e.target.name
        const value = e.target.value

        this.setState(prevState => ({
            estimate_inputs:{
                ...prevState.estimate_inputs,
                [target]: value
        }}))
    }

    handleParamClear = () => {
        this.setState({
            estimate_inputs: newEsimationInputs,
        })
        this.setState(prevState => ({
            estimate_inputs:{
                ...prevState.estimate_inputs,
                estimate_inputs: newEsimationInputs,
        }}))

    }

    handleToggleEditing = (e) => {
        const target = e.target.name

        this.setState(prevState => ({
            isEditing:{
                ...prevState.isEditing,
                [target]: !this.state.isEditing[target]
        }}))
    }

    handleToCurrency = (num) => {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    handleCalculate = () => {
        const assessLandValue = this.state.estimate_inputs.assess_value_land !== '' ? this.state.estimate_inputs.assess_value_land : 0
        const bldgValue = this.state.estimate_inputs.assess_value_bldg !== '' ? this.state.estimate_inputs.assess_value_bldg : 0
        const totalAssessedValue = Math.abs(parseInt(assessLandValue) + parseInt(bldgValue))
        const landValue = this.state.estimate_inputs.dev_cost_land !== '' ? this.state.estimate_inputs.dev_cost_land : 0
        const hardValue = this.state.estimate_inputs.dev_cost_hard !== '' ? this.state.estimate_inputs.dev_cost_hard : 0
        const acqValue = this.state.estimate_inputs.dev_cost_other_acq !== '' ? this.state.estimate_inputs.dev_cost_other_acq : 0
        const softValue = this.state.estimate_inputs.dev_cost_soft !== '' ? this.state.estimate_inputs.dev_cost_soft : 0
        const totalDevCost = Math.abs(parseInt(hardValue) + parseInt(acqValue) + parseInt(softValue))
        const totalProjcost = Math.abs(parseInt(landValue) + parseInt(totalDevCost))
        const termYear = this.state.estimate_inputs.lease_term_year !== '' ? this.state.estimate_inputs.lease_term_year : 0
        const termMonth = this.state.estimate_inputs.lease_term_month !== '' ? this.state.estimate_inputs.lease_term_month : 0
        const termInMonths = Math.abs((parseInt(termYear) * 12) + parseInt(termMonth))
        
        const data = this.state.estimate_inputs
        data.assess_value_total = totalAssessedValue
        data.dev_cost_total = totalDevCost
        data.proj_cost = totalProjcost
        data.lease_term = termInMonths
        data['afford_percent'] = this.props.project_parameters.affordable_max_percent_units
        data['afford_ami'] = this.props.project_parameters.affordable_min_percent_ami
        data['progList'] = this.props.progList
        data['report_id'] = this.props.report_id
        
        createEstimatorInput(data)
            .then(res => {
                this.props.passEstimation(res)
                this.props.onHide()
            })
            .catch(err => {
                console.log('error in createEstimaorInput', err);
                console.log('error msg', err.message);
            })
    }

    render() {
        // console.log('esti state', this.state)
        // console.log('proop', this.props)
        const estimatorTip = <Tooltip>Input project variables for an instant estimate of value for applicable programs. <br/> <br/> Estimator accuracy increases as more variables are input. It is recommended to include as many variables as possible.</Tooltip>
        const assessLandValue = this.state.estimate_inputs.assess_value_land !== '' ? this.state.estimate_inputs.assess_value_land : 0
        const bldgValue = this.state.estimate_inputs.assess_value_bldg !== '' ? this.state.estimate_inputs.assess_value_bldg : 0
        const totalAssessedValue = Math.abs(parseInt(assessLandValue) + parseInt(bldgValue))
        const landValue = this.state.estimate_inputs.dev_cost_land !== '' ? this.state.estimate_inputs.dev_cost_land : 0
        const hardValue = this.state.estimate_inputs.dev_cost_hard !== '' ? this.state.estimate_inputs.dev_cost_hard : 0
        const acqValue = this.state.estimate_inputs.dev_cost_other_acq !== '' ? this.state.estimate_inputs.dev_cost_other_acq : 0
        const softValue = this.state.estimate_inputs.dev_cost_soft !== '' ? this.state.estimate_inputs.dev_cost_soft : 0
        const totalDevCost = Math.abs(parseInt(hardValue) + parseInt(acqValue) + parseInt(softValue))
        const totalProjcost = Math.abs(parseInt(landValue) + parseInt(totalDevCost))
        const yearItems = sectionCommercialTenant()[1]
        const monthItems = sectionCommercialTenant()[0]
        const leaseTermYear = this.state.estimate_inputs.lease_term_year
        const leaseTermMonth = this.state.estimate_inputs.lease_term_month
        const softTip = <Tooltip>Excludes financing costs</Tooltip>
        const devTip = <Tooltip>Total Development Costs = Other Acquitision Costs + Hard Costs + Soft Costs</Tooltip>
        const projCostTip = <Tooltip>Total Project Cost = Land Cost + Total Development Cost</Tooltip>
        const mepTip = <Tooltip>Costs related to Mechanical, Electrical or Plumbing.</Tooltip>
        const rehabTip = <Tooltip>Costs associated with full rehab projects of historic properties.</Tooltip>
        const studyTip = <Tooltip>Studies related to site clean up or an energy audit.</Tooltip>
        const rdTip = <Tooltip>Equipment and salary costs related to the development of technology or a new product/process.</Tooltip>
        const employeeCountTip = <Tooltip>Full-time equivalents at project site post work</Tooltip>
        const avgWageTip = <Tooltip>Annual, full-time wage</Tooltip>

        return(
            <Modal
                dialogClassName="custom-modal-width"
                backdrop={this.props.backdrop}
                keyboard={false}
                show={this.props.show}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <OverlayTrigger
                            placement='right'
                            delay={{show:250, hide:400}}
                            overlay={estimatorTip}>
                            <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>REDISTIMATE Variables </b></Form.Label>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '1.25rem 0'}}>
                    <Form id='estimatorInputs' style={{padding: '0px 20px'}}>
                        <Row>
                            <Col md={6}>
                                <Card.Body>
                                    <h4><b>Building Details</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Residential Units</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <Form.Control ref='unit_ct' name='unit_ct' type='number' style={{flex:1}} value={this.state.estimate_inputs.unit_ct || ''} onChange={this.handleEstimateInput} ></Form.Control>
                                            <InputGroup.Append>
                                                <InputGroup.Text>units</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Gross Building Area</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <Form.Control name='sqft_gross' type='number' style={{flex:1}} value={this.state.estimate_inputs.sqft_gross || ''} onChange={this.handleEstimateInput} ></Form.Control>
                                            <InputGroup.Append>
                                                <InputGroup.Text>sq. ft.</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Green Roof Area</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <Form.Control name='sqft_green_roof' type='number' style={{flex:1}} value={this.state.estimate_inputs.sqft_green_roof || ''} onChange={this.handleEstimateInput}></Form.Control>
                                            <InputGroup.Append>
                                                <InputGroup.Text>sq. ft.</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                </Card.Body>

                                <Card.Body>
                                    <h4><b>Property Taxes</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Assessed Value -- Land</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.assess_value_land ? (
                                                <Form.Control 
                                                    name='assess_value_land' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.assess_value_land || ''} 
                                                    onChange={this.handleEstimateInput} 
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='assess_value_land' 
                                                    type='type' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.assess_value_land) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Assessed Value -- Building</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>

                                            { this.state.isEditing.assess_value_bldg ? (
                                                <Form.Control 
                                                    name='assess_value_bldg' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.assess_value_bldg || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='assess_value_bldg' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.assess_value_bldg) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'><b>Total Assessed Value</b></p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control disabled name='assess_value_total' type='text' style={{flex:1}} value={this.handleToCurrency(totalAssessedValue) || ''} onChange={this.handleEstimateInput}></Form.Control>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Property Taxes -- Total</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.prop_tax_total ? (
                                                <Form.Control 
                                                    name='prop_tax_total' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.prop_tax_total || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='prop_tax_total' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.prop_tax_total) || ''}
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Property Taxes -- Land only</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.prop_tax_land ? (
                                                <Form.Control 
                                                    name='prop_tax_land' 
                                                    type='number'
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.prop_tax_land || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='prop_tax_land' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.prop_tax_land) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                </Card.Body>

                                <Card.Body>
                                    <h4><b>Financing</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Conventional Interest Rate</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <Form.Control name='int_rate' type='number' placeholder='x.xx' style={{flex:1}} value={this.state.estimate_inputs.int_rate || ''} onChange={this.handleEstimateInput}></Form.Control>
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Mortgage Principal</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.morg_total ? (
                                                <Form.Control 
                                                    name='morg_total' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.morg_total || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='morg_total' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.morg_total) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                </Card.Body>

                                <Card.Body style={{maxWidth: '550px'}}>
                                    <h4><b>Commercial Tenant</b></h4>
                                    <div style={{display:'flex', alignItems: 'center'}}>
                                        <p className='estimateSectionTitle'>Lease Term</p>
                                        <div style={{flex:1, display:'flex'}}>
                                        <Form.Control name='lease_term_year' as='select' style={{maxMenuHeight:'200px'}} value={leaseTermYear || ''} onChange={this.handleLeaseTerm}>
                                            <option value="default" disabled >Years</option>
                                            {yearItems}
                                        </Form.Control>
                                        <Form.Control name='lease_term_month' as='select' style={{maxMenuHeight:'200px'}} value={leaseTermMonth || ''} onChange={this.handleLeaseTerm}>
                                            <option value="default" disabled>Months</option>
                                            {monthItems}
                                        </Form.Control>
                                        </div>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Annual Base Rent</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.base_rent_annual ? (
                                                <Form.Control 
                                                    name='base_rent_annual' 
                                                    type='number'
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.base_rent_annual || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing} 
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='base_rent_annual' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.base_rent_annual) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                </Card.Body>

                                <Card.Body>
                                    <h4><b>Employment / R&amp;D</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={employeeCountTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>Employee Count</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <Form.Control name='emp_count' type='number' style={{flex:1}} value={this.state.estimate_inputs.emp_count || ''} onChange={this.handleEstimateInput}></Form.Control>
                                            <InputGroup.Append>
                                                <InputGroup.Text>FTE</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={avgWageTip}>
                                                <p className='estimateSectionTitle'>Avg. Wages per Employee</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.emp_cost ? (
                                                <Form.Control 
                                                    name='emp_cost' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.emp_cost || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='emp_cost' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.emp_cost) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={rdTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>R&amp;D Costs</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.rd_exp ? (
                                                <Form.Control 
                                                    name='rd_exp' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.rd_exp || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='rd_exp' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.rd_exp) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                </Card.Body>
                            </Col>
                            <Col md={6}>
                                <Card.Body>
                                    <h4><b>Development / Rehabilitation Costs</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Land Costs</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_land ? (
                                                <Form.Control 
                                                    name='dev_cost_land' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_land || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_land' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_land) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Other Acquisition Costs</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_other_acq ? (
                                                <Form.Control 
                                                    name='dev_cost_other_acq' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_other_acq || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_other_acq' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_other_acq) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                    
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Hard Costs</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_hard ? (
                                                <Form.Control 
                                                    name='dev_cost_hard' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_hard || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_hard' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_hard) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={softTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>Soft Costs</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_soft ? (
                                                <Form.Control 
                                                    name='dev_cost_soft' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_soft || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_soft' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_soft) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={devTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}><b>Total Development Costs</b></p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control disabled name='dev_cost_total' type='text' style={{flex:1}} value={this.handleToCurrency(totalDevCost) || ''} onChange={this.handleEstimateInput}></Form.Control>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={projCostTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}><b>Total Project Cost</b></p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control disabled name='proj_cost' type='text' style={{flex:1}} value={this.handleToCurrency(totalProjcost) || ''} onChange={this.handleEstimateInput}></Form.Control>
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={mepTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>MEP Hard Costs</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_mep_only ? (
                                                <Form.Control 
                                                    name='dev_cost_mep_only' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_mep_only || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_mep_only' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_mep_only) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={rehabTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>Rehabilitation Costs</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_rehab_only ? (
                                                <Form.Control 
                                                    name='dev_cost_rehab_only' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_rehab_only || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_rehab_only' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_rehab_only) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Renewable / Efficiency Costs</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.dev_cost_renew_only ? (
                                                <Form.Control 
                                                    name='dev_cost_renew_only' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.dev_cost_renew_only || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='dev_cost_renew_only' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.dev_cost_renew_only) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                </Card.Body>

                                <Card.Body>
                                    <h4><b>Environmental Cleanup</b></h4>
                                    <div className='estimateSectionContainer'>
                                        <p className='estimateSectionTitle'>Remediation Costs</p>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.env_cost_rem ? (
                                                <Form.Control 
                                                    name='env_cost_rem' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.env_cost_rem || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='env_cost_rem' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.env_cost_rem) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                />
                                            )}
                                        </InputGroup>
                                    </div>

                                    <div className='estimateSectionContainer'>
                                        <div style={{display:'flex', flex:1, alignItems: 'center'}}>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={studyTip}>
                                                <p className='redistTip' style={{marginBottom: 0, marginRight:5}}>Engineering Study Costs</p>
                                            </OverlayTrigger>
                                        </div>
                                        <InputGroup className='estimateSectionInput'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            { this.state.isEditing.env_cost_eng ? (
                                                <Form.Control 
                                                    name='env_cost_eng' 
                                                    type='number' 
                                                    style={{flex:1}} 
                                                    value={this.state.estimate_inputs.env_cost_eng || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onBlur={this.handleToggleEditing}
                                                />
                                            ) : (
                                                <Form.Control 
                                                    name='env_cost_eng' 
                                                    type='text' 
                                                    style={{flex:1}} 
                                                    value={this.handleToCurrency(this.state.estimate_inputs.env_cost_eng) || ''} 
                                                    onChange={this.handleEstimateInput}
                                                    onFocus={this.handleToggleEditing}
                                                    readOnly
                                                /> 
                                            )}
                                        </InputGroup>
                                    </div>
                                </Card.Body>

                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent:'space-between'}}>
                    <div>
                        <Button type='button' variant='light' style={{marginRight: '10px'}} onClick={this.handleParamClear}><u>Clear Parameters</u></Button>
                    </div>
                    <div>
                        <Button type='button' variant='info' style={{width:'100px'}} onClick={this.handleCalculate}>Calculate</Button>
                    </div>
                </Modal.Footer>
            
            </Modal>
        );
    }
}

function sectionCommercialTenant(){
    const yearItems = []
    const monthItems = []
    const result = []
    let i;
    let j;

    for (i = 0; i < 31; i++) {yearItems.push(<option key={i+5}>{i}</option>)}
    for (j = 0; j < 13; j++) {monthItems.push(<option key={j+100}>{j}</option>)}

    result.push(yearItems)
    result.push(monthItems)

    return result
}

function convertMonths(m){
    let result = {
        years: 0,
        months: 0
    }

    if(m < 12){
        result.years = 0
        result.months = m
    } else if(m === 12){
        result.years = 1
        result.months = 0
    } else {
        result.years = parseInt(Math.abs(m/12))
        result.months = Math.abs(m%12)
    }

    return result
}

const newEsimationInputs = {
    unit_ct: '',
    sqft_gross: '',
    sqft_green_roof: '',
    assess_value_land: '',
    assess_value_bldg: '',
    assess_value_total: '',
    prop_tax_total: '',
    prop_tax_land: '',
    int_rate: '',
    morg_total: '',
    lease_term: '',
    lease_term_year:'',
    lease_term_month:'',
    base_rent_annual: '',
    emp_count: '',
    emp_cost: '',
    rd_exp: '',
    dev_cost_land: '',
    dev_cost_other_acq: '',
    dev_cost_hard: '',
    dev_cost_soft: '',
    dev_cost_total: '',
    proj_cost: '',
    dev_cost_mep_only: '',
    dev_cost_rehab_only: '',
    dev_cost_renew_only: '',
    env_cost_rem: '',
    env_cost_eng: '',
    afford_percent:'',
    afford_ami:''
}

export default Estimator
