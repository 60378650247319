import React from 'react';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import { getUserReport, getReportPrograms, getRelatedPrograms, getConflictingPrograms } from '../../crud/report.crud';
import { getUserReportProjectParameters, getRedistimateText, getEstimateInputs, createEstimatorInput, getEstimation } from '../../crud/project.crud';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { Calculator } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Switch from '@material-ui/core/Switch';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import MenuReportConfig from "../../../_metronic/layout/MenuReportConfig.js";
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import Accordion from 'react-bootstrap/Accordion';
import ProgramEntry from './program-entry';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import GoogleMap from './google-map';
import '../../../_metronic/_assets/sass/pages/report/report.scss';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import ProjectParameters from './project-parameters';
import Estimator from './estimator';
import ProgramModal from './program-modal';
import Address from './address';
import ProgramsToPrint from './programs-to-print';
import FilterListIcon from '@material-ui/icons/FilterList';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

class ReportV1 extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            project_parameters: newProjectParameters,
            estimation_inputs: {},
            estimations: {},
            redistimate_values: {},
            export_programs: [],
            hide_export: false,
            program_count_after_add: 0,
            progam_sections: [],
            modalShow: false,
            filter_tenant: false,
            filter_contractor: false,
            filter_inactive_programs: true,
            filter_competitive_programs: false,
            show_additional_filters: false,
            show_estimator: false,
            myReport: [],
			reportPrograms: [],
			filterQueryString: '',
            "Property Tax": false,
            "Federal Income Tax": false,
            "State Income Tax": false,
            "Business Tax": false,
            "Capital Gains Tax": false,
            "Sales & Use Tax": false ,
            "Mortgage Recording Tax": false,
            "Transfer Tax": false,
            "Grant": false,
            "Financing": false,
            "Custom Duty": false,
            "Permit Fee": false,
            "Utility Cost": false,
            "Zoning Bonus": false,
            "Bidding Priority": false,
            "Rebate": false,
            "Other Tax": false,
            "disqual_progs_section":false,
            "Property Tax Filtered": false,
            "Federal Income Tax Filtered": false,
            "State Income Tax Filtered": false,
            "Business Tax Filtered": false,
            "Capital Gains Tax Filtered": false,
            "Sales & Use Tax Filtered": false ,
            "Mortgage Recording Tax Filtered": false,
            "Transfer Tax Filtered": false,
            "Grant Filtered": false,
            "Financing Filtered": false,
            "Custom Duty Filtered": false,
            "Permit Fee Filtered": false,
            "Utility Cost Filtered": false,
            "Zoning Bonus Filtered": false,
            "Bidding Priority Filtered": false,
            "Rebate Filtered": false,
            "Other Tax Filtered": false,
            repProgramData: this.props,
            is_loading: true,
            programModalShow: false,
            programModalData: {
                eligible_project_status:[]
            }
        }
    }
    componentDidMount() {
        const paramRep_id = this.props.match.params.report_id 

        getRedistimateText()
        .then(res => {
            this.setState({
                redistimate_values: res
            })
        })
        .catch(err => {
            console.log('redistimate text err: ', err.message)
        })

        this.requestReportData(paramRep_id)

        if(this.props.user.is_admin){
            this.props.setMenuConfig(AdminMenuConfig)
          } else {
            this.props.setMenuConfig(MenuReportConfig)
          }
        
          
		this.firstTimeout = setTimeout(()=> {
			this.parseQuery(this.props.location.search)
        },1000)
        
        this.reloadInterval = setInterval(() => {
            window.location.reload();
          }, 1800000);
    }

    componentWillUnmount() {
        clearInterval(this.firstTimeout);
        clearInterval(this.reloadInterval)
    }

    requestReportData = (paramRep_id) => {

        Promise.all([getUserReport(paramRep_id),getReportPrograms(paramRep_id),getUserReportProjectParameters(paramRep_id), getEstimateInputs(paramRep_id)])
            .then((res) => {
                let estimateInputs;

                if(res[3]){
                    estimateInputs = res[3]
                    estimateInputs['report_id'] = paramRep_id
                    estimateInputs['progList'] = finalProgIdList(newFilterData(res[2][0],res[1]))
                    estimateInputs['afford_percent'] = res[2][0].affordable_max_percent_units
                    estimateInputs['afford_ami'] = res[2][0].affordable_min_percent_ami

                    return getEstimation(estimateInputs)
                        .then(estimations => {
                            if(res[0] && res[1] && res[1].length > 0 && res[2] && res[2].length > 0 && res[2][0].asset_class.length > 0){
                                this.setState({
                                    myReport: res[0],
                                    reportPrograms: res[1],
                                    project_parameters:res[2][0],
                                    is_loading: false,
                                    estimations: estimations,
                                    estimation_inputs: estimateInputs
                                })
                            } else {
                                setTimeout(() => {
                                    if(this.state.is_loading){
                                        this.requestReportData(paramRep_id)
                                    }
                                }, 7000)
                            }
                        })
                } else {
                    estimateInputs = newEsimationInputs
                    estimateInputs['report_id'] = paramRep_id
                    estimateInputs['progList'] = finalProgIdList(newFilterData(res[2][0],res[1]))
                    estimateInputs['afford_percent'] = res[2][0].affordable_max_percent_units
                    estimateInputs['afford_ami'] = res[2][0].affordable_min_percent_ami
    
                    return createEstimatorInput(estimateInputs)
                        .then(estimations => {
                            if(res[0] && res[1] && res[1].length > 0 && res[2] && res[2].length > 0 && res[2][0].asset_class.length > 0){
                                this.setState({
                                    myReport: res[0],
                                    reportPrograms: res[1],
                                    project_parameters:res[2][0],
                                    is_loading: false,
                                    estimations: estimations,
                                    estimation_inputs: estimateInputs
                                })
                            } else {
                                setTimeout(() => {
                                    if(this.state.is_loading){
                                        this.requestReportData(paramRep_id)
                                    }
                                }, 7000)
                            }
                        })
                }
            })
            .catch(err => {
                console.log('v1 promise all err: ', err.message)
            })
    }

    //builds url query for filters
	buildQuery = () => {
        const paramArr = []

        if(this.state.filter_tenant === true) {
            paramArr.push('filter_tenant')
        }

        if(this.state.filter_contractor === true) {
            paramArr.push('filter_contractor')
        }

        if(this.state.filter_competitive_programs === true) {
            paramArr.push('filter_competitive_programs')
        }

        if(this.state.filter_inactive_programs === true) {
            paramArr.push('filter_inactive_programs')
        }

        const query = paramArr.join('+')

		this.props.history.push({
			search: query,	
			state: undefined
		})
	}

	parseQuery = (qStr) => {
		qStr = qStr.substring(1);
		const filterArr = qStr.split('+')

		filterArr.forEach((f)=> {
            if(f === 'filter_tenant'){
                this.setState({
                    filter_tenant: true
                })
            }

            if(f === 'filter_contractor'){
                this.setState({
                    filter_contractor: true
                })
            }

            if(f === 'filter_competitive_programs'){
                this.setState({
                    filter_competitive_programs: true
                })
            }

            if(f === 'filter_inactive_programs'){
                this.setState({
                    filter_inactive_programs: true
                })
            }
		})
	}


    handleShowModal = () =>{
        this.setState({
          modalShow: true
        })
    }
  
    //upon canceling report creating process, onhide will trigger, reseting the form and necessary values
    handleHideModal = () => {
        this.setState({
            modalShow: false,
        })
    }


    handleSwitch = (e) => {
        const checkedVal = e.target.checked
        const targetProp = e.target.value

        Promise.resolve().then(() => {
            this.setState({
                [targetProp]: checkedVal
            })
        })
        .then(() => {
            this.buildQuery()
        })
        .catch((err) => {
            console.log(err.message)
        })
    }

    handleShowAdditionalFilters = () => {
        this.setState({
            show_additional_filters: true
        })
    }

    handleHideAdditionalFilters = () => {
        this.setState({
            show_additional_filters: false
        })
    }

    handleShowEsitmator = () => {
        this.setState({
            show_estimator: true
        })
    }

    handleHideEsitmator = () => {
        this.setState({
            show_estimator: false
        })
    }

    handleSectionArrows = (e) => {
        const section = e.target.id

        this.setState({
            [section] : !this.state[section]
        })
    }

    handleExport = () => {
        this.setState({
            hide_export: !this.state.hide_export
        })
    }

    handleParamPull = () => {
        const paramRep_id = this.props.match.params.report_id 
        getUserReportProjectParameters(paramRep_id)
            .then(res => {
                const [data] = res
                this.setState({
                    project_parameters: data
                })
            })
            .catch(err => {
                // Handle error
                console.log('report proj param error 2', err.message);
            })
    }

    handleProgramExportSelect = (e) => {
        const prog = e.target.id
        let exportProgs = this.state.export_programs
        let filterProg = (e) => {return e !== prog }

        if(exportProgs.includes(prog)){
            let newExportProgs = exportProgs.filter(filterProg)
            console.log('removing prog from arr', newExportProgs)
            this.setState({
                export_programs: newExportProgs
            })
        } else {
            this.setState(prevState => ({
                    export_programs: [...prevState.export_programs, prog]
            }))
        }
    }

    handlePassedEstimation = (obj) => {
        this.setState({
            estimations: obj
        })

    }

    handleProgramModalData = (p) => {
        const program_id = p.id
        let pResult = p

        Promise.all([getRelatedPrograms(program_id), getConflictingPrograms(program_id)])
            .then( res => {
            
                let relData  = []
                let conData  = []

                res[0].forEach(e => {
                    relData.push(e.program_name)
                })

                res[1].forEach(e => {
                    conData.push(e.program_name)
                })

                pResult.program_related = relData
                pResult.program_conflict = conData

                this.setState({
                    programModalShow: true,
                    programModalData: pResult
                })

            })
            .catch( error => {
                console.log('err in make rel con Req v1:', error.message)
            })
    }

    handleHideProgramModal = () => {
        this.setState({
            programModalShow: false,
        })
    }

    componentDidUpdate(){
        clearTimeout(this.timeout)
    }

    render() {
        if(!this.state.myReport[0] || !this.state.reportPrograms || this.state.reportPrograms.length < 1 || !this.state.myReport[0].compiled || this.state.project_parameters.length < 1 || this.state.project_parameters.asset_class.length < 1){
            return(
                <div style={{position:'fixed', top:'50%', left:'50%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Spinner animation='border' variant='info' style={{height:'60px', width:'60px'}}/>
                </div>
            )
        }

        const formatEstimation = formatEstimationValues(this.state.estimations)
        const reportData = this.state.myReport[0]
        const programList = this.state.reportPrograms
        const projectParams = this.state.project_parameters
        alphaList(programList)
        const reportLat = reportData.point[0]
        const reportLng = reportData.point[1]
        const truncLat = reportLat.length > 9 ? reportLat.slice(0,(9 - reportLat.length)) : reportLat
        const truncLng = reportLng.length > 9 ? reportLng.slice(0, (9 - reportLng.length)) : reportLng
        const projectName = projectParams.project_name
        const additionalFilters = this.state.show_additional_filters ? true : false
        const showEstimator = this.state.show_estimator ? true : false
        const filteredPrograms = newFilterData(projectParams,programList)
        const programAmount = filteredPrograms['results'].length
        const hideTenant = this.state.filter_tenant
        const hideContractor = this.state.filter_contractor
        const hideInactive = this.state.filter_inactive_programs
        const hideCompetitive = this.state.filter_competitive_programs
        const additionalFilter = addFilters(hideInactive,hideCompetitive,hideContractor,hideTenant,filteredPrograms)
        const programCountAfterAdd = primaryTypes(additionalFilter, reportData, this.handleSectionArrows, this.handleProgramExportSelect,this.state, formatEstimation, this.handleProgramModalData).programs_counted
        const primarySections = primaryTypes(additionalFilter, reportData, this.handleSectionArrows, this.handleProgramExportSelect ,this.state, formatEstimation, this.handleProgramModalData).programs
        const proj_name = projectParams.project_name.length < 1 ? reportData.address : projectParams.project_name
        const qualifiedText = qualifingProgramsText(programAmount, programList.length, proj_name)
        const finalProjArr = finalProgIdList(filteredPrograms)
        const totalValue = totalAllEstimateValues(additionalFilter.results, formatEstimation)
        const redistimatePotentialTip = <Tooltip>Enter and edit project-related variables to produce specific estimates of program incentive values.</Tooltip>
        const additionalInactiveTip = <Tooltip>Hide closed or expired programs.</Tooltip>
        const additionalCompetitiveTip = <Tooltip>Hide programs with competitive or discretionary approval processes.</Tooltip>
        const additionalContractorTip = <Tooltip>Hide programs with contractor only benefits.</Tooltip>
        const additionalTenantTip = <Tooltip>Hide programs with tenant only benefits.</Tooltip>

        return (
            <>
                <Container id='redist-container' fluid>
                    <Estimator
                        show={showEstimator}
                        onHide={this.handleHideEsitmator}
                        backdrop={true}
                        report_id={reportData.id}
                        project_parameters={this.state.project_parameters}
                        progList={finalProjArr}
                        passEstimation={this.handlePassedEstimation}
                        estimation_inputs={this.state.estimation_inputs}
                    />
                    <ProjectParameters
                        show={this.state.modalShow}
                        onHide={this.handleHideModal}
                        report_id={reportData.id}
                        address={reportData.address}
                        reportPage={true}
                        backdrop={true}
                        paramPull={this.handleParamPull}
                        project_parameters={this.state.project_parameters}
                    />
                    <ProgramModal
                        show={this.state.programModalShow}
                        onHide={this.handleHideProgramModal}
                        data={this.state.programModalData}
                        report_id={reportData.id}
                        backdrop={true}
                    />
                    <Modal
                        size='xl'
                        keyboard={false}
                        scrollable={true}
                        onHide={this.handleExport}
                        show={this.state.hide_export}
                    >
                        <Modal.Body>
                            <ReactToPrint
                                trigger={() => <Button variant='success'>Print This Out</Button>}
                                content={() => this.componentRef}
                            />
                            <ProgramsToPrint
                                exportPrograms={this.state.export_programs}
                                programs={additionalFilter}
                                reportData={reportData}
                                programAmount={programAmount}
                                addressData={this.state.myReport[0]}
                                primarySections={primarySections}
                                project_parameters={this.state.project_parameters}
                                ref={el => (this.componentRef = el)}
                                estimateVal={this.state.redistimate_values}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal
                        size='sg'
                        onHide={this.handleHideAdditionalFilters}
                        keyboard={false}
                        show={additionalFilters}
                        centered={true}
                    >
                        <Modal.Header >
                            <Modal.Title>
                                <b>Additional Filters</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group controlId="control-additional-filter_inactive_programs" >
                                <OverlayTrigger
                                    placement='right'
                                    delay={{show:250, hide:400}}
                                    overlay={additionalInactiveTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Hide Inactive Programs</b></Form.Label>
                                </OverlayTrigger>
                                <Switch
                                    checked={this.state.filter_inactive_programs}
                                    onChange={this.handleSwitch}
                                    value='filter_inactive_programs'
                                />
                            </Form.Group>

                            <Form.Group controlId="control-additional-filter_competitive_programs" >
                                <OverlayTrigger
                                    placement='right'
                                    delay={{show:250, hide:400}}
                                    overlay={additionalCompetitiveTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Hide Competitive Programs </b></Form.Label>
                                </OverlayTrigger>
                                <Switch
                                    checked={this.state.filter_competitive_programs}
                                    onChange={this.handleSwitch}
                                    value='filter_competitive_programs'
                                />
                            </Form.Group>

                            <Form.Group controlId="control-additional-filter_contractor" >
                                <OverlayTrigger
                                    placement='right'
                                    delay={{show:250, hide:400}}
                                    overlay={additionalContractorTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Hide Contractor Only</b></Form.Label>
                                </OverlayTrigger>
                                <Switch
                                    checked={this.state.filter_contractor}
                                    onChange={this.handleSwitch}
                                    value='filter_contractor'
                                />
                            </Form.Group>

                            <Form.Group controlId="control-additional-filter_tenant" >
                                <OverlayTrigger
                                    placement='right'
                                    delay={{show:250, hide:400}}
                                    overlay={additionalTenantTip}>
                                    <Form.Label style={{minWidth: '200px'}}><b className='redistTip'>Hide Tenant Only</b></Form.Label>
                                </OverlayTrigger>
                                <Switch
                                    checked={this.state.filter_tenant}
                                    onChange={this.handleSwitch}
                                    value='filter_tenant'
                                />
                            </Form.Group>

                        </Modal.Body>
                    </Modal>
                    <Card id="r-gen-card">
                        <Card.Body>
                            <GoogleMap
                                key={reportData.length}
                                latV={reportLat}
                                lngV={reportLng}
                            />
                        </Card.Body>
                        <Card style={{zIndex:'1', position:'absolute', top:'30px', left:'30px', border:'none', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                            <Card.Body>
                                <div>
                                    <h2 style={{fontWeight:600, color:'#212529', marginBottom:'0'}}>{projectName}</h2>
                                    <Address {...this.state.myReport[0]}/>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card style={{zIndex:'1', position:'absolute', top:'30px', right:'30px', border:'none', backgroundColor: 'rgba(255,255,255,0.8)', height:'60px', justifyContent:'center'}}>
                            {reportData.census_tract !== '' &&
                                <Card.Text as='h6' id='card-text--right-align'>CENSUS TRACT &nbsp; <b>{reportData.census_tract}</b></Card.Text>
                            }
                            <Card.Text as='h6' id='card-text--right-align'>({truncLat}, {truncLng})</Card.Text>
                        </Card>
                    </Card>
                    <Card id="r-gen-card">
                        <Card.Body style={{}}>
                                <Card style={{border: 'solid 2px #0275d8', marginBottom:'5px' }}>
                                    {qualifiedText}
                                </Card>
                                <Card style={{border: 'solid 2px #0ABB87' }}>
                                    <Card.Body>
                                        <div style={{display:'flex', alignItems:'center', color:'#0ABB87'}}>
                                            <img style={{width:'20px', height:'20px'}} alt='logo'src={require("../../assets/redist-lg-logo.png")}/>
                                            <p style={{margin:0,marginLeft:10}}>
                                                <b>REDISTIMATE&trade; Incentive Potential Estimate:</b>
                                            </p>
                                            <OverlayTrigger
                                                placement='right'
                                                delay={{show:250, hide:400}}
                                                overlay={redistimatePotentialTip}>
                                                <b className='redistTip' style={{marginLeft:'5px'}}>${totalValue.toLocaleString()}+</b>
                                            </OverlayTrigger>
                                        </div>
                                    </Card.Body>
                                </Card>
                        </Card.Body>
                    </Card>
                    <Card id="r-gen-card">
                        <Card.Body style={{lineHeight: 1}}>
                            <Card style={{border:'0 solid'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end', margin:'0 0 4px 4px'}}>
                                    <div style={{display:'flex', fontSize:'14px', fontWeight:'600'}}>
                                        <div style={{marginRight:'4px',display:'flex'}}><p>Displaying</p><p style={{color: '#5578EB', margin:'0 3px 0 3px'}}><b>{programCountAfterAdd}</b></p><p>programs out of</p></div>
                                        <div style={{marginRight:'4px'}}>{programList.length} total results</div>
                                    </div>
                                    <div>
                                        <Button variant='outline-success' style={{marginLeft:'4px'}} onClick={this.handleShowEsitmator}>
                                            <Calculator size={20} color={'#0ABB87'} style={{marginRight:'5px'}}/>
                                        REDISTIMATE Variables
                                        </Button>
                                        <Button variant='outline-info' style={{marginLeft:'4px'}} onClick={this.handleShowModal}>
                                            <FilterListIcon/> Project Parameters
                                        </Button>
                                        <Button variant='outline-info' style={{marginLeft:'4px'}} onClick={this.handleShowAdditionalFilters}>
                                            <FilterListIcon/> Additional Filters
                                        </Button>
                                        {/* <Button variant='outline-secondary' style={{marginLeft:'4px'}} onClick={this.handleExport}>
                                            <DescriptionIcon/> Export
                                        </Button> */}
                                    </div>
                                </div>
                                {primarySections}
                            </Card>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    }
}

function alphaList(arrObjlist) {
    arrObjlist.sort(function(a, b) {
        return a.program_name.localeCompare(b.program_name);
     });
}

function addFilters(showInac, hideCompe, hideCont, hideTen, programs){
    let results = []
    let trackHidden = []
    let toBeHidden = []
    const reportPrograms = programs['results']
    const combinedResults = {}

    if(!showInac && !hideCompe && !hideCont && !hideTen ){
        reportPrograms.forEach((i) => {
            if(i.program_end_date){
                trackHidden.push(i)
            } else {
                results.push(i)
            }
        })
    } else {
        reportPrograms.forEach(i => {
            let pass = true

            if(!showInac){
                if(i.program_end_date){
                    pass = false
                }
            }

            if(hideCompe){
                if(i.program_approval_process === 'Competitive'){
                    pass = false
                }
            }

            if(hideCont){
                if(i.program_beneficiary.includes('Contractor') && i.program_beneficiary.length === 1){
                    pass = false
                }
            }

            if(hideTen){
                if(i.program_beneficiary.includes('Tenant') && i.program_beneficiary.length === 1){
                    pass = false
                }
            }

            if(pass){
                results.push(i)
            } else {
                trackHidden.push(i)
            }
        })
    }

    results = [...new Set(results)]
    toBeHidden = toBeHidden.concat(trackHidden)
    toBeHidden = [...new Set(toBeHidden)]    
    combinedResults['results'] = results
    combinedResults['toBeHidden'] = toBeHidden
    combinedResults['disqual_progs'] = programs['disqual_progs']
    return combinedResults

}

function newFilterData(params, reportPrograms){
    const results = []
    const disqualprogs = []
    const combinedData = {}

    reportPrograms.forEach((i) => {
        let andCheckArr = []
        let optionalCheck = []

        for(const e in params){
            if(e === 'type'){
                if(params[e].length < 1){
                    andCheckArr.push(true)
                    continue;
                }
                if(projTypeHelp(params[e],i)){
                    andCheckArr.push(true)
                    continue;
                } else {
                    andCheckArr.push(false)
                    continue;
                }
            }

            if(e === 'asset_class'){
                if(params[e].length < 1){
                    andCheckArr.push(true)
                    continue;
                }
                if(projAssetHelp(params[e],i)){
                    andCheckArr.push(true)
                    continue;
                } else {
                    andCheckArr.push(false)
                    continue;
                }
            }

            if(e === 'affordable_housing'){    
                if(projBoolHelp(e,params[e],i)){
                    optionalCheck.push(true)
                    continue;
                } else {
                    optionalCheck.push(false)
                    continue;
                }
            }

            if(e === 'green_building'){
                if(projBoolHelp(e,params[e],i)){
                    optionalCheck.push(true)
                    
                    continue;
                } else {
                    optionalCheck.push(false)
                    continue;
                }
            }

            if(e === 'env_remediation'){
                if(projBoolHelp(e,params[e],i)){
                    optionalCheck.push(true)
                    continue;
                } else {
                    optionalCheck.push(false)
                    continue;
                }
            }

            if(e === 'historic_preservation'){
                if(projBoolHelp(e,params[e],i)){
                    optionalCheck.push(true)
                    continue;
                } else {
                    optionalCheck.push(false)
                    continue;
                }
            }

            if(e === 'public_non_profit_partner'){
                if(projBoolHelp(e,params[e],i)){
                    optionalCheck.push(true)
                    continue;
                } else {
                    optionalCheck.push(false)
                    continue;
                }
            }

            if(e === 'affordable_min_percent_ami'){
                if(params['affordable_housing'] && i.cat_affordable){
                    if(projMinAmiHelp(params[e], i)){
                        optionalCheck.push(true)
                        continue;
                    } else {
                        optionalCheck.push(false)
                        continue;
                    }
                } else {
                    continue;
                }
            }

            if(e === 'affordable_max_percent_units'){
                if(params['affordable_housing'] && i.cat_affordable){
                    if(projMaxUnitHelp(params[e], i)){
                        optionalCheck.push(true)
                        continue;
                    } else {
                        optionalCheck.push(false)
                        continue;
                    }
                } else {
                    continue;
                }
            }
        }
        if(andCheckArr.every((ele) => ele === true) && optionalCheck.every((ele) => ele === true)){
            results.push(i)
        } else{
            disqualprogs.push(i)
        }
    })

    combinedData['results'] = results
    combinedData['disqual_progs'] = disqualprogs
    return combinedData
}

function projTypeHelp(arr,obj){
    const repArr = obj.eligible_project_type
    let passed = false
    if(arr.length < 1){return passed}

    arr.forEach((e) => {
        if(repArr.includes(e)){
            passed = true
        }
    })
    return passed
}

function projAssetHelp(arr, obj){
    let passed = false
    let checkList = []
    const assetMatch = {
        class_multi_lg: 'Multifamily >50 units',
        class_multi_sm: 'Multifamily <50 units',
        class_office: 'Office',
        class_other_residential: 'Other Residential',
        class_other_commercial: 'Other Commercial',
        class_manufacturing: 'Manufacturing',
        class_other_industrial: 'Other Industrial',
        class_homeowner: 'Homeowner',
        class_retail: 'Retail',
    }

    if(arr.length < 1){return passed}

    for(const prop in assetMatch){
        if(arr.includes(assetMatch[prop])){
            checkList.push(prop)
        }

        if((arr.includes('Hospitality') || arr.includes('Healthcare')) && prop === 'class_other_commercial') {
            checkList.push(prop)
        }
    }

    checkList = [...new Set(checkList)]
    checkList.forEach((i) => {
        if(obj[i]){
            passed = true
        }
    })

    return passed
}

function projBoolHelp(prop, param, repObj){
    let passed = false

    const catMatch = {
        "affordable_housing": 'cat_affordable',
        "green_building" : 'cat_green',
        "env_remediation": 'cat_cleanup',
        "historic_preservation": 'cat_bldg_preservation',
        "public_non_profit_partner": 'cat_public_partner'
    }

    if(param === true){
        passed = true
    } else if(param === false){
        if(repObj[catMatch[prop]] === param){
            passed = true
        }
    }


    return passed
}

function projMinAmiHelp(paramVal, progObj){
    const progVal = progObj.affordable_max_percent_ami

    // if(!paramList['affordable_housing']){return false}

    if(progVal === null){
        return false
    } else if( paramVal === null && progVal >= 0){
        return true
    } else if(  paramVal !== null && progVal >= (parseInt(paramVal))/100){
        return true
    }

    return false

}

function projMaxUnitHelp(paramVal, progObj){
    const progVal = progObj.affordable_min_percent_units

    // if(!paramList['affordable_housing']){return false}

    if(progVal === null){
        return false
    } else if( paramVal === null && progVal <= 0){
        return true
    } else if( paramVal !== null && progVal <= (parseInt(paramVal))/100){
        return true
    }

    return false

}

const primaryTypeList = ["Bidding Priority","Business Tax","Capital Gains Tax","Custom Duty","Federal Income Tax","Financing","Grant","Mortgage Recording Tax","Other Tax","Permit Fee", "Property Tax","Rebate","Sales & Use Tax","State Income Tax","Transfer Tax","Utility Cost","Zoning Bonus"]


const primaryTypeListFiltered = ["Bidding Priority Filtered","Business Tax Filtered","Capital Gains Tax Filtered","Custom Duty Filtered","Federal Income Tax Filtered","Financing Filtered","Grant Filtered","Mortgage Recording Tax Filtered","Other Tax Filtered","Permit Fee Filtered", "Property Tax Filtered","Rebate Filtered","Sales & Use Tax Filtered","State Income Tax Filtered","Transfer Tax Filtered","Utility Cost Filtered","Zoning Bonus Filtered"]

function primaryTypes(progObj, report, func1, func2, state, estimateObj, modalFunc) {
    const result = {}
    const resultArr = progObj.results
    const toBeHiddenArr = progObj.toBeHidden
    const disqualProgs = progObj.disqual_progs
    const filteredSectionTip = <Tooltip className='inlineToolTip'>These programs have been filtered out by the additional filters.</Tooltip>
    const disqualifiedSectionTip = <Tooltip className='inlineToolTip'>The project site sits within the geographical boundaries of these programs, but does not qualify due to selected parameters.</Tooltip>
    const mainSectionTip = <Tooltip>Enter and edit project-related variables to product specific estimates of program incentive values.</Tooltip>
    let disqualArr = []
    let disqualSection;

    let sectionList = []
    let emptyCatList = []
    let proglistCount = 0
    
    disqualProgs.forEach((e,i) => {
        disqualArr.push(
            <ProgramEntry
                key={i}
                hidden={true}
                program={e}
                Rep={report.id}
                eventKey={i.toString()}
                estimateVal={state.redistimate_values[e.id]}
                estimateStr={estimateObj[e.id].string}
                programModal={modalFunc}
            />
        )
    })

    disqualSection = 
        <Accordion defaultActiveKey="200" key={50} style={{margin:'2px 0'}}>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={50} id='disqual_progs_section' style={{height:'2.4em', display:'flex', alignItems:'center', border:'0 solid lightgrey', borderWidth:'0 0 1px 0'}} onClick={func1}>
                    {state["disqual_progs_section"] ? <ArrowDropDownOutlinedIcon/> : <ArrowRightOutlinedIcon/>}
                    <h5 style={{opacity:'.5', margin:'0', fontWeight:'600'}}>Disqualified Programs</h5> &nbsp; <Badge variant='danger' style={{fontSize:'1rem', fontWeight:'600', marginLeft:'5px', marginRight:'5px', padding:'0.25em 0.4em'}}>{disqualArr.length}</Badge>
                    <OverlayTrigger
                        placement='right'
                        delay={{show:250, hide:400}}
                        overlay={disqualifiedSectionTip}>
                        <HelpIcon color='disabled'/>
                    </OverlayTrigger>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={50}>
                    <Card.Body style={{opacity:'.5', padding: '0 10px'}}>
                        {disqualArr}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>


    for(let i = 0; i < primaryTypeList.length; i++){
        let progList =  []
        let hiddenProgList = []
        let sectionCountTotal = 0
        resultArr.forEach((e, j) => {
            if(e.program_primary_type === primaryTypeList[i]){
                //add count
                if(estimateObj[e.id].count){sectionCountTotal += estimateObj[e.id].count}
                progList.push(
                    // <div key={j+500} style={{display:'flex', alignItems:'center'}}>
                    // <input
                    //     type='checkbox'
                    //     id={e.id}
                    //     onChange={func2}
                    //     key={j+300}
                    // />
                    <ProgramEntry
                        key={j}
                        program={e}
                        hidden={false}
                        Rep={report.id}
                        eventKey={i.toString()}
                        estimateVal={state.redistimate_values[e.id]}
                        estimateStr={estimateObj[e.id].string}
                        programModal={modalFunc}
                    />
                    // </div>
                )
            }
        })

        //used hidden array from additional filters and create list of gray progs <ListGroup.Item disabled>
        toBeHiddenArr.forEach((e, j) => {
            if(e.program_primary_type === primaryTypeList[i]){
                hiddenProgList.push(
                    <ProgramEntry
                        key={j}
                        hidden={true}
                        program={e}
                        Rep={report.id}
                        eventKey={i.toString()}
                        estimateVal={state.redistimate_values[e.id]}
                        estimateStr={estimateObj[e.id].string}
                        programModal={modalFunc}
                    />
                )
            }
        })

        proglistCount += progList.length

        //handles showing empty categories  
        // if(progList.length < 1 && hiddenProgList.length < 1){continue;}

        let toShowFilteredSection;
        let toShowFilteredSectionCount;
        if(hiddenProgList.length > 0){
            toShowFilteredSectionCount = <Badge id={primaryTypeList[i]} variant='secondary' style={{fontSize:'1rem', fontWeight:'600', marginLeft:'4px', padding:'0.25em 0.4em'}}>{hiddenProgList.length}</Badge>

            toShowFilteredSection =
                <Accordion defaultActiveKey="100" key={i+100} style={{margin:'2px 0'}}>
                    <Card>
                        <Accordion.Toggle id={primaryTypeListFiltered[i]} as={Card.Header} eventKey={100} style={{height:'2.4em', display:'flex', alignItems:'center', border:'0 solid lightgrey', borderWidth:'0 0 1px 0'}} onClick={func1}>
                            {state[primaryTypeListFiltered[i]] ? <ArrowDropDownOutlinedIcon id={primaryTypeListFiltered[i]}/> : <ArrowRightOutlinedIcon id={primaryTypeListFiltered[i]}/>}
                            <h5 id={primaryTypeListFiltered[i]} style={{opacity:'.5', margin:'0', fontWeight:'600'}}>Filtered </h5> &nbsp; 
                            <Badge id={primaryTypeListFiltered[i]} variant='secondary' style={{fontSize:'1rem', fontWeight:'600', marginLeft:'4px', padding:'0.25em 0.4em'}}>{hiddenProgList.length}</Badge>
                            <OverlayTrigger
                                placement='right'
                                delay={{show:250, hide:400}}
                                overlay={filteredSectionTip}>
                                <HelpIcon color='disabled'/>
                            </OverlayTrigger>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={100}>
                            <Card.Body style={{opacity:'.5', padding: '0 10px'}}>
                                {hiddenProgList}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        } else {
            toShowFilteredSectionCount = undefined
            toShowFilteredSection = undefined
        }

        if(progList.length < 1 && hiddenProgList.length < 1){
            emptyCatList.push(
                <Accordion defaultActiveKey="0" key={i} style={{margin:'2px 0'}}>
                    <Card style={{opacity:'0.4'}}>
                        <Accordion.Toggle as={Card.Header} eventKey={i} id={primaryTypeList[i]} style={{height:'2.4em', display:'flex', alignItems:'center', border:'0 solid lightgrey', borderWidth:'0 0 1px 0', justifyContent:'space-between'}} onClick={func1}>
                            <div style={{display:'flex', alignItems:'center', marginLeft:'20px'}}>
                                {/* {state[primaryTypeList[i]] ? <ArrowDropDownOutlinedIcon/> : <ArrowRightOutlinedIcon/>} */}
                                <h5 style={{margin:'0', fontWeight:'600'}}>{primaryTypeList[i]} </h5> &nbsp; <Badge variant='secondary' style={{fontSize:'1rem', fontWeight:'600', marginLeft:'4px', padding:'0.25em 0.4em'}}>{progList.length}</Badge> &nbsp; {toShowFilteredSectionCount}
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i}>
                        <Card.Body id='r-gen-card-dropdown-body'>
                            {progList}
                            {toShowFilteredSection}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            )
        } else {
            let catBadgeTip
            if(hiddenProgList.length === 1){
                catBadgeTip = <Tooltip className='inlineToolTip'>{progList.length} progam matches based on project parameters, {hiddenProgList.length} program filtered.</Tooltip>
            } else if(hiddenProgList.length > 1){
                catBadgeTip = <Tooltip className='inlineToolTip'>{progList.length} progam matches based on project parameters, {hiddenProgList.length} programs filtered.</Tooltip>
            } else{
                catBadgeTip = <Tooltip className='inlineToolTip'>{progList.length} progam matches based on project parameters.</Tooltip>
            } 
            sectionList.push(
                <Accordion defaultActiveKey="0" key={i} style={{margin:'2px 0'}}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={i} id={primaryTypeList[i]} style={{height:'2.4em', display:'flex', alignItems:'center', border:'0 solid lightgrey', borderWidth:'0 0 1px 0', justifyContent:'space-between'}} onClick={func1}>
                            <div id={primaryTypeList[i]} style={{display:'flex'}}>
                                {state[primaryTypeList[i]] ? <ArrowDropDownOutlinedIcon id={primaryTypeList[i]}/> : <ArrowRightOutlinedIcon id={primaryTypeList[i]}/>}
                                <OverlayTrigger
                                    placement='right'
                                    delay={{show:250, hide:400}}
                                    overlay={catBadgeTip}>
                                    <div id={primaryTypeList[i]} style={{display:'flex'}}>
                                        <h5 id={primaryTypeList[i]} style={{margin:'0', fontWeight:'600'}}>{primaryTypeList[i]} </h5> &nbsp; <Badge id={primaryTypeList[i]} variant='info' style={{fontSize:'1rem', fontWeight:'600', marginLeft:'4px', padding:'0.25em 0.4em'}}>{progList.length}</Badge> &nbsp; {toShowFilteredSectionCount}
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div id={primaryTypeList[i]} style={{marginRight: '40px'}}>
                                <OverlayTrigger
                                    placement='left'
                                    delay={{show:250, hide:400}}
                                    overlay={mainSectionTip}>
                                    {sectionCountTotal < 1 ? <h5 id={primaryTypeList[i]} className='redistTip' style={{margin:0}}><b id={primaryTypeList[i]}>$ --</b></h5>: <h5 id={primaryTypeList[i]} className='redistTip' style={{margin:0}}><b id={primaryTypeList[i]}>${sectionCountTotal.toLocaleString()}</b></h5>}
                                </OverlayTrigger>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i}>
                        <Card.Body id='r-gen-card-dropdown-body'>
                            {progList}
                            {toShowFilteredSection}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            )
        }
    }
    sectionList = [...sectionList, ...emptyCatList]
    sectionList.push(disqualSection)
    result.programs = sectionList
    result.programs_counted = proglistCount
    return result
}

function qualifingProgramsText(count, total, name){
    let result = []
    // let name;

    // if(rep.project_name.trim().length < 1){
    //     name = rep.address
    // } else {
    //     name = rep.project_name
    // }

    if (total === 0){
        result.push(
            <Card.Body key={count}>
                <h2 style={{color: '#0275d8'}}><b> No Programs Identified.</b></h2>
                <p>There are almost certainly programs that this project site will qualify for based upon location alone, but the current project parameters are likely too restrictive. Try broadening the search to yield more possible results.
                </p>
            </Card.Body>
        )
        return result
    }

    if(total === 1){
        result.push(
            <Card.Body key={count}>
                <h2 style={{color: '#0275d8'}}><b>{total} program match found for {name}.</b></h2>
            </Card.Body>
        )
        return result
    } else {
        result.push(
            <Card.Body key={count}>
                <h2 style={{color: '#0275d8'}}><b>{total} program matches found for {name}.</b></h2>
            </Card.Body>
        )
        return result
    }
}

function finalProgIdList(obj){
    let res = [] 
    
    if(obj.results){
        obj.results.forEach(e => {
            res.push(e.id)
        })
    }

    if(obj.disqual_progs){
        obj.disqual_progs.forEach(e => {
            res.push(e.id)
        })
    }

    return res
}

function formatEstimationValues(obj){
    const arr = obj.program_ids
    let result = {}

    if(!arr){return {}}

    arr.forEach(prop => {
        
        if( (prop.calc_max === '' && prop.fixed_max === '') && (prop.calc_min !== '' || prop.fixed_min !== '' ) && (prop.fixed_min !== 'custom_amount') ){
            //min calc or min fixed val
            let calcVal = parseInt(prop.calc_min)
            let fixedVal = parseInt(prop.fixed_min)
            if(prop.calc_min !== '' && prop.fixed_min !== ''){
                //calc then check if below fixed min
                if(calcVal < fixedVal){
                    result[prop.id] = {string: `$${fixedVal.toLocaleString()}+`, count: fixedVal}
                } else {
                    result[prop.id] = {string: `$${calcVal.toLocaleString()}+`, count: calcVal}
                }
            } else if(prop.calc_min !== '' && prop.fixed_min === ''){
                //calc
                result[prop.id] = {string: `$${calcVal.toLocaleString()}+`, count: calcVal}
            } else if(prop.calc_min === '' && prop.fixed_min !== ''){
                //fixed
                result[prop.id] = {string: `$${fixedVal.toLocaleString()}+`, count: fixedVal}
            }
        } else if( (prop.calc_min === '' && prop.fixed_min === '') && (prop.calc_max !== '' || prop.fixed_max !== '') && (prop.fixed_max !== 'custom_amount') ){
            //max calc or fixed val
            let calcVal = parseInt(prop.calc_max)
            let fixedVal = parseInt(prop.fixed_max)
            if(prop.calc_max !== '' && prop.fixed_max !== ''){
                //calc then check if above fixed max
                if(calcVal > fixedVal){
                    result[prop.id] = {string: `Up to $${fixedVal.toLocaleString()}`, count: fixedVal}
                } else {
                    result[prop.id] = {string: `Up to $${calcVal.toLocaleString()}`, count: calcVal}
                }
            } else if(prop.calc_max !== '' && prop.fixed_max === ''){
                //calc
                result[prop.id] = {string: `Up to $${calcVal.toLocaleString()}`, count: calcVal}
            } else if(prop.calc_max === '' && prop.fixed_max !== ''){
                //fixed
                result[prop.id] = {string: `Up to $${fixedVal.toLocaleString()}`, count: fixedVal}
            }
        } else if( (prop.calc_max !== '' || prop.fixed_max !== '') && (prop.calc_min !== '' || prop.fixed_min !== '') && (prop.fixed_max !== 'custom_amount' && prop.fixed_min !== 'custom_amount') ){
            
            //min calc/fixed - max calc/fixed
            let minCalcVal = parseInt(prop.calc_min)
            let maxCalcVal = parseInt(prop.calc_max)
            let minFixedVal = parseInt(prop.fixed_min)
            let maxFixedVal = parseInt(prop.fixed_max)
            if(prop.calc_min !== '' && prop.calc_max !== '' && prop.fixed_min !== '' && prop.fixed_max !== '' ){
                //show max if below fixed_max
                if(prop.calc_min === prop.calc_max && maxCalcVal > maxFixedVal){
                    result[prop.id] = {string: `$${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                } else if(prop.calc_min === prop.calc_max){
                    result[prop.id] = {string: `$${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                } else {
                    let tempMin;
                    let tempMax;
                    if(minCalcVal < minFixedVal ){
                        tempMin = minFixedVal
                    } else {
                        tempMin = minCalcVal
                    }

                    if(maxCalcVal > maxFixedVal ){
                        tempMax = maxFixedVal
                    } else {
                        tempMax = maxCalcVal
                    }
                    result[prop.id] = {string: `$${tempMin.toLocaleString()} - ${tempMax.toLocaleString()}`, count: tempMax}
                }             
            } else if(prop.calc_min !== '' && prop.calc_max !== '' && prop.fixed_min !== '' && prop.fixed_max === '' ){
                if(prop.calc_min === prop.calc_max && minCalcVal < minFixedVal){
                    result[prop.id] = {string: `$${minFixedVal.toLocaleString()}`, count: minFixedVal}
                } else if(prop.calc_min === prop.calc_max){
                    result[prop.id] = {string: `$${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                } else {
                    let tempMin;
                    let tempMax;
                    if(minCalcVal < minFixedVal ){
                        tempMin = minFixedVal
                    } else {
                        tempMin = minCalcVal
                    }
                        tempMax = maxCalcVal

                    result[prop.id] = {string: `$${tempMin.toLocaleString()} - ${tempMax.toLocaleString()}`, count: tempMax}
                }   
            } else if(prop.calc_min !== '' && prop.calc_max !== '' && prop.fixed_min === '' && prop.fixed_max !== '' ){
                if(prop.calc_min === prop.calc_max && maxCalcVal > maxFixedVal){
                    result[prop.id] = {string: `$${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                } else if(prop.calc_min === prop.calc_max){
                    result[prop.id] = {string: `$${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                } else {
                    let tempMin;
                    let tempMax;

                    tempMin = minCalcVal

                    if(maxCalcVal > maxFixedVal ){
                        tempMax = maxFixedVal
                    } else {
                        tempMax = maxCalcVal
                    }
                    result[prop.id] = {string: `$${tempMin.toLocaleString()} - ${tempMax.toLocaleString()}`, count: tempMax}
                } 
            } else if(prop.calc_min !== '' && prop.calc_max === '' && prop.fixed_min !== '' && prop.fixed_max !== '' ){
                let tempMin;
                let tempMax;
                if(minCalcVal < minFixedVal ){
                    tempMin = minFixedVal
                } else {
                    tempMin = minCalcVal
                }

                tempMax = maxFixedVal

                result[prop.id] = {string: `$${tempMin.toLocaleString()} - ${tempMax.toLocaleString()}`, count: tempMax}
            } else if(prop.calc_min === '' && prop.calc_max !== '' && prop.fixed_min !== '' && prop.fixed_max !== '' ){
                let tempMin;
                let tempMax;

                tempMin = minFixedVal

                if(maxCalcVal > maxFixedVal ){
                    tempMax = maxFixedVal
                } else {
                    tempMax = maxCalcVal
                }
                result[prop.id] = {string: `$${tempMin.toLocaleString()} - ${tempMax.toLocaleString()}`, count: tempMax}
            } else if(prop.calc_min !== '' && prop.calc_max !== '' && prop.fixed_min === '' && prop.fixed_max === '' ){
                if(prop.calc_min === prop.calc_max){
                    result[prop.id] = {string: `$${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                } else {
                    result[prop.id] = {string: `$${minCalcVal.toLocaleString()} - ${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                }  
            } else if(prop.calc_min === '' && prop.calc_max === '' && prop.fixed_min !== '' && prop.fixed_max !== '' ){
                if(prop.fixed_min === prop.fixed_max){
                    result[prop.id] = {string: `$${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                } else {
                    result[prop.id] = {string: `$${minFixedVal.toLocaleString()} - ${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                }
            } else if(prop.calc_min === '' && prop.calc_max !== '' && prop.fixed_min !== '' && prop.fixed_max === '' ){
                if(prop.fixed_min === prop.calc_max){
                    result[prop.id] = {string: `$${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                } else if(prop.fixed_min > prop.calc_max) {
                    result[prop.id] = {string: `$${minFixedVal.toLocaleString()} +`, count: minFixedVal}
                } else {
                    result[prop.id] = {string: `$${minFixedVal.toLocaleString()} - ${maxCalcVal.toLocaleString()}`, count: maxCalcVal}
                }
            } else if(prop.calc_min !== '' && prop.calc_max === '' && prop.fixed_min === '' && prop.fixed_max !== '' ){
                if(prop.calc_min === prop.fixed_max){
                    result[prop.id] = {string: `$${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                } else if(prop.calc_min > prop.fixed_max) {
                    result[prop.id] = {string: `Up to $${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                } else {
                    result[prop.id] = {string: `$${minCalcVal.toLocaleString()} - ${maxFixedVal.toLocaleString()}`, count: maxFixedVal}
                }
            }
        } else if( (prop.calc_max === '' && prop.calc_min === '') && (prop.fixed_max ==='custom_amount' && prop.fixed_min === 'custom_amount') ){
            //no min or max "Custom amount"
            result[prop.id] = {string: `Custom Amount`, count: 0}
        } else {
            result[prop.id] = {string: `...`, count: 0}
        }
    })
    return result
}

function totalAllEstimateValues(arr,obj){
    let count = 0

    arr.forEach(e => {
        if(obj[e.id]){
            count += obj[e.id].count
        }
    })
    return count
}

const newProjectParameters = {
    project_name: '',
    type: [],
    asset_class: [],
    affordable_housing: true,
    affordable_max_percent_units: 0,
    affordable_min_percent_ami: 0,
    green_building: true,
    env_remediation: true,
    historic_preservation: true,
    public_non_profit_partner: true
}

const newEsimationInputs = {
    unit_ct: '',
    sqft_gross: '',
    sqft_green_roof: '',
    assess_value_land: '',
    assess_value_bldg: '',
    assess_value_total: '',
    prop_tax_total: '',
    prop_tax_land: '',
    int_rate: '',
    morg_total: '',
    lease_term: '',
    base_rent_annual: '',
    emp_count: '',
    emp_cost: '',
    rd_exp: '',
    dev_cost_land: '',
    dev_cost_other_acq: '',
    dev_cost_hard: '',
    dev_cost_soft: '',
    dev_cost_total: '',
    proj_cost: '',
    dev_cost_mep_only: '',
    dev_cost_rehab_only: '',
    dev_cost_renew_only: '',
    env_cost_rem: '',
    env_cost_eng: '',
    afford_percent:'',
    afford_ami:''
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    builder: state.builder.menuConfig,
    }
}

export default connect(mapStateToProps, builderActions)(ReportV1);
