import React from 'react';
import ProgramEntry from './program-entry';
import Card from 'react-bootstrap/Card';
import '../../../_metronic/_assets/sass/pages/report/report.scss';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import Address from './address';

class ProgramsToPrint extends React.Component {

    render() {
        console.log('props', this.props)
        const exportProgs = this.props.exportPrograms
        const estimateVal = this.props.estimateVal
        // const programAmount = this.props.programAmount
        const reportData = this.props.reportData
        const addiFilters = this.props.programs
        // const programCountAfterAdd = exportProgs.length
        // const primarySections = primaryTypes(addiFilters, reportData).programs
        const projectName = this.props.project_parameters.project_name
        const newList = listEntries(exportProgs,addiFilters, reportData, estimateVal)


        return (
            <>
                <Card style={{ border:'none', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                    <Card.Body>
                        <div>
                            <h3>{projectName}</h3>
                            <Address {...this.props.addressData}/>
                            <div>
                                <div style={{border:'none'}}>
                                    {/* <div>{programAmount} results total <b style={{color: '#5578EB'}}>{programCountAfterAdd} shown</b></div> */}
                                    {newList}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    }
}


function listEntries(progIds, progObj, report, estiVal){
    let result = []
    let selectedData = []

    progObj.results.forEach((e) => {
        if(progIds.includes(e.id)){
            selectedData.push(e)
        }
    })
    console.log('selected', selectedData)
    
    selectedData.forEach((e,j) => {
        result.push(
            <ProgramEntry
                key={j}
                id={e.id}
                hidden={true}
                Rep={report.id}
                program_approval_process={e.program_approval_process}
                estimateVal={estiVal[e.id]}
                program_name={e.program_name}
            />
        )
    })
    return result
}

// const primaryTypeList = ["Property Tax","Federal Income Tax","State Income Tax","Business Tax","Capital Gains Tax","Sales & Use Tax","Mortgage Recording Tax","Transfer Tax","Grant","Financing","Custom Duty","Permit Fee","Utility Cost","Zoning Bonus","Bidding Priority","Rebate","Other Tax"]

// function primaryTypes(progArr, report) {
//     const result = {}
//     const progArr1 = progArr[0]
//     // const progArr2 = progArr[1]

//     const sectionList = []
//     let proglistCount = 0

//     for(let i = 0; i < primaryTypeList.length; i++){
//         let progList =  []
//         let hiddenProgList = []

//         progArr1.forEach((e, j) => {
//             if(e.program_primary_type === primaryTypeList[i]){
//                 progList.push(
//                     <ProgramEntry
//                         key={j}
//                         id={e.id}
//                         hidden={true}
//                         Rep={report.id}
//                         eventKey={i.toString()}
//                         program_name={e.program_name}
//                         program_agency={e.program_agency}
//                         program_amount={e.program_amount}
//                         jurisdiction={e.program_jurisdiction}
//                         cat_asofright={e.cat_asofright}
//                     />
//                 )
//             }
//         })

//         //used hidden array from additional filters and create list of gray progs <ListGroup.Item disabled>
//         // progArr2.forEach((e, j) => {
//         //     if(e.program_primary_type === primaryTypeList[i]){
//         //         hiddenProgList.push(
//         //             <ProgramEntry
//         //                 key={j}
//         //                 hidden={true}
//         //                 id={e.id}
//         //                 Rep={report.id}
//         //                 eventKey={i.toString()}
//         //                 program_name={e.program_name}
//         //                 program_agency={e.program_agency}
//         //                 program_amount={e.program_amount}
//         //                 jurisdiction={e.program_jurisdiction}
//         //                 cat_asofright={e.cat_asofright}
//         //             />
//         //         )
//         //     }
//         // })

//         proglistCount += progList.length
//         // console.log('hiddenprog', hiddenProgList)
//         if(progList.length < 1){continue;}
//         sectionList.push(
//                 <div key={i} style={{marginBottom:"20px"}}>
//                        <h3><b>{primaryTypeList[i]} &nbsp; [{progList.length}/{progList.length + hiddenProgList.length}]</b></h3>
//                     <div>
//                         {progList}
//                             {/* <div key={i} style={{opacity:'.5'}}>
//                                     <b>Filtered &nbsp; [{hiddenProgList.length}/ {hiddenProgList.length + progList.length}]</b>
//                                     <div>
//                                         {hiddenProgList}
//                                     </div>
//                             </div> */}
//                     </div>
//                 </div>
//         )
//     }

//     result.programs = sectionList
//     result.programs_counted = proglistCount
//     return result
// }

export default ProgramsToPrint;
