import React from 'react';
import { connect } from 'react-redux';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { getUsers, editUserAccount } from '../../crud/report.crud';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


class AdminUsers extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            users: [],
            user: {
                name: '',
                email: '',
                firebase_id: '',
                is_admin: false,
                is_sub: false,
                sub_started: '',
                a_la_carte_credit: '',
                sub_limit: '',
                sub_amount_used: ''
            },
            format_sub_started: ''
        }
    }
    componentDidMount() {
        if(this.props.user.is_admin === false){
            this.props.history.push("/dashboard")
        }


        this.handleGetUsers()
        this.props.setMenuConfig(AdminMenuConfig)
    }

    handleGetUsers = () =>{
        getUsers()
            .then( res => {
                this.setState({
                    users: res
                })
            })
            .catch( error => {
                console.log('err in getUsers adminprogram:', error.message)
            })
    }

    handleUserSelect = (e, v) => {
        const val = v
        if(val){
            const formatDateVal = val.sub_started ? new Date(val.sub_started).toISOString().split("T")[0] : ''
            this.setState({
                user: val,
                format_sub_started: formatDateVal
            })
        }
    }

    handleUserEdit = (e) => {
        const eTarget = e.target.name
        const eValue = e.target.value

        this.setState(prevState => ({
            user:{
                ...prevState.user,
                [eTarget]: eValue
        }}))
    }

    handleDateEdit = (e) => {
        const eValue = e.target.value

        this.setState({
            format_sub_started: eValue
        })
    }

    handleCheckOnChange = (e) =>{
        const targetName = e.target.name
        const targetChecked = e.target.checked

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                 [targetName]: targetChecked
            }
        }))
    }

    handleClear = () => {

        Promise.resolve().then(()=> {
            this.setState({
                user: newUser,
                format_sub_started: ''
            })
        })
        .then(res => {
            this.handleGetUsers()
        })
        .catch(err => {
            console.log('err getting users', err.message)
        })
    }

    handleAddUpdateUser = () =>{
        const userData = this.state.user
        userData.sub_started = this.state.format_sub_started
        editUserAccount(userData)
            .then(res => {
                if(res){
                    alert('User successfully edited')
                    this.handleGetUsers()
                } else{
                    alert('User Unsuccessfully edited check fb id and email.')
                }
            })
            .catch(err => {
                console.log('error',err.message)
                alert('User Unsuccessfully edited')
            })
    }

    render() {
        const formatSubStarted = this.state.format_sub_started ? this.state.format_sub_started: ''
        const users = this.state.users
        return (
            <>
                <Container style={{backgroundColor:'#fff', height: '100%'}}>
                    <Row>
                        <Col md={6} style={{marginTop: '20px'}}>
                            <Autocomplete
                                options={users}
                                getOptionLabel={(option) => `${option.name} (${option.email})`}
                                onChange={this.handleUserSelect}
                                renderInput={(params) => <TextField {...params} label='Users' variant='outlined' />}
                            />
                        </Col>
                        <Col md={3} style={{display:'flex', marginTop:'20px', alignItems:'center'}}>
                            <Button variant='warning' onClick={this.handleClear}>Clear</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Name</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="text" placeholder="redist User" name='name' value={this.state.user.name} onChange={this.handleUserEdit}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Email</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="email" placeholder="found in FB console" name='email' value={this.state.user.email} onChange={this.handleUserEdit}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Firebase ID</Form.Label>
                                            <Col sm="6">
                                                <Form.Control type="text" placeholder="name@redist.us" name='firebase_id' value={this.state.user.firebase_id} onChange={this.handleUserEdit} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Is an admin?</Form.Label>
                                            <Col sm="1">
                                                <Form.Control type='checkbox' name='is_admin' checked={this.state.user.is_admin || false} onChange={this.handleCheckOnChange}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Subscribed</Form.Label>
                                            <Col sm="1">
                                                <Form.Control type='checkbox' name='is_sub' checked={this.state.user.is_sub || false} onChange={this.handleCheckOnChange}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Subscribed Start Date</Form.Label>
                                            <Col sm="3">
                                                <Form.Control type='text' placeholder='yyyy-mm-dd' name='sub_started' value={formatSubStarted} onChange={this.handleDateEdit}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">A La Carte</Form.Label>
                                            <Col sm="3">
                                                <Form.Control type='number' name='a_la_carte_credit' value={this.state.user.a_la_carte_credit} onChange={this.handleUserEdit}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Subscribed Limit</Form.Label>
                                            <Col sm="3">
                                                <Form.Control type='number' placeholder='if >10' name='sub_limit' value={this.state.user.sub_limit || 0} onChange={this.handleUserEdit}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">Subcription amount used</Form.Label>
                                            <Col sm="3">
                                                <Form.Control plaintext readOnly value={this.state.user.sub_amount_used || 0}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group>
                                            <Button variant='success' onClick={this.handleAddUpdateUser}>Add / Update User</Button>
                                            {/* <Button variant='warning'>Add User</Button> */}
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <div style={{border:'solid 1px', height:'200px', overflowY: 'scroll'}}>
                                
                        </div>
                    </Row> */}
                </Container>
            </>
        );
    }
}

const newUser =  {
    name: '',
    email: '',
    firebase_id: '',
    is_admin: false,
    is_sub: false,
    sub_started: '',
    a_la_carte_credit: '',
    sub_limit: '',
    sub_amount_used: ''
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    builder: state.builder.menuConfig
    }
}

export default connect(mapStateToProps, builderActions)(AdminUsers);