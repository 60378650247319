import React from 'react';
import { connect } from 'react-redux';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { getProgramById, getGeoTableAdmin1, getGeoTableAdmin2, getGeoTableLocality, getGeoTableCensusTract, getGeoTableZcta, getGeoTableCountry, createGeoRel, deleteGeoRel, editProgramBoundNotes } from '../../crud/report.crud';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


class AdminGeoTable extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            program: null,
            admin1Data: [],
            admin2Data: [],
            localityData: [],
            censusTractData: [],
            zctaData: [],
            countryData: [],
            createRel: {
                type: '',
                value: '',
                state: null
            },
        }
    }
    componentDidMount() {
        const program_id = this.props.match.params.program_id
        
        if(this.props.user.is_admin === false){
            this.props.history.push("/dashboard")
        }

        this.props.setMenuConfig(AdminMenuConfig)

        if(program_id){
            Promise.resolve().then(() => {
                return getProgramById(program_id)
                    .then( res => {
                        const [data] = res
                        this.setState({
                            program: data
                        })
                    })
                    .catch( error => {
                        console.log('err in getprogrambyID adminGeoTable:', error.message)
                    })
            })
            .then(() => {
                this.handleTableGather();
            })
        } else {
            return this.props.history.push("/admin-interface")
        }

    }

    handleTableGather = () => {

        //Admin1
        getGeoTableAdmin1(this.state.program.id)
        .then(res => {
            this.setState({
                admin1Data: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata admin1 geotable:', error.message)
        })

        //Admin2
        getGeoTableAdmin2(this.state.program.id)
        .then(res => {
            this.setState({
                admin2Data: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata admin2 geotable:', error.message)
        })

        //Locality
        getGeoTableLocality(this.state.program.id)
        .then(res => {
            this.setState({
                localityData: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata locality geotable:', error.message)
        })

        //Census Tract
        getGeoTableCensusTract(this.state.program.id)
        .then(res => {
            this.setState({
                censusTractData: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata census geotable:', error.message)
        })

        //Zcta
        getGeoTableZcta(this.state.program.id)
        .then(res => {
            this.setState({
                zctaData: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata zcta geotable:', error.message)
        })

        //country
        getGeoTableCountry(this.state.program.id)
        .then(res => {
            this.setState({
                countryData: res
            })
        })
        .catch(error => {
            console.log('err in get geotabledata country geotable:', error.message)
        })
    }

    onValueChange = (e) => {
        const eValue = e.target.value

        this.setState(prevState => ({
            createRel:{
                ...prevState.createRel,
                value: eValue
        }}))
    }

    handleGeoRelDelete = (value, tableName, state = null) => {

        deleteGeoRel(this.state.program.id, value, tableName, state)
            .then(res => {
                //
            })
            .catch(err => {
                console.log('err in deleteprogram admin geo rel:' ,err.message)
            })
        
        setTimeout(() => {
            this.handleTableGather();
        }, 1000)
    }

    handleBoundarySelect = (e) => {
        const target = e.target.value
        
        this.setState(prevState => ({
            createRel: {
                ...prevState.createRel,
                type: target
            }
        }))

        if(target !== "County"){
            this.setState(prevState => ({
                createRel: {
                    ...prevState.createRel,
                    state: null
                }
            }))
        }
    }

    handleStateSelect = (e) => {
        const target = e.target.value
        this.setState(prevState => ({
            createRel: {
                ...prevState.createRel,
                state: target
            }
        }))
    }

    handleAddGeoRel = (e) => {
        e.preventDefault();

        if(!this.state.program.id || this.state.createRel.value === '') {return alert('Please fill in Relationship Value')}
        if(this.state.createRel.type === 'County' && this.state.createRel.state === null) {return alert('Please fill in Relationship State')}

        createGeoRel(this.state.program.id, this.state.createRel)
            .then(res => {
                //
            })
            .catch(err => {
                alert('Unsuccessful Addition')
                console.log('err in admin create geoRel:' ,err.message)
            })

        if(this.state.createRel.type === 'County'){
            this.setState({
                createRel: {
                    type: this.state.createRel.type,
                    value: '',
                    state: this.state.createRel.state
                }
            })
        } else {
            this.setState({
                createRel: {
                    type: this.state.createRel.type,
                    value: '',
                    state: null
                }
            })
        }

        setTimeout(() => {
            this.handleTableGather();
        }, 1000)
    }

    onInputChange = (e) => {
        const eTarget = e.target.name
        const eValue = e.target.value

        this.setState(prevState => ({
            program:{
                ...prevState.program,
                [eTarget]: eValue
        }}))
    }

    handleCheckOnChange = (e) =>{
        const targetName = e.target.name
        const targetChecked = e.target.checked

        this.setState(prevState => ({
            program: {
                ...prevState.program,
                 [targetName]: targetChecked
            }
        }))
    }

    handleAddBoundNotes = () =>{

        editProgramBoundNotes(this.state.program)
        .then(res => {
            // this.props.history.push("/admin-interface")
        })
        .catch(err => {
            console.log('error in handleProgBoundSubmitEdit:', err.message)
        })
    }

    render() {
        if(!this.state.program){
            return null
        }

        // console.log('geoprog', this.state.program)
        const admin1Rel = createExistingRel(this.state.admin1Data, 'state', 'admin_area_lvl_1',this.handleGeoRelDelete)
        const admin2Rel = createExistingAdmin2Rel(this.state.admin2Data, this.handleGeoRelDelete)
        const localityRel = createExistingRel(this.state.localityData, 'city_town', 'locality', this.handleGeoRelDelete)
        const censusTractRel = createExistingRel(this.state.censusTractData, 'id', 'census_tract', this.handleGeoRelDelete)
        const zctaRel = createExistingRel(this.state.zctaData, 'id','zcta', this.handleGeoRelDelete)
        const countryRel = createExistingRel(this.state.countryData, 'name','country', this.handleGeoRelDelete)
        const showStateSelect = this.state.createRel.type === "County" ? true : false
        const boundIncomplete = this.state.program.boundary_incomplete ? <Form.Group><Form.Check custom type='checkbox' id='boundary_incomplete' name='boundary_incomplete' label={<b>Boundary Incomplete [FOR DEV TEAM USE]</b>} defaultChecked={true} onChange={this.handleCheckOnChange}/></Form.Group> : <Form.Group><Form.Check custom type='checkbox' id='boundary_incomplete' name='boundary_incomplete' label={<b>Boundary Incomplete [FOR DEV TEAM USE]</b>} onChange={this.handleCheckOnChange}/></Form.Group>
        const relationshipList = makeRegionOptionList(regionArray)

        return (
            <>
                <Container fluid>
                    <Form id='formCreateProgram' onSubmit={this.handleAddGeoRel}>
                        <Row>
                            <Col xs={6}>
                                <Card id='r-gen-card'>
                                    <Card.Body>
                                        <Form.Group controlId="control-program_id">
                                            <Form.Label><b>Program ID</b></Form.Label>
                                            <Form.Control ref='id' name="id" type="text" value={this.state.program.id} disabled />
                                        </Form.Group>   

                                        <Form.Group controlId="control-program_name">
                                            <Form.Label><b>Program Name</b></Form.Label>
                                            <Form.Control ref='program_name' name="program_name" type="text" value={this.state.program.program_name} disabled/>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                <Card id='r-gen-card'>
                                    <Card.Body>
                                        <Form.Group controlId="control-add-relationship">
                                            <Form.Label><b>Add Relationship</b></Form.Label>
                                            <div style={{display:"flex"}}>
                                            <Form.Control as="select" defaultValue='Boundary Type' onChange={this.handleBoundarySelect}>
                                                <option disabled defaultValue>Boundary Type</option>
                                                <option>Country</option>
                                                <option>State</option>
                                                <option>County</option>
                                                <option>City_Town</option>
                                                <option>Census_Tract</option>
                                                <option>ZCTA</option>
                                            </Form.Control>
                                            <Form.Control placeholder="Value" type="text" value={this.state.createRel.value} onChange={this.onValueChange}></Form.Control>
                                            {showStateSelect &&
                                                <Form.Control as="select" defaultValue='State' onChange={this.handleStateSelect}>
                                                    <option disabled defaultValue>State</option>
                                                    {relationshipList}
                                                </Form.Control>
                                            }
                                            <Button variant="outline-primary" type="submit" style={{marginRight:'50px'}}>Add</Button>
                                            </div>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                <Card id='r-gen-card'>
                                    <Card.Body>
                                        <Form.Group controlId="control-rel-country">
                                            <Form.Label><b>Country</b></Form.Label>
                                            { countryRel.length > 0 &&
                                                <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                    {countryRel}
                                                </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>

                                    <Card.Body>
                                        <Form.Group controlId="control-rel-admin1">
                                            <Form.Label><b>State</b></Form.Label>
                                            {admin1Rel.length > 0 &&
                                            <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                {admin1Rel}
                                            </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>

                                    <Card.Body>
                                        <Form.Group controlId="control-rel-admin2">
                                            <Form.Label><b>County</b></Form.Label>
                                            {admin2Rel.length > 0 &&
                                            <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                {admin2Rel}
                                            </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>

                                    <Card.Body>
                                        <Form.Group controlId="control-rel-locality">
                                            <Form.Label><b>City/Town</b></Form.Label>
                                            {localityRel.length > 0 &&
                                            <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                {localityRel}
                                            </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>

                                    <Card.Body>
                                        <Form.Group controlId="control-rel-census-tract">
                                            <Form.Label><b>Census Tract Id</b></Form.Label>
                                            {censusTractRel.length > 0 &&
                                            <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                {censusTractRel}
                                            </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>

                                    <Card.Body>
                                        <Form.Group controlId="control-rel-zcta">
                                            <Form.Label><b>ZCTA (Zip Code Tabulation Area)</b></Form.Label>
                                            {zctaRel.length > 0 &&
                                            <div style={{maxHeight: '350px', overflow:'scroll'}}>
                                                {zctaRel}
                                            </div>
                                            }
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col xs={6}>
                                <Card id='r-gen-card'>
                                    <Card.Body>
                                        <div style={{display:'flex',justifyContent:'space-between', alignItems:'baseline' }}>
                                            <Button variant="outline-primary" type="button" onClick={this.handleAddBoundNotes}>Save Boundary Notes</Button>

                                            {boundIncomplete}
                                        </div>
                                            
                                        <Form.Group controlId="control-program_boundary">
                                            <Form.Label><b>Program Boundary Description</b></Form.Label>
                                            <Form.Control ref='program_boundary' name="program_boundary" as="textarea" value={this.state.program.program_boundary || ' '} onChange={this.onInputChange} />
                                        </Form.Group>

                                        <Form.Group controlId="control-boundary_specification">
                                            <Form.Label><b>Boundary Specification</b></Form.Label>
                                            <Form.Control ref='boundary_specification' name="boundary_specification" as="textarea" value={this.state.program.boundary_specification || ' '} onChange={this.onInputChange} />
                                        </Form.Group>

                                        <Form.Group controlId="control-boundary_specification_notes">
                                            <Form.Label><b> Boundary Specification Notes </b></Form.Label>
                                            <Form.Control ref='boundary_specification_notes' name="boundary_specification_notes" as="textarea" value={this.state.program.boundary_specification_notes || ''} onChange={this.onInputChange} />
                                        </Form.Group>

                                        <Form.Group controlId="control-boundary_implementation">
                                            <Form.Label><b>Program Implementation [FOR DEV TEAM USE]</b></Form.Label>
                                            <Form.Control ref='boundary_implementation' name="boundary_implementation" as="textarea" value={this.state.program.boundary_implementation || ' '} onChange={this.onInputChange} />
                                        </Form.Group>

                                        <Form.Group controlId="control-boundary_implementation_notes">
                                            <Form.Label><b>Program Implementation Notes [FOR DEV TEAM USE]</b></Form.Label>
                                            <Form.Control ref='boundary_implementation_notes' name="boundary_implementation_notes" as="textarea" value={this.state.program.boundary_implementation_notes || ' '} onChange={this.onInputChange} />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        );
    }
}

function createExistingRel(arr, bound, table, func){
    let idArr = []
    let result = []
    
    // console.log('arr', arr)
    // console.log('bound', bound)
    arr.forEach((i) => {
        idArr.push(i[bound])
    })
    idArr.sort()

    idArr.map((e,dex) => {
        // console.log('e val', e)
        return result.push(
            <Form.Group controlId={'control-'+ e} key={dex+1} style={{display:'flex', marginBottom:'.25rem'}} onSelect={() =>{console.log('hey')}}>
                <Form.Control key={dex} name={bound} type="text" value={e} disabled/>
                <Button variant="danger" onClick={() => func(e, table)}><HighlightOffOutlinedIcon/></Button>
            </Form.Group>
        )
    })

    return result
}

function createExistingAdmin2Rel(arr,func){
    let result = []

    arr.sort(compare)

    arr.map((e,dex) => {
        return result.push(
            <Form.Group controlId={'control-'+e.county} key={dex+1} style={{display:'flex', marginBottom:'.25rem'}}>
                <Form.Control key={dex} name={'county'} type="text" value={e.county} disabled/>
                <Form.Control key={dex+2} name={'county'} type="text" value={e.state} disabled/>
                <Button variant="danger" onClick={()=>func(e.county, 'admin_area_lvl_2' ,e.state)}><HighlightOffOutlinedIcon/></Button>
            </Form.Group>
        )
    })

    return result
}

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const compareA = a.program_id.toUpperCase();
    const compareB = b.program_id.toUpperCase();
  
    let comparison = 0;
    if (compareA > compareB) {
      comparison = 1;
    } else if (compareA < compareB) {
      comparison = -1;
    }
    return comparison;
  }

const regionArray = ['United States', 'Connecticut', 'New Jersey', 'New York', 'Massachusetts','Pennsylvania', 'Virginia', 'Florida', 'Washington D.C.','Maryland', 'North Carolina', 'South Carolina', 'Ohio', 'Georgia', 'Texas', 'Illinois', 'California', 'Oregon', 'Washington','Michigan']

function makeRegionOptionList(arr){
    const result = []
    arr.forEach((i,x) => {
        result.push(
            <option key={x}>{i}</option>
        )
    })
    return result
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    builder: state.builder.menuConfig
    }
}

export default connect(mapStateToProps, builderActions)(AdminGeoTable);