import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
// import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
//   Login: "[Login] Action",
  GetReports: "[Get Reports] Action",
  GetPrograms: "[Get Programs] Action",
};

const initialAuthState = {
  reports: undefined,
  programs: undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: ["user", "authToken", "reports"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.GetReports: {
          const { reports }  = action.payload;
          return { ...state, reports };
        }

        case actionTypes.GetPrograms: {
            const { programs } = action.payload;
            return { ...state, programs};
        }

        default:
          return state;
      }
    }
);

export const actions = {
//   login: (authToken, user) => ({ type: actionTypes.Login, payload: { authToken, user } }),
  getReports: (reports) => ({ type: actionTypes.GetReports, payload: { reports } }),
  getPrograms: (programs) => ({ type: actionTypes.GetPrograms, payload: { programs } }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   // const { data: user } = yield getUserByToken();
  //   const name  = yield getUserByToken(store.getState().auth.authToken);

  //   yield put(actions.fulfillUser(name));
  // });

  // yield takeLatest(actionTypes.GetReports, function* getReportsSaga() {
  //   const reports = yield getUserReports(store.getState().auth.authToken);
  //   console.log('in saga for getrep', reports)
  //   yield put(actions.fillReports(reports));
  // });
}
