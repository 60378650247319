import React from 'react';
import Linkify from 'linkifyjs/react';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';    
import Row from 'react-bootstrap/Row';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../../../_metronic/_assets/sass/pages/report/incentive.scss';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ProgramHeadlinePill from './program-headline-pill';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LinkIcon from '@material-ui/icons/Link';
import { ArrowUpRight } from 'react-bootstrap-icons';

    const typeCat = {
        type_property_tax: 'Property Tax',
        type_federal_income_tax: 'Federal Income Tax',
        type_state_income_tax: 'State Income Tax',
        type_business_tax: 'Business Tax',
        type_capital_gains_tax: 'Capital Gains Tax',
        type_sales_tax: 'Sales & Use Tax',
        type_mortgage_recording_tax: 'Mortgage Recording Tax',
        type_transfer_tax: 'Transfer Tax',
        type_other_tax: 'Other Tax',
        type_grant: 'Grant',
        type_financing: 'Financing',
        type_customs_duty: 'Custom Duty',
        type_permit_fee: 'Permit Fee',
        type_energy: 'Utility Cost',
        type_zoning: 'Zoning Bonus',
        type_bidding: 'Bidding Priority',
        type_rebate: 'Rebate'
    }

    const assetClassCat = {
        class_multi_lg: 'Multifamily >50 units',
        class_multi_sm: 'Multifamily <50 units',
        class_other_residential: 'Other Residential',
        class_retail: 'Retail',
        class_office: 'Office',
        class_other_commercial: 'Other Commercial',
        class_manufacturing: 'Manufacturing',
        class_other_industrial: 'Other Industrial'
    }

    const standardsCat = {
        cat_affordable: 'Affordable Housing',
        cat_asofright: 'As-of-right',
        cat_bldg_preservation: 'Building Preservation',
        cat_design_standards: 'Design & Construction',
        cat_cleanup: 'Environmental Cleanup',
        cat_green: 'Green Building',
        cat_operating: 'Operating Business',
        cat_sci_tech: 'Science & Technology',
        cat_public_partner: 'Public / Nonprofit Partner',
    }

    const strategyCat = {
        strat_reno: 'Renovation',
        strat_new: 'New Construction',
        strat_exist: 'Existing',
    }

// const alert = '#fd397a';
const warn = '#ffb822';

class ProgramModal extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            program: this.props.program,
            tabValue: 0,
        }
    }
    componentDidMount() {}

    handleTabChange = (e,v) => {
        const newVal = v
        
        this.setState({
            tabValue: newVal
        })

    }

    render() {
        if(Object.keys(this.props.data).length === 0){
            return null
        }

        const programData = this.props.data
        const progAlert = programData.program_alert
        const programName =  programData.program_name
        const programHeadline = programData.program_headline
        const programSummary = programData.program_summary
        const capitalSummary = programSummary ? programSummary.charAt(0).toUpperCase() + programSummary.slice(1) : ''
        const formatSummary =  formatProgramSummary(capitalSummary)
        const programBenefit = programData.program_benefit
        const capitalBenefit = programBenefit? formatBenefitSection(programBenefit.charAt(0).toUpperCase() + programBenefit.slice(1)) : ''
        const progDetermination = programData.program_approval_process === 'As-of-right' ? 'As-of-right' : 'Competitive / Discretionary'
        const progAppFee = programData.program_application_fee
        const progTiming = programData.program_timeframe
        const progAppLink = programData.program_application_link
        const progLegLink = programData.program_legislation_links
        const progAdmin = programData.program_admin
        const progAdminEmail = programData.program_admin_email
        const progAdminPhone = programData.program_admin_phone
        const progIncep = programData.program_date_start
        const progSite = programData.program_link
        const progLegName = programData.program_legislation_name
        const progJuris =  programData.program_jurisdiction ? programData.program_jurisdiction.toUpperCase() : ''
        const programReqs = programData.program_requirements
        const programNotes = programData.program_notes
        const lastUpdated = programData.last_updated ? new Date(programData.last_updated).toLocaleDateString() : ''
        const listProgCon = makeProgConList(programData.program_conflict)
        const listProgNotes = makeProgNoteList(programNotes)
        const showNotes = listProgNotes.length > 0 || listProgCon.length > 0 ? true : false
        const listProgReqs = makeProgReqList(programReqs)
        const listProgAppProcess = makeAppProcessList(programData.program_application_steps)
        const listFormatStrat = formatProgClass(strategyCat, programData)
        const listFormatType = formatProgClass(typeCat, programData)
        const listFormatAssetClass = formatProgClass(assetClassCat, programData)
        const listFormatStandard = formatProgClass(standardsCat, programData)
        const listProgAdmin = makeProgAdminList(progAdmin)
        const listProgRelated = makeProgRelatedList(programData.program_related)
        const beneText = beneficiaryText(programData.program_beneficiary)
        const renderRelatedProgTip = <Tooltip>Blue designates similar or compatible programs <br/> <br/>Red designates conflicting programs</Tooltip>
        const renderApplyTip = <Tooltip>Designates when you apply to the program in relation to the work that is required in order to recieve the benefit</Tooltip>
        const applyPrior = programData.eligible_project_status.includes('Pre-development') ? 'Prior to Work': ''
        const applyAfter = programData.eligible_project_status.includes('Post-development') ? 'After work is completed': ''
        const newsLinks = programData.news_link ? makeNewsLinkList(programData.news_link) : ''

        return (
            <Modal
                dialogClassName="custom-modal-width"
                backdrop={this.props.backdrop}
                keyboard={false}
                show={this.props.show}
                onHide={this.props.onHide}
                address={this.props.address}
                report_id={this.props.report_id}>
                <Modal.Body style={{padding: '1.25rem 0'}}>
                    <Container id='redist-container' fluid className='program-container'>
                        <Row>
                            <Col style={{padding: '0'}}>
                                <Card id='r-gen-card'>
                                    <Card.Body style={{ paddingBottom: '0',backgroundColor: 'transparent'}}>
                                        <div style={{ display:'flex', justifyContent:'space-between'}}>
                                            <Card.Text as='h5'><b>{progJuris}</b></Card.Text>
                                            <Link 
                                                id='link-style' 
                                                to={{pathname:'/project_id/' + this.props.report_id + '/program_id/' + this.props.data.id}} 
                                                target="_blank"
                                                style={{width:'auto'}}
                                            >
                                                <ArrowUpRight/>
                                            </Link>
                                        </div>
                                        <Card.Text as='h1' style={{color: '#7146C2'}}><b>{programName}</b></Card.Text>
                                        <div className='program-headline'>
                                            <Card.Text as='h2' style={{marginRight: '20px'}}>
                                                {programHeadline}
                                            </Card.Text>
                                            <ProgramHeadlinePill 
                                                lightAsof={programData.program_approval_process} 
                                                hasAsof={'show'}
                                                programData={programData}
                                            />
                                        </div>
                                        <Row style={{justifyContent:'space-between'}}>
                                            <Paper square id='min-tab-height'>
                                                <Tabs value={this.state.tabValue} indicatorColor="primary" textColor="primary" onChange={this.handleTabChange} id='min-tab-height'>
                                                    <Tab label="Overview" value={0}/>
                                                    <Tab label="Application &amp; Additional Details" value={1}/>
                                                </Tabs>
                                            </Paper>
                                            <div style={{display: 'flex', alignItems:'center'}}><h6>LAST UPDATED &nbsp;{lastUpdated}</h6></div>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        { this.state.tabValue === 0 ? (
                            <div>
                            {progAlert && progAlert.length > 5 &&
                                <Row>
                                    <Col xs={12} style={{padding:'0', marginBottom:'15px'}}>
                                        <Card>
                                            <Card.Header as='h4' id='prog_alert' style={{backgroundColor:'rgba(255, 184, 34, 1)', border:'none'}}>
                                                <ErrorOutlineOutlinedIcon style={{marginRight:'10px'}}/>
                                                <b>
                                                    <Linkify
                                                        options={{
                                                            format: {url: (val) => {return <LinkIcon/> }}
                                                        }}>
                                                        {progAlert}
                                                    </Linkify>
                                                </b>
                                            </Card.Header>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={6} style={{padding:'0', paddingRight:'10px'}}>
                                    <Card id='r-gen-card'>
                                        <Card.Header id='r-gen-card-header' as='h4'>Summary</Card.Header>
                                        <Card.Body as='h4'>
                                            {formatSummary}
                                        </Card.Body>
                                    </Card>
                                    <Card id='r-gen-card'>
                                        <Card.Header id='r-gen-card-header' as='h4'>Benefit</Card.Header>
                                        <Card.Body as='h4'>{capitalBenefit}</Card.Body>
                                        <Card.Body style={{display:'flex'}}><b>BENEFICIARY:</b>{beneText}</Card.Body>
                                    </Card>
                                    { listProgReqs.length > 0 &&
                                        <Card id='r-gen-card'>
                                            <Card.Header id='r-gen-card-header' as='h4'>Requirements</Card.Header>
                                            <Card.Body>                                
                                                {listProgReqs}
                                            </Card.Body>
                                        </Card>
                                    }
                                </Col>
                                <Col xs={6} style={{padding:'0',paddingLeft:'10px'}}>
                                    <Card id='r-gen-card'>
                                        <Card.Header id='r-gen-card-header' as='h4'>Administration</Card.Header>
                                        <Card.Body>
                                            <Row style={{marginBottom:'10px'}}>
                                                <Col >
                                                    <Card.Text as='h4'style={{fontWeight:'600'}}>Agencies</Card.Text>
                                                </Col>
                                                <Col style={{flex:'2'}}>
                                                    {listProgAdmin}
                                                </Col>
                                            </Row>
                                            { progAdminEmail && 
                                                <Row>
                                                    <Col>
                                                        <Card.Text as='h4' style={{fontWeight:'600'}}>Email</Card.Text>
                                                    </Col>
                                                    <Col style={{flex:'2'}}>
                                                        <h4>{progAdminEmail}</h4>
                                                    </Col>
                                                </Row>
                                            }
                                            { progAdminPhone && 
                                                <Row>
                                                    <Col>
                                                        <Card.Text as='h4' style={{fontWeight:'600'}}>Phone</Card.Text>
                                                    </Col>
                                                    <Col style={{flex:'2'}}>
                                                        <h4>{progAdminPhone}</h4>
                                                    </Col>
                                                </Row>
                                            }
                                            { progIncep &&
                                                <Row>
                                                    <Col>
                                                        <Card.Text as='h4' style={{fontWeight:'600'}}>Inception</Card.Text>
                                                    </Col>
                                                    <Col style={{flex:'2'}}>
                                                        <h4>{progIncep}</h4>
                                                    </Col>
                                                </Row>
                                            }
                                            {progSite !== '' &&
                                                <a href={progSite} className='btn btn-outline-info btn-block'role='button' target="_blank" style={{marginTop: '20px'}} rel="noopener noreferrer">Program Website</a>
                                            }
                                        </Card.Body>
                                    </Card> 
                                    <Card id='r-gen-card'>
                                        <Card.Header id='r-gen-card-header' as='h4'>Classification</Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <Card style={{marginBottom: '10px'}}>
                                                        <Card.Body>
                                                            <div><h6 className='program__classification' >TYPE</h6></div>
                                                            {listFormatStrat}
                                                        </Card.Body>
                                                    </Card>
                                                    <Card style={{marginBottom: '10px'}}>
                                                        <Card.Body>
                                                            <div><h6 className='program__classification' >ASSET CLASS</h6></div>
                                                            {listFormatAssetClass}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card style={{marginBottom: '10px'}}>
                                                        <Card.Body>
                                                            <div><h6 className='program__classification' >CATEGORY</h6></div>
                                                            {listFormatType}
                                                        </Card.Body>
                                                    </Card>
                                                    <Card style={{marginBottom: '10px'}}>
                                                        <Card.Body>
                                                            <div><h6 className='program__classification' >ADDITIONAL</h6></div>
                                                            {listFormatStandard}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            </div>
                        ) : (
                            <Row>
                                <Col xs={6} style={{padding:'0', paddingRight:'10px'}}>
                                    <Card id='r-gen-card'>
                                        <Card.Header id='r-gen-card-header' as='h4' style={{display: 'flex', justifyContent: ' space-between'}}>
                                            Application Process
                                            {/* { !progAppLink &&
                                                <div style={{alignItems:'center', justifyContent:'space-between',display:'flex'}}>
                                                    Apply Now
                                                    <Button variant='secondary' disabled>Link to Application</Button>
                                                </div>
                                            } */}
                                            { progAppLink && 
                                                <div style={{alignItems:'center', justifyContent:'space-between',display:'flex'}}>
                                                    <a href={progAppLink} target="_blank" className='btn btn-info'role='button'style={{marginLeft:'10px', color: '#fff'}} rel="noopener noreferrer">Apply Now</a> 
                                                </div>
                                            }
                                        </Card.Header>
                                        <Card.Body>
                                            <Row style={{marginBottom:'10px'}}>
                                                <Col>
                                                    <Card.Text as='h4' style={{fontWeight:'600'}}>Determination</Card.Text>
                                                </Col>
                                                <Col>
                                                    <h4>{progDetermination}</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom:'10px'}}>
                                                <Col>
                                                <OverlayTrigger
                                                    placement='right'
                                                    delay={{show:250, hide:400}}
                                                    overlay={renderApplyTip}>
                                                    <Card.Text as='h4' style={{fontWeight:'600', maxWidth:'60px',borderBottom:'1px dashed rgba(0, 0, 0, 0.25)', cursor:'help'}}>Apply</Card.Text>
                                                </OverlayTrigger>
                                                </Col>
                                                <Col>
                                                    <h4>{applyPrior} {applyAfter}</h4>
                                                </Col>
                                            </Row>
                                            {progAppFee && 
                                                <Row style={{marginBottom:'10px'}}>
                                                    <Col>
                                                        <Card.Text as='h4' style={{fontWeight:'600'}}>Application Fee</Card.Text>
                                                    </Col>
                                                    <Col>
                                                    <Linkify
                                                        options={{
                                                            format: {url: (val) => {return <LinkIcon/> }}
                                                        }}>
                                                            <h4>{progAppFee}</h4>
                                                    </Linkify>
                                                    </Col>
                                                </Row>
                                            }
                                            {progTiming && 
                                                <Row style={{marginBottom:'10px'}}>
                                                    <Col>
                                                        <Card.Text as='h4' style={{fontWeight:'600'}}>Timing</Card.Text>
                                                    </Col>
                                                    <Col>
                                                        <h4>{progTiming}</h4>
                                                    </Col>
                                                </Row>
                                            }
                                            {listProgAppProcess}

                                        </Card.Body>
                                    </Card>

                                    { showNotes &&
                                        <Card id='r-gen-card'>
                                            <Card.Header id='r-gen-card-header' as='h4'>Notes</Card.Header>
                                            <Card.Body>
                                                {listProgNotes}
                                            </Card.Body>
                                        </Card>    
                                    }
                                </Col>
                                <Col xs={6} style={{padding:'0',paddingLeft:'10px'}}>
                                    {/* To add news section, taking title:url; parsing to create button with title as label and link as redirect target */}
                                    {newsLinks.length > 0 &&
                                        <Card id='r-gen-card'>
                                            <Card.Header id='r-gen-card-header' as='h4'>News</Card.Header>
                                            <Card.Body>
                                                {newsLinks}
                                            </Card.Body>
                                        </Card>
                                    }

                                    {progLegName &&
                                        <Card id='r-gen-card'>
                                            <Card.Header id='r-gen-card-header' as='h4'>Legislation</Card.Header>
                                            <Card.Body>
                                                {progLegName &&  <a href={progLegLink} target="_blank" className='btn btn-outline-info btn-block'role='button' rel="noopener noreferrer">{progLegName}</a> }
                                            </Card.Body>
                                        </Card>
                                    }

                                    {(listProgRelated.length !== 0 || listProgCon.length !== 0) &&
                                        <Card id='r-gen-card'>
                                                <OverlayTrigger
                                                    placement='right'
                                                    delay={{show:250, hide:400}}
                                                    overlay={renderRelatedProgTip}>
                                                    <Card.Header id='r-gen-card-header' style={{fontWeight:'600', width:'160px',borderBottom:'1px dashed rgba(0, 0, 0, 0.25)', cursor:'help'}} as='h4'>Related Programs</Card.Header>
                                                </OverlayTrigger>
                                            <Card.Body>
                                                {listProgRelated}
                                                {listProgCon}
                                            </Card.Body>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

function formatProgramSummary(Str){
    return(
        <Linkify
            options={{
                format: {url: (val) => {return 'Source'}}
            }}>
            {Str}
        </Linkify>
    )
}

function formatBenefitSection(Str){
    return(
        <Linkify
            options={{
                format: {url: (val) => {return <LinkIcon/>}}
            }}>
            {Str}
        </Linkify>
    )
}

function makeProgReqList(str){
    if(str == null || str === ''){return []}
    const strArr = str.split(';')
    let newArr = []
    
    if(strArr.length < 1){return newArr}

    strArr.forEach((e,i) => {
            newArr.push(<Card key={i} style={{marginBottom:'15px'}}>
            <Card.Body>
                <Card.Text as='h4' key={i}>
                    <Linkify
                        options={{
                            format: {url: (val) => {return <LinkIcon/>}}
                        }}>
                        {e}
                    </Linkify>
                </Card.Text>
                </Card.Body>
            </Card>)
    })
    return newArr
}

function makeProgNoteList(str) {
    if(str == null || str === ''){return []}
    const strArr = str.split(';')
    let newArr = []

    if(strArr.length < 1){return newArr}
    
    strArr.forEach((e,i) => {
        newArr.push(
        <Card key={i} style={{marginBottom:'15px'}}>
            <Card.Body>
                <Card.Text as='h5' key={i} style={{display: 'flex', alignItems:'center'}}>
                    <ErrorOutlineOutlinedIcon style={{color: warn, marginRight:'10px'}}/>
                    <div style={{maxWidth:'450px'}}>
                        <Linkify
                            options={{
                                format: {url: (val) => {return <LinkIcon/>}}
                            }}>
                            {e}
                        </Linkify>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>)
    })
    return newArr
}

function makeNewsLinkList(str){

    let newSets = str.split(';')
    let result = []
    
    newSets.forEach((e,i) => {
        let titleUrl = e.replace(':', '|') 
        let newTitleUrl = titleUrl.split('|')

        result.push(
            <Button href={newTitleUrl[1]} target='_blank' key={i} variant='outline-info' block>{newTitleUrl[0]}</Button>
        )
    })
    return result 
}

function makeAppProcessList(str) {
    if(str == null){return}
    const strArr = str.split(';')
    let newArr = []

    strArr.map((e,i) => {
        if(e.length <= 1){return undefined}
      return  newArr.push(<Card key={i+1} style={{marginBottom:'15px'}}>
            <Card.Body>
                <Card.Text as='h4' key={i}>
                    <Linkify
                        options={{
                            format: {url: (val) => {return <LinkIcon/>}}
                        }}>
                        {e}
                    </Linkify>
                </Card.Text>
            </Card.Body>
        </Card>)
    })
    return newArr
}

function formatProgClass(objCat, objState){
    const keyNames = Object.keys(objCat)
    const resArr = []

    keyNames.forEach((e,i) => {
       if(objState[e]) {
           resArr.push(<h5 key={i} style={{textAlign: 'center', marginBottom:'5px', fontWeight:'600'}}>{objCat[e]}</h5>)
        } 
    })
    return resArr
}

function makeProgAdminList(str) {
    if(str == null){return}
    const strArr = str.split(';')
    let newArr = []

    strArr.map((e,i) => {
       return newArr.push(<Card.Text as='h4' key={i}>{e}</Card.Text>)
    })
    return newArr
}

function makeProgRelatedList(strArr) {
    let newArr = []

    if(!strArr){return newArr}
    if(strArr.length < 1){return newArr}

    strArr.map((e,i) => {
        // newArr.push(<a href={'togo add program relations'} className='btn btn-outline-info btn-block'role='button'></a> )
      return  newArr.push(<Button key={i} variant='info' block>{e}</Button>)
    })
    return newArr
}

function makeProgConList(strArr) {
    let newArr = []

    if(!strArr){return newArr}
    if(strArr.length < 1){return newArr}

    strArr.map((e,i) => {
        return newArr.push(
            <Button key={i} variant='danger' block>{e}</Button>
        )
    })
    return newArr
}

function beneficiaryText(data){ 
    let result = []

    if(!data){return result}
    
    data.forEach((e,i) => {
        result.push(
            <p key={i} style={{margin:'0 5px'}}>{e}</p>
        )
    })

    return result
}

export default ProgramModal