import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ClipboardCheck, CheckSquareFill, PlusSquareDotted } from 'react-bootstrap-icons';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


export default function ProgramEntry(props) {
    const asOfRightTip = <Tooltip>This program has 'as-of-right' qualification criteria</Tooltip>
    const progSummaryTip = <Tooltip>{props.program.program_summary}</Tooltip>
    const progBenefitTip = <Tooltip>{props.program.program_benefit}</Tooltip>
    const headlineTip = <Tooltip className='inlineToolTip'>{props.estimateVal}</Tooltip>
    const estimateIcon = handleEstimateIcon(props.estimateStr)
    // const estimateString = props.estimateStr === 'Custom Amount' ? <FileText size={20}/> : props.estimateStr
    const estimateString = props.estimateStr === 'Custom Amount' ? "..." : props.estimateStr

  return (
    <>    
    {props.hidden
        ?   <ListGroup.Item style={{display:'flex',alignItems:'center',height:'3em', borderWidth:'0 0 1px 0', padding:'0 10px', opacity:'0.4'}} onClick={() => { props.programModal(props.program) }}>
                <div className='program-entry--list-item'>
                    <div style={{display:'flex'}}>
                        <OverlayTrigger
                            placement='right'
                            delay={{show:250, hide:400}}
                            overlay={headlineTip}>
                            <h5 className='redistTip' style={{margin: '0', fontWeight: '600'}}>{props.program.program_name}</h5>
                        </OverlayTrigger>
                    </div>
                    {props.program.program_approval_process === 'As-of-right' &&
                        <OverlayTrigger
                            placement='right'
                            delay={{show:250, hide:400}}
                            overlay={asOfRightTip}>
                                <ClipboardCheck size={20} color={'grey'} style={{marginLeft:10}}/>
                        </OverlayTrigger>}
                </div>
                <div style={{marginLeft:'20px', display:'flex', alignItems:'center'}}>
                    <OverlayTrigger
                        placement='left'
                        delay={{show:250, hide:400}}
                        overlay={progBenefitTip}>
                        <h5 className='redistTip' style={{marginRight:'5px', marginBottom:0}} >{estimateString}</h5>
                    </OverlayTrigger>
                    {estimateIcon}
                </div>
            </ListGroup.Item>

        : 
            <ListGroup.Item style={{display:'flex',alignItems:'center',height:'3em', borderWidth:'0 0 1px 0', padding:'0 10px'}} onClick={() => { props.programModal(props.program) }}>
                <div className='program-entry--list-item'>
                    <div style={{display:'flex'}}>
                        <OverlayTrigger
                            placement='left'
                            delay={{show:250, hide:400}}
                            overlay={progSummaryTip}>
                            <h5 className='redistTip'  style={{margin: '0', fontWeight: '600'}}>{props.program.program_name}</h5>
                        </OverlayTrigger>
                    </div>
                    {props.program.program_approval_process === 'As-of-right' &&
                        <OverlayTrigger
                            placement='right'
                            delay={{show:250, hide:400}}
                            overlay={asOfRightTip}>
                                <ClipboardCheck size={20} color={'grey'}  style={{marginLeft:10}}/>
                        </OverlayTrigger>}
                </div>
                <div style={{marginLeft:'20px', display:'flex', alignItems:'center'}}>
                    <OverlayTrigger
                        placement='left'
                        delay={{show:250, hide:400}}
                        overlay={progBenefitTip}>
                        <h5 className='redistTip' style={{marginRight:'5px', marginBottom:0}}>{estimateString}</h5>
                    </OverlayTrigger>
                    {estimateIcon}
                </div>
            </ListGroup.Item>
    }
    </>
  );

    function handleEstimateIcon(str){
        const supportedTip = <Tooltip>REDESTIMATE is currently supported for this program. Estimates will be automatically generated as input variables are provided.</Tooltip>
        const notSupportedTip = <Tooltip>REDISTIMATE functionality for this program is currently in development. Please refer to the specific program details for a summary of the program benefit and determination of value.</Tooltip>
        let result  = []

        if(str === 'Custom Amount' ){
            result.push(
                <OverlayTrigger
                    key={notSupportedTip}
                    placement='left'
                    delay={{show:250, hide:400}}
                    overlay={notSupportedTip}>
                    <PlusSquareDotted color='#FBAA00' size={15}/>
                </OverlayTrigger>
            )
        } else {
            result.push(
                <OverlayTrigger
                    key={supportedTip}
                    placement='left'
                    delay={{show:250, hide:400}}
                    overlay={supportedTip}>
                    <CheckSquareFill color='#0ABB87' size={15}/>
                </OverlayTrigger>
            )
        }
        return result
    }
}
