import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import * as firebase from 'firebase/app';
import 'firebase/auth';

class Logout extends Component {
  componentDidMount() {
    firebase.auth().signOut()
    .then(() => {
      window.location.reload();
    })
    .catch((error)=> {
    // An error happened.
      console.log('error while trying to log out ', error.message);
    })
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
