import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// export const ME_URL = "api/me";

// export function login(email, password) {
//   return axios.post(LOGIN_URL, { email, password });
// }

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
  return axios.get(`/users/current`,
    {headers: {authorization: `Bearer ${token}`}})
    .then((res) => {
      return res.data[0]
    })
    .catch((error) => {
      console.log('err msg', error.message);
    })
}
