import React from 'react';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
const { googleMapApiKey } = require('../../../config');

//Use and abstract redist-apikey, not your own

export default function StaticMap(props) {
    const fStatic = `https://maps.googleapis.com/maps/api/staticmap?key=${googleMapApiKey}&libraries=drawing&center=${props.staticLat},${props.staticLng}&zoom=${props.staticZoom}&markers=icon:https://img.icons8.com/color/48/000000/down.png|${props.staticLat},${props.staticLng}&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels%7Cvisibility:off&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=640x400&scale=2`
    return (
        <>
            <img className='static-map-container' alt='static-map' style={{ width: props.staticWidth, height: props.staticHeight }} src={fStatic}></img>
        </>
  );
}
