import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { getPrograms} from '../../crud/report.crud';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


class AdminInterface extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            programs: [],
            editProgramId: '',
        }
    }
    componentDidMount() {
        if(this.props.user.is_admin === false){
            this.props.history.push("/dashboard")
        }

        getPrograms()
            .then( res => {
                this.setState({
                    programs: res
                })
            })
            .catch( error => {
                console.log('err in get programs admin interface:', error.message)
            })
       

        this.props.setMenuConfig(AdminMenuConfig)
    }

    handleName = (event, newValue) => {
        const prog_id = newValue.id

        this.setState({
            editProgramId: prog_id,
        })
    }

    render() {
        if(!this.state.programs){
            return null
        }

        const programs = this.state.programs
        const formatProgs = formatProgramNames(programs)
        const editProgramLink = this.state.editProgramId !== '' ? <Link to={ '/admin_program_id/' + this.state.editProgramId}><Button variant="outline-primary">Edit</Button></Link> : <Button variant="outline-primary">Edit</Button>
        const viewProgramLink = this.state.editProgramId !== '' ? <Link to={ '/admin_program_id_view/' + this.state.editProgramId}><Button variant="outline-success">View</Button></Link> : <Button variant="outline-primary">View</Button>
        const editGeoLink = this.state.editProgramId !== '' ? <Link to={'/admin_geo_table_id/' + this.state.editProgramId}><Button variant="outline-primary">Edit Geo</Button></Link> : <Button variant="outline-primary">Edit Geo</Button>

        return (
            <>
                <Container style={{backgroundColor:'#fff', height: '100%'}}>
                    <Form>
                        <Row>
                            <Col>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                    <div style={{height: '50px', width:'100px', border:'2px solid', textAlign:'center'}}>
                                        <h2>{programs.length}</h2>
                                    </div>
                                    <h6>no. of incentives</h6>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{padding:'20px 0', marginBottom:'40px'}}>
                            <Col md={6}> 
                                <div>
                                <Autocomplete
                                    options={formatProgs}
                                    getOptionLabel={(option) => option.program_name}
                                    onChange={this.handleName}
                                    renderInput={(params) => <TextField {...params} label='Program Name' variant='outlined' />}
                                />
                                </div >
                            </Col>
                            <Col style={{display:'flex',alignItems:'center'}}>
                                {editProgramLink}
                                {editGeoLink}
                                {viewProgramLink}
                            </Col>
                            <Col md={3} style={{display:'flex',alignItems:'center'}}>
                                <Link style={{color: 'rgb(72,70,91)'}} to={ '/admin_program_create/' }>
                                    <Button style={{marginLeft: '10px'}} variant="outline-success">New</Button>
                                </Link>
                            </Col>
                        </Row>

                        <Row style={{marginBottom:'60px'}}>
                            <Col md={8}>
                                <div>
                                <Autocomplete
                                    options={formatProgs}
                                    getOptionLabel={(option) => `${option.program_admin} : ${option.program_name}`}
                                    onChange={this.handleName}
                                    renderInput={(params) => <TextField {...params} label='Program Agency : Name' variant='outlined' />}
                                />
                                </div>
                            </Col>
                            <Col style={{display:'flex',alignItems:'center'}}>
                                {editProgramLink}
                                {editGeoLink}
                                {viewProgramLink}
                            </Col>
                        </Row>

                        <Row style={{marginBottom:'60px'}}>
                            <Col md={6}>
                                <Autocomplete
                                    options={formatProgs}
                                    getOptionLabel={(option) => option.id}
                                    onChange={this.handleName}
                                    renderInput={(params) => <TextField {...params} label='Program ID' variant='outlined' />}
                                />

                            </Col>
                            <Col style={{display:'flex',alignItems:'center'}}>
                                {editProgramLink}
                                {editGeoLink}
                                {viewProgramLink}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to={'/admin_users/'}>
                                    <Button variant="success">Edit Users</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        );
    }
}

function formatProgramNames(arr){
    let result = []

    arr.forEach(e => {
        if(e.program_name){
            result.push(e)
        }
    })

    return result
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    builder: state.builder.menuConfig
    }
}

export default connect(mapStateToProps, builderActions)(AdminInterface);