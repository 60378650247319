import React from 'react';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Badge from 'react-bootstrap/Badge';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


export default function ReportStatusBadge(props) {
    const processed = props.status ? 'success': 'warning'
    const programsCount = props.programsCount
    const pillColorVariant = props.variant ? 'info' : 'success'
    const programsFoundText = programsCount === '1' ? <h6>Program Found</h6> : <h6>Programs Found</h6>
  return (
    <>  
        {processed === 'warning' && !props.isOutOfTristate &&
            <Badge pill variant='warning' id='report-pill'>
                    <AccessTimeOutlinedIcon fontSize={'small'} style={{marginRight: '.2em'}}/>
                <h6>Report Pending</h6>
            </Badge>
        }

        {processed === 'warning' && props.isOutOfTristate &&
            <Badge pill variant='danger' id='report-pill'>
                    <ErrorOutline fontSize={'small'} style={{marginRight: '.2em'}}/>
                <h6>Outside Of Subscribed Boundary</h6>
            </Badge>
        }

        {processed === 'success' &&
            <Badge pill variant={pillColorVariant} id='report-pill'>
                    <PlaylistAddCheckOutlinedIcon fontSize={'small'} style={{marginRight: '10px'}}/>
                {programsCount} &nbsp; {programsFoundText}
            </Badge>
        }
    </>
  );
}
