import axios from "axios";

export function getPrograms(){
  return axios.get(`/users/current/programs`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getprograms err:', error.message);
    })
}

export function getUserAccount(){
  return axios.get(`/users/current/getUserAccount`)
    .then((res) => {
      return res.data[0]
    })
    .catch((error) => {
      console.log('getUserAccount err:', error.message);
    })
}

export function getProgramById(program_id){
  return axios.get(`/users/current/admin_program_id/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getprogramById crud err:', error.message);
    })
}

export function deleteProgram(program_id){
  return axios.delete(`/users/current/adminDeleteProgram/${program_id}`)
  .then((res) => {
    alert('Successfully Deleted')
  })
  .catch((error) => {
    console.log('delete prog crud err:', error.message)
    alert('Unsuccessful Deletion')
  })
}

export function deleteReport(report_id){
  return axios.delete(`/users/current/deleteReport/${report_id}`)
  .then((res) => {
    return true
  })
  .catch((error) => {
    return false
  })
}

export function getUserReports() {
  return axios.get(`/users/current/reports`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('user reports err:', error.message);
    })
}

export function getUserReport(reportId) {
    return axios.get(`/users/current/report_id/${reportId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        console.log('user report err:', error.message);
      })
}

export function getReportPrograms(reportId) {
    return axios.get(`/users/current/report_id/${reportId}/programs`)
        .then((res) => {
        return res.data
        })
        .catch((error) => {
        console.log('report progs err:', error.message);
        })
}

export function getReportProgram( reportId, programId) {
    return axios.get(`/users/current/report_id/${reportId}/program_id/${programId}`)
        .then((res) => {
        return res.data
        })
        .catch((error) => {
        console.log('report prog err msg', error.message);
        })
}

export function createReport(formObj){
  const {country, admin_area_lvl_1, admin_area_lvl_2, locality, address, postal_code, censusTractGeoId, latlng, report_name, zcta, bbl, borough, borough_code, block, lot, zone_dist_1, zone_dist_2, zone_dist_3, zone_dist_4, street_number, route, sublocality, outOfTriState, util_gas, util_electric, project_name, type, status, asset_class, approval_process, affordable_housing, affordable_max_percent_units, affordable_min_percent_ami, green_building, env_remediation, historic_preservation, public_non_profit_partner } = formObj

  return axios.post(`/users/current/createReport`, {
    country: country,
    admin_area_lvl_1: admin_area_lvl_1,
    admin_area_lvl_2: admin_area_lvl_2,
    locality: locality,
    address: address,
    postal_code: postal_code,
    censusTractGeoId: censusTractGeoId,
    point: latlng,
    report_name: report_name,
    zcta: zcta,
    bbl: bbl,
    borough: borough,
    borough_code: borough_code,
    block: block,
    lot: lot,
    zone_dist_1: zone_dist_1,
    zone_dist_2: zone_dist_2,
    zone_dist_3: zone_dist_3,
    zone_dist_4: zone_dist_4,
    street_number: street_number,
    route: route,
    sublocality: sublocality,
    out_of_tristate: outOfTriState,
    util_gas: util_gas,
    util_electric: util_electric,
    project_name: project_name,
    type: type,
    status: status,
    asset_class: asset_class,
    approval_process: approval_process,
    affordable_housing: affordable_housing,
    affordable_max_percent_units: affordable_max_percent_units,
    affordable_min_percent_ami: affordable_min_percent_ami ,
    green_building: green_building,
    env_remediation: env_remediation,
    historic_preservation: historic_preservation,
    public_non_profit_partner: public_non_profit_partner
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    console.log('err in creating report', err.message)
  })
}

export function createNotionProject(formObj){
  const {country, admin_area_lvl_1, admin_area_lvl_2, locality, address, postal_code, censusTractGeoId, latlng, report_name, zcta, bbl, borough, borough_code, block, lot, zone_dist_1, zone_dist_2, zone_dist_3, zone_dist_4, street_number, route, sublocality, outOfTriState, util_gas, util_electric, project_name, type, status, asset_class, approval_process, affordable_housing, affordable_max_percent_units, affordable_min_percent_ami, green_building, env_remediation, historic_preservation, public_non_profit_partner } = formObj

  return axios.post(`/users/current/createNotionProject`, {
    country: country,
    admin_area_lvl_1: admin_area_lvl_1,
    admin_area_lvl_2: admin_area_lvl_2,
    locality: locality,
    address: address,
    postal_code: postal_code,
    censusTractGeoId: censusTractGeoId,
    point: latlng,
    report_name: report_name,
    zcta: zcta,
    bbl: bbl,
    borough: borough,
    borough_code: borough_code,
    block: block,
    lot: lot,
    zone_dist_1: zone_dist_1,
    zone_dist_2: zone_dist_2,
    zone_dist_3: zone_dist_3,
    zone_dist_4: zone_dist_4,
    street_number: street_number,
    route: route,
    sublocality: sublocality,
    out_of_tristate: outOfTriState,
    util_gas: util_gas,
    util_electric: util_electric,
    project_name: project_name,
    type: type,
    status: status,
    asset_class: asset_class,
    approval_process: approval_process,
    affordable_housing: affordable_housing,
    affordable_max_percent_units: affordable_max_percent_units,
    affordable_min_percent_ami: affordable_min_percent_ami ,
    green_building: green_building,
    env_remediation: env_remediation,
    historic_preservation: historic_preservation,
    public_non_profit_partner: public_non_profit_partner
  })
  .catch(err => {
    console.log('err in creating notion', err.message)
  })
}

export function createProgram(formObj){
  const { id, program_region, program_jurisdiction, program_link, program_headline, program_summary, program_requirements, program_benefit, program_notes,program_conflicts, program_related, program_application_steps, program_application_link, program_application_fee, program_timeframe, program_date_start, program_date_end, program_legislation_name,program_legislation_links, program_admin, program_admin_phone, program_admin_email, strat_new, strat_reno, strat_exist, class_multi_lg, class_multi_sm,class_other_residential, class_retail, class_office, class_other_commercial, class_manufacturing, class_other_industrial, cat_design_standards, cat_affordable,cat_asofright, cat_public_partner, cat_bldg_preservation, cat_operating, cat_cleanup, cat_green, cat_sci_tech, type_property_tax, type_federal_income_tax,type_state_income_tax, type_business_tax, type_capital_gains_tax, type_sales_tax, type_mortgage_recording_tax, type_transfer_tax, type_other_tax, type_grant,type_financing, type_customs_duty, type_permit_fee, type_energy, type_zoning, type_bidding, type_rebate, boundary_specification, boundary_specification_notes, program_alert, program_boundary, boundary_implementation, boundary_implementation_notes, boundary_incomplete, eligible_project_type, eligible_project_status, program_beneficiary, program_primary_type, program_start_date, program_end_date, affordable_min_num_units, affordable_min_percent_units, affordable_max_percent_ami, program_approval_process, news_link, cat_syndicated, loan_type, loan_seniority, forgivable} = formObj

  let { program_name  } = formObj
  program_name = program_name.trim()

  return axios.post(`/users/current/adminCreateProgram`, {
    id: id,
    program_name: program_name,
    program_region: program_region,
    program_jurisdiction: program_jurisdiction,
    program_link: program_link,
    program_headline: program_headline,
    program_summary: program_summary,
    program_requirements: program_requirements,
    program_benefit: program_benefit,
    program_notes: program_notes,
    program_conflicts: program_conflicts,
    program_related: program_related,
    program_application_steps: program_application_steps,
    program_application_link: program_application_link,
    program_application_fee: program_application_fee,
    program_timeframe: program_timeframe,
    program_date_start: program_date_start,
    program_date_end: program_date_end,
    program_legislation_name: program_legislation_name,
    program_legislation_links: program_legislation_links,
    program_admin: program_admin,
    program_admin_phone: program_admin_phone,
    program_admin_email: program_admin_email,
    strat_new: strat_new, 
    strat_reno: strat_reno,
    strat_exist: strat_exist,
    class_multi_lg: class_multi_lg,
    class_multi_sm: class_multi_sm,
    class_other_residential: class_other_residential,
    class_retail: class_retail,
    class_office: class_office,
    class_other_commercial: class_other_commercial,
    class_manufacturing: class_manufacturing,
    class_other_industrial: class_other_industrial,
    cat_design_standards: cat_design_standards,
    cat_affordable: cat_affordable,
    cat_asofright: cat_asofright,
    cat_public_partner: cat_public_partner,
    cat_bldg_preservation: cat_bldg_preservation,
    cat_operating: cat_operating,
    cat_cleanup: cat_cleanup,
    cat_green: cat_green,
    cat_sci_tech: cat_sci_tech,
    type_property_tax: type_property_tax,
    type_federal_income_tax: type_federal_income_tax,
    type_state_income_tax: type_state_income_tax,
    type_business_tax: type_business_tax,
    type_capital_gains_tax: type_capital_gains_tax,
    type_sales_tax: type_sales_tax,
    type_mortgage_recording_tax: type_mortgage_recording_tax,
    type_transfer_tax: type_transfer_tax,
    type_other_tax: type_other_tax,
    type_grant: type_grant,
    type_financing: type_financing,
    type_customs_duty: type_customs_duty,
    type_permit_fee: type_permit_fee,
    type_energy: type_energy,
    type_zoning: type_zoning,
    type_bidding: type_bidding,
    type_rebate: type_rebate,
    boundary_specification: boundary_specification,
    boundary_specification_notes: boundary_specification_notes,
    program_alert: program_alert,
    program_boundary: program_boundary,
    boundary_implementation: boundary_implementation,
    boundary_implementation_notes: boundary_implementation_notes,
    boundary_incomplete: boundary_incomplete,
    eligible_project_type: eligible_project_type,
    eligible_project_status: eligible_project_status,
    program_beneficiary: program_beneficiary,
    program_primary_type: program_primary_type,
    program_start_date: program_start_date,
    program_end_date: program_end_date,
    affordable_min_num_units: affordable_min_num_units,
    affordable_min_percent_units: affordable_min_percent_units,
    affordable_max_percent_ami: affordable_max_percent_ami,
    program_approval_process: program_approval_process,
    news_link: news_link,
    cat_syndicated: cat_syndicated,
    loan_type: loan_type,
    loan_seniority: loan_seniority,
    forgivable: forgivable
  })
  .then(res => {
    return true
  })
  .catch(err => {
    // console.log('err in createProgram crud: ', err.message)
    alert('Unsuccessful Addition: '+ err.message)
    return false
  })
}

export function editProgram(formObj){
  const { id, program_name, program_region, program_jurisdiction, program_link, program_headline, program_summary, program_requirements, program_benefit, program_notes,program_conflicts, program_related, program_application_steps, program_application_link, program_application_fee, program_timeframe, program_date_start, program_date_end, program_legislation_name,program_legislation_links, program_admin, program_admin_phone, program_admin_email, strat_new, strat_reno, strat_exist, class_multi_lg, class_multi_sm,class_other_residential, class_retail, class_office, class_other_commercial, class_manufacturing, class_other_industrial, cat_design_standards, cat_affordable,cat_asofright, cat_public_partner, cat_bldg_preservation, cat_operating, cat_cleanup, cat_green, cat_sci_tech, type_property_tax, type_federal_income_tax,type_state_income_tax, type_business_tax, type_capital_gains_tax, type_sales_tax, type_mortgage_recording_tax, type_transfer_tax, type_other_tax, type_grant,type_financing, type_customs_duty, type_permit_fee, type_energy, type_zoning, type_bidding, type_rebate, boundary_specification, boundary_specification_notes, program_alert, program_boundary, boundary_implementation, boundary_implementation_notes, boundary_incomplete,     eligible_project_type, eligible_project_status, program_beneficiary, program_primary_type, program_start_date, program_end_date, affordable_min_num_units, affordable_min_percent_units, affordable_max_percent_ami, program_approval_process, news_link, cat_syndicated, loan_type, loan_seniority, forgivable} = formObj

  return axios.post(`/users/current/adminEditProgram`, {
    id: id,
    program_name: program_name,
    program_region: program_region,
    program_jurisdiction: program_jurisdiction,
    program_link: program_link,
    program_headline: program_headline,
    program_summary: program_summary,
    program_requirements: program_requirements,
    program_benefit: program_benefit,
    program_notes: program_notes,
    program_conflicts: program_conflicts,
    program_related: program_related,
    program_application_steps: program_application_steps,
    program_application_link: program_application_link,
    program_application_fee: program_application_fee,
    program_timeframe: program_timeframe,
    program_date_start: program_date_start,
    program_date_end: program_date_end,
    program_legislation_name: program_legislation_name,
    program_legislation_links: program_legislation_links,
    program_admin: program_admin,
    program_admin_phone: program_admin_phone,
    program_admin_email: program_admin_email,
    strat_new: strat_new, 
    strat_reno: strat_reno,
    strat_exist: strat_exist,
    class_multi_lg: class_multi_lg,
    class_multi_sm: class_multi_sm,
    class_other_residential: class_other_residential,
    class_retail: class_retail,
    class_office: class_office,
    class_other_commercial: class_other_commercial,
    class_manufacturing: class_manufacturing,
    class_other_industrial: class_other_industrial,
    cat_design_standards: cat_design_standards,
    cat_affordable: cat_affordable,
    cat_asofright: cat_asofright,
    cat_public_partner: cat_public_partner,
    cat_bldg_preservation: cat_bldg_preservation,
    cat_operating: cat_operating,
    cat_cleanup: cat_cleanup,
    cat_green: cat_green,
    cat_sci_tech: cat_sci_tech,
    type_property_tax: type_property_tax,
    type_federal_income_tax: type_federal_income_tax,
    type_state_income_tax: type_state_income_tax,
    type_business_tax: type_business_tax,
    type_capital_gains_tax: type_capital_gains_tax,
    type_sales_tax: type_sales_tax,
    type_mortgage_recording_tax: type_mortgage_recording_tax,
    type_transfer_tax: type_transfer_tax,
    type_other_tax: type_other_tax,
    type_grant: type_grant,
    type_financing: type_financing,
    type_customs_duty: type_customs_duty,
    type_permit_fee: type_permit_fee,
    type_energy: type_energy,
    type_zoning: type_zoning,
    type_bidding: type_bidding,
    type_rebate: type_rebate,
    boundary_specification: boundary_specification,
    boundary_specification_notes: boundary_specification_notes,
    program_alert: program_alert,
    program_boundary: program_boundary,
    boundary_implementation: boundary_implementation,
    boundary_implementation_notes: boundary_implementation_notes,
    boundary_incomplete: boundary_incomplete,
    eligible_project_type: eligible_project_type,
    eligible_project_status: eligible_project_status,
    program_beneficiary: program_beneficiary,
    program_primary_type: program_primary_type,
    program_start_date: program_start_date,
    program_end_date: program_end_date,
    affordable_min_num_units: affordable_min_num_units,
    affordable_min_percent_units: affordable_min_percent_units,
    affordable_max_percent_ami: affordable_max_percent_ami,
    program_approval_process: program_approval_process,
    news_link: news_link,
    cat_syndicated: cat_syndicated,
    loan_type: loan_type,
    loan_seniority: loan_seniority,
    forgivable: forgivable
  })
  .then(res => {
    return true
  })
  .catch(err => {
    // console.log('err in editProgram crud:', err.message)
    alert('Unsuccessful Edition: '+ err.message)
    return false
  })
}

export function editReportName(formObj){
  const {report_name, report_id} = formObj

  return axios.post(`/users/current/editReportName`, {
    report_name: report_name,
    report_id: report_id
  })
  .then(res => {
    return true
  })
  .catch(err => {
    return false
  })
}

export function editProgramBoundNotes(formObj){
  const { id, boundary_specification, boundary_specification_notes, program_boundary, boundary_implementation, boundary_implementation_notes, boundary_incomplete } = formObj

  return axios.post(`/users/current/adminEditProgramBoundNotes`, {
    id: id,
    boundary_specification: boundary_specification,
    boundary_specification_notes: boundary_specification_notes,
    program_boundary: program_boundary,
    boundary_implementation: boundary_implementation,
    boundary_implementation_notes: boundary_implementation_notes,
    boundary_incomplete: boundary_incomplete
  })
  .then(res => {
    alert('Program Successfully Added Boundary Notes')
  })
  .catch(err => {
    console.log('err in editProgramBoundData crud:', err.message)
    alert('Unsuccessful Boundary Note Addition: '+ err.message)
  })
}

export function getSearchedLocationData(lat, lng){
  return axios.get(`/users/current/gisData/lat/${lat}/lng/${lng}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log('get searched location data error:', err.message)
    })
}

export function getShallowProgramMatch(census_tract_id, zcta, admin_lvl_2, admin_lvl_1){
  if(admin_lvl_2 === ''){
    admin_lvl_2 = ' '
  }
  return axios.get(`/users/current/censusMatch/census_tract_id/${census_tract_id}/zcta/${zcta}/admin_lvl_2/${admin_lvl_2}/admin_lvl_1/${admin_lvl_1}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log('err in getShallowProgramMatch:', err.message)
    })
}

export function getSearchedLocationBBL(lat, lng, srid, geoType, spatialRel){
  return axios.get(`/nycBbl/lat/${lat}/lng/${lng}/srid/${srid}/geotype/${geoType}/spatialRel/${spatialRel}`)
    .then((res) => {
        //use if feature poly coords are needed
        const {data: {features}} = res
        //use if only feature attr are needed
        const [{attributes}] = features
        return  attributes
    })
    .catch((err) => {
      console.log('searched bbl data error/or no bbl data (ignore if outside nyc):', err.message)
    })
}

export function getUtilGasData(lat,lng){
  return axios.get(`/utilGas/lat/${lat}/lng/${lng}/`)
  .then((res) => {
      return  res.data
  })
  .catch((err) => {
    console.log('searched utilGas data error: ', err.message)
  })
}

export function getGeoTableAdmin1(program_id){
  return axios.get(`/users/current/admin_geo_table_admin1/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataAdmin1 crud err:', error.message);
    })
}

export function getGeoTableAdmin2(program_id){
  return axios.get(`/users/current/admin_geo_table_admin2/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataAdmin2 crud err:', error.message);
    })
}

export function getGeoTableLocality(program_id){
  return axios.get(`/users/current/admin_geo_table_locality/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataLocality crud err:', error.message);
    })
}

export function getGeoTableCensusTract(program_id){
  return axios.get(`/users/current/admin_geo_table_census_tract/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataCensus crud err:', error.message);
    })
}

export function getGeoTableZcta(program_id){
  return axios.get(`/users/current/admin_geo_table_zcta/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataZcta crud err:', error.message);
    })
}

export function getGeoTableCountry(program_id){
  return axios.get(`/users/current/admin_geo_table_country/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getGeoTableDataCountry crud err:', error.message);
    })
}

export function createGeoRel(program_id, formObj){
  const { type, value, state } = formObj

  const tables = {
    Country: 'country',
    State: 'admin_area_lvl_1',
    County: 'admin_area_lvl_2',
    City_Town: 'locality',
    Census_Tract: 'census_tract',
    ZCTA:'zcta'
  }

  const tableCols = {
    Country: 'name',
    State: 'state',
    County: 'county',
    City_Town: 'city_town',
    Census_Tract: 'id',
    ZCTA:'id'
  }

  return axios.post(`/users/current/adminCreateGeoRel`, {
    program_id: program_id,
    table: tables[type],
    column: tableCols[type],
    value: value,
    state: state
  })
  .then(res => {
    alert('Successfully Added')
  })
  .catch(err => {
    console.log('err in addGeoRel crud: ', err.message)
    alert('Unsuccessful Addition: '+ err.message)
  })
}

export function deleteGeoRel(program_id, value, tableName, state){
  const cols = {
    country: 'name',
    admin_area_lvl_1: 'state',
    admin_area_lvl_2: 'county',
    locality: 'city_town',
    census_tract: 'id',
    zcta: 'id'
  }

  const colName = cols[tableName]

  return axios.delete(`/users/current/adminDeleteGeoRel/${program_id}/${value}/${tableName}/${colName}/${state}`)
  .then((res) => {
    alert('Successfully Deleted')
  })
  .catch((error) => {
    console.log('delete geoRel crud err:', error.message)
    alert('Unsuccessful Deletion: '+ error.message)
  })
}

export function deleteRelatedProgram(program_id, relation){
  return axios.delete(`/users/current/admin_delete_related_program/${program_id}/${relation}`)
    .catch((error) => {
      console.log('delete related program err:', error.message)
      alert('Unsuccessful Deletion: '+ error.message)
    })
}

export function getRelatedPrograms(program_id){
  return axios.get(`/users/current/admin_related_program_id/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getRelatedPrograms crud err:', error.message);
    })
}

export function addProgramRelationship(obj){
  const { program1, program2 } = obj

  return axios.post(`/users/current/adminAddRelProgram`, {
    program1: program1,
    program2: program2
  })
    .then(res => {
      return true
    })
    .catch(err => {
      alert('Unsuccessful relationship addition: '+ err.message)
      return false
    })
}

export function addProgramConflict(obj){
  const { program1, program2 } = obj

  return axios.post(`/users/current/adminAddConProgram`, {
    program1: program1,
    program2: program2
  })
    .then(res => {
      return true
    })
    .catch(err => {
      alert('Unsuccessful conflict addition: '+ err.message)
      return false
    })
}

export function deleteConflictingProgram(program_id, conflict){
  return axios.delete(`/users/current/admin_delete_conflicting_program/${program_id}/${conflict}`)
    .catch((error) => {
      console.log('delete conflicting program err:', error.message)
      alert('Unsuccessful Deletion: '+ error.message)
    })
}

export function getConflictingPrograms(program_id){
  return axios.get(`/users/current/admin_conflicting_program_id/${program_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getRelatedPrograms crud err:', error.message);
    })
}

export function getUsers(){
  return axios.get(`/users/current/adminGetUsers`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('getUsers:', error.message);
    })
}

export function editUserAccount(formObj){
  const { name, email, firebase_id, is_admin, is_sub, a_la_carte_credit, sub_limit} = formObj
  let {sub_started} = formObj

  if(sub_started === ''){sub_started = null}

  return axios.post(`/users/current/adminEditUserAccount`, {
    name: name,
    email: email,
    firebase_id: firebase_id,
    is_admin: is_admin,
    is_sub: is_sub,
    a_la_carte_credit: a_la_carte_credit,
    sub_limit: sub_limit,
    sub_started: sub_started
  })
  .then(res => {
      return true
  })
  .catch(err => {
      console.log('Unsuccessful user account edit: '+ err.message)
      return false
  })
}

export function incrementSubUsedAmount(){
  return axios.post(`/users/current/incrementSubUsedAmount`)
    .then(res => {
      if(res){
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      return false
    })
}

export function decrementAlacarte(){
  return axios.post(`/users/current/decrementAlacarte`)
    .then(res => {
      if(res){
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      return false
    })
}