import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetParameters: "SET_PARAMS"
};

const initialAuthState = {
  parameters: undefined
};

export const reducer = persistReducer(
    { storage, key: "project-parameters", whitelist: ["parameters"] },
    (state = initialAuthState, action) => {
      switch (action.type) {

        case actionTypes.SetParameters: {
            const parameters = action.parameters;
            return {...state, parameters}
        }

        default:
          return state;
      }
    }
);

export const setparameters = (parameters) => ({
    type: actionTypes.SetParameters, 
    parameters
})
