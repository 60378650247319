export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Dashboards",
      //   root: true,
      //   alignment: "left",
      //   page: "dashboard",
      //   translate: "MENU.DASHBOARD"
      // }
      {
        title: 'Create Project',
        desc: 'Generates reports',
        root: true,
        page: 'create-project',
        icon: 'flaticon-edit-1'
      }
    ]
  },
  aside: {
    self: {},
    items: [
      // {
      //   title: "Dashboard",
      //   root: true,
      //   icon: "flaticon-home",
      //   page: "dashboard",
      //   translate: "MENU.DASHBOARD"
      // },
      {
        title: 'Create Project',
        desc: 'Generates reports',
        root: true,
        page: 'create-project',
        icon: 'flaticon-edit-1'
      },
      {
        title: 'Current Projects',
        desc: 'Some desc',
        root: true,
        page: 'current-projects',
        icon: 'flaticon-folder-1'
      },
      // {
      //   title: "Layout Builder",
      //   root: true,
      //   icon: "flaticon2-expand",
      //   page: "builder"
      // },
      // { section: "Components" },
      // {
      //   title: "Material UI",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon2-browser-2",
      //   submenu: [
      //     {
      //       title: "Layout",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Box",
      //           page: "google-material/layout/box"
      //         },
      //         {
      //           title: "Container",
      //           page: "google-material/layout/container"
      //         },
      //         {
      //           title: "Grid",
      //           page: "google-material/layout/grid"
      //         },
      //         {
      //           title: "Grid list",
      //           page: "google-material/layout/grid-list"
      //         },
      //         {
      //           title: "Hidden",
      //           page: "google-material/layout/hidden"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Inputs",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Autocomplete",
      //           page: "google-material/inputs/autocomplete",
      //           permission: "accessToECommerceModule"
      //         },
      //         {
      //           title: "Buttons",
      //           page: "google-material/inputs/buttons"
      //         },
      //         {
      //           title: "Checkboxes",
      //           page: "google-material/inputs/checkboxes"
      //         },
      //         {
      //           title: "Pickers",
      //           page: "google-material/inputs/pickers"
      //         },
      //         {
      //           title: "Radio Buttons",
      //           page: "google-material/inputs/radio-buttons"
      //         },
      //         {
      //           title: "Selects",
      //           page: "google-material/inputs/selects"
      //         },
      //         {
      //           title: "Switches",
      //           page: "google-material/inputs/switches"
      //         },
      //         {
      //           title: "Text Fields",
      //           page: "google-material/inputs/text-fields"
      //         },
      //         {
      //           title: "Transfer List",
      //           page: "google-material/inputs/transfer-list"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Navigation",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Bottom Navigation",
      //           page: "google-material/navigation/bottom-navigation"
      //         },
      //         {
      //           title: "Breadcrumbs",
      //           page: "google-material/navigation/breadcrumbs"
      //         },
      //         {
      //           title: "Drawers",
      //           page: "google-material/navigation/drawer"
      //         },
      //         {
      //           title: "Links",
      //           page: "google-material/navigation/links"
      //         },
      //         {
      //           title: "Menus",
      //           page: "google-material/navigation/menus"
      //         },
      //         {
      //           title: "Steppers",
      //           page: "google-material/navigation/steppers"
      //         },
      //         {
      //           title: "Tabs",
      //           page: "google-material/navigation/tabs"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Surfaces",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "App Bar",
      //           page: "google-material/surfaces/app-bar"
      //         },
      //         {
      //           title: "Paper",
      //           page: "google-material/surfaces/paper"
      //         },
      //         {
      //           title: "Cards",
      //           page: "google-material/surfaces/cards"
      //         },
      //         {
      //           title: "Expansion Panels",
      //           page: "google-material/surfaces/expansion-panels"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Feedback",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Progress",
      //           page: "google-material/feedback/progress"
      //         },
      //         {
      //           title: "Dialogs",
      //           page: "google-material/feedback/dialogs"
      //         },
      //         {
      //           title: "Snackbars",
      //           page: "google-material/feedback/snackbars"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Data Display",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Avatars",
      //           page: "google-material/data-displays/avatars"
      //         },
      //         {
      //           title: "Badges",
      //           page: "google-material/data-displays/badges"
      //         },
      //         {
      //           title: "Chips",
      //           page: "google-material/data-displays/chips"
      //         },
      //         {
      //           title: "Dividers",
      //           page: "google-material/data-displays/dividers"
      //         },
      //         {
      //           title: "Icons",
      //           page: "google-material/data-displays/icons"
      //         },
      //         {
      //           title: "Lists",
      //           page: "google-material/data-displays/lists"
      //         },
      //         {
      //           title: "Tables",
      //           page: "google-material/data-displays/tables"
      //         },
      //         {
      //           title: "Tooltips",
      //           page: "google-material/data-displays/tooltips"
      //         },
      //         {
      //           title: "Typography",
      //           page: "google-material/data-displays/typography"
      //         }
      //       ]
      //     },
      //     {
      //       title: "Utils",
      //       bullet: "dot",
      //       submenu: [
      //         {
      //           title: "Click Away Listener",
      //           page: "google-material/utils/click-away-listener"
      //         },
      //         {
      //           title: "Modal",
      //           page: "google-material/utils/modal"
      //         },
      //         {
      //           title: "No SSR",
      //           page: "google-material/utils/no-ssr"
      //         },
      //         {
      //           title: "Popover",
      //           page: "google-material/utils/popover"
      //         },
      //         {
      //           title: "Popper",
      //           page: "google-material/utils/popper"
      //         },
      //         {
      //           title: "Portal",
      //           page: "google-material/utils/portal"
      //         },
      //         {
      //           title: "Transitions",
      //           page: "google-material/utils/transitions"
      //         },
      //         {
      //           title: "useMediaQuery",
      //           page: "google-material/utils/use-media-query"
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  }
};
