import React from 'react';
import Badge from 'react-bootstrap/Badge';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


export default function programHeadlinePill(props) {
    const activeState = checkActiveDate(props.programData)
  return (
    <>    
      {activeState}
    </>
  );
  
  function checkActiveDate(p){
    let tDate = new Date()
    let result = []

    if(p.program_end_date){
        let eDate = new Date(p.program_end_date)
        if(tDate > eDate){
          result.push(
            <Badge key={eDate} pill variant='danger' id='report-pill'><h6 style={{font:'black'}}>Inactive</h6></Badge>
          )
          return result
        } else {
          result.push(
            <Badge key={tDate} pill variant='success' id='report-pill-program'><h6 style={{color:'black'}}>Active</h6></Badge>
          )
        }
    }
    
    if(p.program_start_date){
      result = []
      let fDate = new Date(p.program_start_date)

      if(tDate < fDate){
        result.push(
          <Badge key={fDate} pill variant='danger' id='report-pill'><h6 style={{font:'black'}}>Inactive</h6></Badge>
        )
        return result
      } else {
        result.push(
          <Badge key={tDate} pill variant='success' id='report-pill-program'><h6 style={{color:'black'}}>Active</h6></Badge>
        )
      }
    }
    
    if(!p.program_start_date && !p.program_end_date) {
      result = []
      result.push(
        <Badge key={tDate} pill variant='success' id='report-pill-program'><h6 style={{color:'black'}}>Active</h6></Badge>
      )
    }
    return result
  }
}
