import React from 'react';
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';


export default function Address(props) {
  const sublocalityCheck = props.sublocality ? `${props.sublocality.toUpperCase()},` : ''
  const localityCheck = props.locality !== '' ? `${props.locality.toUpperCase()},` : sublocalityCheck
  const cutStreetRoute = props.address ? props.address.split(',')[0] : ''
  const streetAndRoute = props.street_number === '' && props.route === '' ? cutStreetRoute : <>{props.street_number} {props.route}</>
  return (
    <>    
      <div style={{marginBottom: '5px'}}>
        <h5 style={{marginBottom:'0', fontWeight:'600'}}>{streetAndRoute}</h5>
        <h6>{localityCheck} {props.admin_lvl_1.toUpperCase()} {props.zcta}</h6>
      </div>
    </>
  );
}
