import React from 'react';
import { connect } from 'react-redux';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { getProgramById, createProgram, getPrograms, deleteProgram, editProgram, addProgramRelationship, getRelatedPrograms, deleteRelatedProgram, getConflictingPrograms, addProgramConflict, deleteConflictingProgram} from '../../crud/report.crud';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import '../../../_metronic/_assets/sass/pages/redist-main/main.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';


class AdminProgram extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            programList: [],
            program: null,
            progSDateMsg: false,
            progEDateMsg: false,
            eligTypeMsg: false,
            eligStatusMsg: false,
            apprProcessMsg: false,
            benefitMsg: false,
            primaryTypeMsg: false,
            setProgramList: {},
            isNewProg: false,
            confirmDelete: false,
            confirmDeleteMsg: false,
            supplyProgIdDeleteMsg: false,
            relConProgramId: '',
            relatedProgramList: [],
            conflictingProgramList: []
        }
    }
    componentDidMount() {
        const program_id = this.props.match.params.program_id
        
        if(this.props.user.is_admin === false){
            this.props.history.push("/dashboard")
        }
    
        if(program_id){
            getProgramById(program_id)
                .then( res => {
                    const [data] = res
                    this.setState({
                        program: data
                    })

                    if(this.state.program.program_start_date){
                        let formatDate = new Date(this.state.program.program_start_date).toISOString().split('T')[0]
                        this.setState(prevState => ({
                            program: {
                                ...prevState.program,
                                program_start_date: formatDate
                            }
                        })) 
                    }

                    if(this.state.program.program_end_date){
                        let formatDate = new Date(this.state.program.program_end_date).toISOString().split('T')[0]
                        this.setState(prevState => ({
                            program: {
                                ...prevState.program,
                                program_end_date: formatDate
                            }
                        })) 
                    }
                })
                .catch( error => {
                    console.log('err in getprogram adminprogram:', error.message)
                })

            getRelatedPrograms(program_id)
                .then( res => {
                    let data  = []

                    res.forEach(e => {
                        data.push(e.relation)
                    })

                    this.setState({
                        relatedProgramList: data
                    })
                })
                .catch( error => {
                    console.log('err in getRelatedPrograms:', error.message)
                })

            getConflictingPrograms(program_id)
                .then( res => {
                    let data  = []

                    res.forEach(e => {
                        data.push(e.conflicting)
                    })

                    this.setState({
                        conflictingProgramList: data
                    })
                })
                .catch( error => {
                    console.log('err in getConflictingPrograms :', error.message)
                })
        } else{
            this.setState({
                program: newProgram,
                isNewProg: true
            })
        }


        Promise.resolve().then(() => {    
            return getPrograms()
                .then( res => {
                    this.setState({
                        programList: res
                    })
                })
                .catch( error => {
                    console.log('err in get programs admin program:', error.message)
                })
        })
        .then(() => {
            progIdList(this.state.programList, this.handleSetLists)
        })

        this.props.setMenuConfig(AdminMenuConfig)
    }

    onInputChange = (e) => {
        const eTarget = e.target.name
        const eValue = e.target.value

        this.setState(prevState => ({
            program:{
                ...prevState.program,
                [eTarget]: eValue
        }}))
    }

    handleRegionChange = (e) => {
        const target = e.target.value
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                program_region: target
            }
        }))

        if(this.state.isNewProg){
            this.handleNewProgIdSuggest(target)
        }
    }

    handleNewProgIdSuggest = (bound) => {
        const list = this.state.setProgramList
        const arrLength = list[bound].length-1
        const lastProgId = list[bound][arrLength]
        
        let progInt = parseInt(lastProgId.slice(2))
        const newProgInt = (progInt + 1).toString()
        const newProgId = lastProgId.slice(0,2) + this.leadingZero(newProgInt)

        this.setState(prevState => ({
            program: {
                ...prevState.program,
                id: newProgId
            }
        }))    
    }

    leadingZero = (str) => {
        const strLen = str.length

        if(strLen === 4){return str}
        if(strLen === 3){
            return '0'+str
        } else if(strLen === 2){
            return '00'+str
        }
    }

    handleJurisChange = (e) => {
        const target = e.target.value
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                program_jurisdiction: target
            }
        }))
    }
    
    handleCheckOnChange = (e) =>{
        const targetName = e.target.name
        const targetChecked = e.target.checked

        this.setState(prevState => ({
            program: {
                ...prevState.program,
                 [targetName]: targetChecked
            }
        }))
    }

    handleMultiCheck = (e) => {
        const targetName = e.target.name
        const targetType = multiSelectProject[targetName]
        const targetChecked = e.target.checked
        const eliArr = this.state.program[targetType]
        let resArr = []

        if(targetChecked === false){
            resArr = eliArr.filter(wrd => wrd !== targetName)
        } else {
            resArr = eliArr
            resArr.push(targetName)
        }

        this.setState(prevState => ({
            program: {
                ...prevState.program,
                [targetType]: resArr
            }
        }))

    }

    handlePrimaryChange = (e) => {
        const target = e.target.value
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                program_primary_type: target
            }
        }))
    }

    handleApprovalChange = (e) => {
        const target = e.target.value
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                program_approval_process: target
            }
        }))
    }

    handleProgSubmit = (e) => {
        e.preventDefault();


        if(this.state.program.id === '' || this.state.program.program_name === ''){
            return undefined
        }

        // if(!this.requiredInput()){
        //     alert('Fill in all required fields')
        //     return
        // }

        this.formatEmtpyDecimal()


        if(this.formatDateInput(this.state.program.program_start_date,'program_start_date')){
            this.setState({
                progSDateMsg: false
            })
        } else {
            this.setState({
                progSDateMsg: true
            })
            alert('Check start date format')
            return
        }

        if(!this.formatDateInput(this.state.program.program_end_date,'program_end_date')){
            this.setState({
                progEDateMsg: true
            }) 
            alert('Check end date format')
            return
        } else {
            this.setState({
                progEDateMsg: false
            })
        }

        setTimeout(() => {
            if(this.state.isNewProg){
                const data = this.state.program
                createProgram(data)
                .then(res => {
                    if(res){
                        alert('Program successfully added')
                        this.props.history.push("/admin-interface")
                    }
                })
                .catch(err => {
                    console.log('error in handleProgSubmitCreate:', err.message)
                })
            } else {
                const data = this.state.program
                editProgram(data)
                .then(res => {
                    if(res){
                        alert('Program successfully edited')
                        this.props.history.push("/admin-interface")
                    } else {
                        alert('Double check inputs eg. dates/decimals')
                        return
                    }
                })
                .catch(err => {
                    console.log('error in handleProgSubmitEdit:', err.message)
                })
            }
        }, 500)
    }

    handleSetLists = (obj) => {
        this.setState({
            setProgramList: obj
        })
    }

    handleConfirmDeleteCheck = (e) => {
        const targetChecked = e.target.checked

        this.setState({
            confirmDelete: targetChecked,
            confirmDeleteMsg: false
        })
    }

    handleProgramDelete = () => {
        if(this.state.confirmDelete === false){
            this.setState({
                confirmDeleteMsg: true
            })
            return
        }

        deleteProgram(this.state.program.id)
            .then(res => {
                this.props.history.push("/admin-interface")
                window.location.reload()
            })
            .catch(err => {
                console.log('err in deleteprogram admin program:' ,err.message)
            })

    }

    formatDateInput = (date,sOe) => {
        const format = new RegExp(/(\d{4}[-]\d{2}[-]\d{2})/).test(date)
        if(format || date === '' || date === null){
            if(date === ''){
                this.setState(prevState => ({
                    program: {
                        ...prevState.program,
                        [sOe]: null
                    }
                }))
            }
            return true
        }
        return false
    }

    formatEmtpyDecimal = () => {
        const deciArr = ['affordable_min_num_units', 'affordable_min_percent_units', 'affordable_max_percent_ami']

        deciArr.forEach(e => {
            if(this.state.program[e] === ''){
                this.setState(prevState => ({
                    program: {
                        ...prevState.program,
                        [e]: 0
                    }
                }))
            }
        })

    }

    requiredInput = () => {
        const reqArr = ['eligible_project_type', 'eligible_project_status', 'program_approval_process', 'program_beneficiary', 'program_primary_type']
        const reqObj = {
            eligible_project_type: 'eligTypeMsg',
            eligible_project_status: 'eligStatusMsg',
            program_approval_process: 'apprProcessMsg',
            program_beneficiary: 'benefitMsg',
            program_primary_type: 'primaryTypeMsg'
        }

        let flag = 0

        reqArr.forEach(req => {
            if(this.notEmptyValCheck(this.state.program[req])){
                this.setState({
                    [reqObj[req]]: false
                })
            } else {
                this.setState({
                    [reqObj[req]]: true
                })
                flag ++
            }
        })
        if(flag > 0){return false}
        return true
    }

    notEmptyValCheck = (val) => {
        if(val && val.length > 0){
            return true
        }
        return false
    }

    handleSuggestRel = (event, newValue) => {
        if(!newValue){return}
        const prog_id = newValue.id

        this.setState({
            relConProgramId: prog_id,
        })
    }

    handleProgRel = () => {
        //figure out indexing
        const progId = this.state.program.id
        const progRelId = this.state.relConProgramId
        let data = {
            program1: progId,
            program2: progRelId
        }

        if(progId === ''){
            return alert('Please first create a program id')
        }

        Promise.resolve().then(() => {    
            addProgramRelationship(data)
        })
        .then(() => {

            setTimeout(() => {
                getRelatedPrograms(progId)
                    .then( res => {
                        let data  = []

                        res.forEach(e => {
                            data.push(e.relation)
                        })
            
                        this.setState({
                            relatedProgramList: data
                        })
                    })
                    .catch( error => {
                        console.log('err in getRelatedPrograms :', error.message)
                    })
            }, 1500)
            
        })
        .catch(err => {
            console.log('error in formatDataPromise:', err.message)
        }) 
    }

    handleProgCon = () => {
        //figure out indexing
        const progId = this.state.program.id
        const progConId = this.state.relConProgramId
        let data = {
            program1: progId,
            program2: progConId
        }

        if(progId === ''){
            return alert('Please first create a program id')
        }

        Promise.resolve().then(() => {    
            addProgramConflict(data)
        })
        .then(() => {

            setTimeout(() => {
                getConflictingPrograms(progId)
                    .then( res => {
                        let data  = []

                        res.forEach(e => {
                            data.push(e.conflicting)
                        })
            
                        this.setState({
                            conflictingProgramList: data
                        })
                    })
                    .catch( error => {
                        console.log('err in getConflictingPrograms :', error.message)
                    })
            }, 1500)
            
        })
        .catch(err => {
            console.log('error in formatDataPromise:', err.message)
        }) 
    }

    handleRelDelete = (value) => {
        //firgure out promise issue
        const programId = this.state.program.id
        const relation = value

        Promise.resolve().then(() => {    
            deleteRelatedProgram(programId, relation)
        })
        .then(() => {
            setTimeout(() => {
                getRelatedPrograms(programId)
                    .then( res => {
                        let data  = []

                        res.forEach(e => {
                            data.push(e.relation)
                        })
            
                        this.setState({
                            relatedProgramList: data
                        })
                    })
                    .catch( error => {
                        console.log('err in delete RelatedPrograms :', error.message)
                    })
            }, 1500)
            
        })
        .catch(err => {
            console.log('error in formatDataPromise:', err.message)
        }) 
    }

    handleConDelete = (value) => {
        //firgure out promise issue
        const programId = this.state.program.id
        const conflicting = value

        Promise.resolve().then(() => {    
            deleteConflictingProgram(programId, conflicting)
        })
        .then(() => {
            setTimeout(() => {
                getConflictingPrograms(programId)
                    .then( res => {
                        let data  = []

                        res.forEach(e => {
                            data.push(e.conflicting)
                        })
            
                        this.setState({
                            conflictingProgramList: data
                        })
                    })
                    .catch( error => {
                        console.log('err in delete conflicting program :', error.message)
                    })
            }, 1500)
            
        })
        .catch(err => {
            console.log('error in formatDataPromise:', err.message)
        }) 
    }

    render() {
        if(!this.state.program || !this.state.programList){
            return null
        }

        const programList = formatProgramNames(this.state.programList)
        const relatedProgramList = this.state.relatedProgramList
        const conflictingProgramList = this.state.conflictingProgramList
        // const listStratCheck = makeCatCheckbox(this.state.program, strategyCat, this.handleCheckOnChange)
        const listAssestCheck = makeCatCheckbox(this.state.program, assetClassCat, this.handleCheckOnChange)
        const listTypeCheck = makeCatCheckbox(this.state.program, typeCat, this.handleCheckOnChange)
        const listAdditionalCheck = makeCatCheckbox(this.state.program, additionalCat, this.handleCheckOnChange)
        const eligProjType = this.state.program.eligible_project_type ? makeMultiTypeCheckbox(this.state.program, eligible_project_type, 'eligible_project_type', this.handleMultiCheck) : ''
        const eligProjStatus = this.state.program.eligible_project_status ? makeMultiTypeCheckbox(this.state.program, eligible_project_status, 'eligible_project_status', this.handleMultiCheck) : ''
        const projBenefit = this.state.program.program_beneficiary ? makeMultiTypeCheckbox(this.state.program, program_beneficiary, 'program_beneficiary', this.handleMultiCheck) : ''
        const loanType = this.state.program.loan_type ? makeMultiTypeCheckbox(this.state.program, loan_type, 'loan_type', this.handleMultiCheck) : ''
        const loanSeniorioty = this.state.program.loan_seniority ? makeMultiTypeCheckbox(this.state.program, loan_seniority, 'loan_seniority', this.handleMultiCheck) : ''
        // const progEnd = this.state.program.program_date_end ? <Form.Check custom type='checkbox' id='program_date_end' name='program_date_end' label='Program Ended' defaultChecked={true} onChange={this.handleCheckOnChange}/> : <Form.Check custom type='checkbox' id='program_date_end' name='program_date_end' label='Program Ended' onChange={this.handleCheckOnChange}/>
        const startDateInp = this.state.progSDateMsg? <Form.Control style={{borderColor:'red'}} ref='program_start_date' name="program_start_date" type="text" value={this.state.program.program_start_date || ''} onChange={this.onInputChange} /> : <Form.Control ref='program_start_date' name="program_start_date" type="text" value={this.state.program.program_start_date || ''} onChange={this.onInputChange} />  
        const endDateInp = this.state.progEDateMsg? <Form.Control style={{borderColor:'red'}} ref='program_end_date' name="program_end_date" type="text" value={this.state.program.program_end_date || ''} onChange={this.onInputChange} /> : <Form.Control ref='program_end_date' name="program_end_date" type="text" value={this.state.program.program_end_date || ''} onChange={this.onInputChange} />
        const confirmDelete = this.state.confirmDeleteMsg ?  <div style={{color: 'red'}}>Are you sure this program should be deleted?</div> : null
        const eligProjTypeCheck = this.state.eligTypeMsg ? <Form.Label style={{border: '2px solid red'}}><b>Eligible Project Types</b></Form.Label> : <Form.Label><b>Eligible Project Types</b></Form.Label>
        const eligProjStatusCheck = this.state.eligStatusMsg ? <Form.Label style={{border: '2px solid red'}}><b>Apply</b></Form.Label> : <Form.Label><b>Apply</b></Form.Label>
        const progAppProcessCheck = this.state.apprProcessMsg ? <Form.Label style={{border: '2px solid red'}}><b>Approval Process</b></Form.Label> : <Form.Label><b>Approval Process</b></Form.Label>
        const progBenefitCheck = this.state.benefitMsg ? <Form.Label style={{border: '2px solid red'}}><b>Beneficiary / Applicant</b></Form.Label> : <Form.Label><b>Beneficiary / Applicant</b></Form.Label>
        const progPrimaryTypeCheck = this.state.primaryTypeMsg ? <Form.Label style={{border: '2px solid red'}}><b>Primary</b></Form.Label> : <Form.Label><b>Primary</b></Form.Label>
        const regionList = makeRegionOptionList(regionArray)
        const relConEntries = createRelProgEntry(programList, relatedProgramList, conflictingProgramList, this.handleRelDelete, this.handleConDelete)

        return (
            <>
                <Container style={{backgroundColor:'#fff'}}>
                    <Form id='formCreateProgram' onSubmit={this.handleProgSubmit}>
                        <Row style={{alignItems:'center', justifyContent:'space-around'}}>
                            <Col xs={6}>
                                <Form.Group controlId="control-program_id">
                                    <Form.Label><b>Program ID</b></Form.Label>
                                    <Form.Control ref='id' name="id" type="text" value={this.state.program.id} disabled />
                                    {confirmDelete}
                                </Form.Group>
                                
                            </Col>
                            <Button variant="outline-primary" type="submit" style={{marginRight:'50px'}}>Save</Button>

                            <Form.Group controlId="control-confirm-delete" style={{marginBottom:'0'}}>
                                <Form.Check custom type="checkbox" id="delete-program" label="Confirm Delete" onChange={this.handleConfirmDeleteCheck}/>
                            </Form.Group>

                            <Button variant="outline-primary" onClick={this.handleProgramDelete}>Delete</Button>

                        </Row>

                        <Form.Group controlId="control-program_name">
                            <Form.Label><b>Program Name</b></Form.Label>
                            <Form.Control ref='program_name' name="program_name" type="text" value={this.state.program.program_name} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_region">
                            <Form.Label><b>Program Region</b></Form.Label>
                            <Form.Control as="select" onChange={this.handleRegionChange}>
                                <option>{this.state.program.program_region}</option>
                                {regionList}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="control-program_jurisdiction">
                            <Form.Label><b>Program Jurisdiction</b></Form.Label>
                            <Form.Control as="select" onChange={this.handleJurisChange}>
                                <option>{this.state.program.program_jurisdiction}</option>
                                <option>Federal</option>
                                <option>State</option>
                                <option>Local</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="control-program_link">
                            <Form.Label><b>Program Website</b></Form.Label>
                            <Form.Control ref='program_link' name="program_link" type="text" value={this.state.program.program_link || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_headline">
                            <Form.Label><b>Program Headline</b></Form.Label>
                            <Form.Control ref='program_headline' name="program_headline" as="textarea" value={this.state.program.program_headline || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_summary">
                            <Form.Label><b>Program Summary</b></Form.Label>
                            <Form.Control ref='program_summary' name="program_summary" as="textarea" value={this.state.program.program_summary || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_requirements">
                            <Form.Label><b>Program Requirements</b></Form.Label>
                            <Form.Control ref='program_requirements' name="program_requirements" as="textarea" value={this.state.program.program_requirements || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_benefit">
                            <Form.Label><b>Program Benefit</b></Form.Label>
                            <Form.Control ref='program_benefit' name="program_benefit" as="textarea" value={this.state.program.program_benefit || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_notes">
                            <Form.Label><b>Program Notes</b> eg. note;note</Form.Label>
                            <Form.Control ref='program_notes' name="program_notes" as="textarea" value={this.state.program.program_notes || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        {/* <Form.Group controlId="control-program_conflicts">
                            <Form.Label><b>Known Conflicts</b> eg. conflict;conflict</Form.Label>
                            <Form.Control ref='program_conflicts' name="program_conflicts" type="text" value={this.state.program.program_conflicts || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_related">
                            <Form.Label><b>Related Programs</b> eg. prog;prog</Form.Label>
                            <Form.Control ref='program_related' name="program_related" as="textarea" value={this.state.program.program_related || ''} onChange={this.onInputChange} />
                        </Form.Group> */}

                        <Form.Group controlId="control-program_application_steps">
                            <Form.Label><b>Application Steps</b> eg. Fill;submit</Form.Label>
                            <Form.Control ref='program_application_steps' name="program_application_steps" as="textarea" value={this.state.program.program_application_steps || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_application_link">
                            <Form.Label><b>Application Link</b> </Form.Label>
                            <Form.Control ref='program_application_link' name="program_application_link" type="text" value={this.state.program.program_application_link || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_application_fee">
                            <Form.Label><b>Application Fee</b> </Form.Label>
                            <Form.Control ref='program_application_fee' name="program_application_fee" type="text" value={this.state.program.program_application_fee || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_timeframe">
                            <Form.Label><b>Application Timeframe</b> </Form.Label>
                            <Form.Control ref='program_timeframe' name="program_timeframe" type="text" value={this.state.program.program_timeframe || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        {/* <Row>
                            <Col>
                                <Form.Group controlId="control-program_date_start">
                                    <Form.Label><b>Program Start Date</b></Form.Label>
                                    <Form.Control ref='program_date_start' name="program_date_start" type="text" value={this.state.program.program_date_start || ''} onChange={this.onInputChange} />
                                </Form.Group>
                            </Col>
                            <Col style={{display:'flex', alignItems:'center'}}>
                                <Form.Group controlId='control-program_date_end' style={{marginBottom:'0'}}>
                                    {progEnd}
                                </Form.Group>
                            </Col>
                        </Row> */}

                        <Form.Group controlId="control-program_legislation_name">
                            <Form.Label><b>Program Legislation Text</b></Form.Label>
                            <Form.Control ref='program_legislation_name' name="program_legislation_name" type="text" value={this.state.program.program_legislation_name || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_legislation_links">
                            <Form.Label><b>Program Legislation Link</b></Form.Label>
                            <Form.Control ref='program_legislation_links' name="program_legislation_links" type="text" value={this.state.program.program_legislation_links || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_admin">
                            <Form.Label><b>Program Administrator</b></Form.Label>
                            <Form.Control ref='program_admin' name="program_admin" type="text" value={this.state.program.program_admin || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_admin_phone">
                            <Form.Label><b>Program Administrator Phone</b></Form.Label>
                            <Form.Control ref='program_admin_phone' name="program_admin_phone" type="text" value={this.state.program.program_admin_phone || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_admin_email">
                            <Form.Label><b>Program Administrator Email </b></Form.Label>
                            <Form.Control ref='program_admin_email' name="program_admin_email" type="text" value={this.state.program.program_admin_email || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_admin_news_link">
                            <Form.Label><b>News Link </b>eg. title: url; title: url; title: url; </Form.Label>
                            <Form.Control ref='news_link' name="news_link" as="textarea" value={this.state.program.news_link || ''} onChange={this.onInputChange} />
                        </Form.Group>
                        
                        <Row>
                            <Col>
                                {/* <Form.Group controlId='control-strategy'>
                                    <Form.Label><b>Strategy</b></Form.Label>
                                    {listStratCheck}
                                </Form.Group> */}

                                <Form.Group controlId='control-asset_class'>
                                    <Form.Label><b>Asset Class</b></Form.Label>
                                    {listAssestCheck}
                                </Form.Group>

                                <Form.Group controlId='control-additional'>
                                    <Form.Label><b>Additional</b></Form.Label>
                                    {listAdditionalCheck}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='control-category'>
                                    <Form.Label><b>Category</b></Form.Label>
                                    {listTypeCheck}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="control-program_primary_type">
                                    {progPrimaryTypeCheck}
                                    <Form.Control as="select" onChange={this.handlePrimaryChange}>
                                        <option>{this.state.program.program_primary_type}</option>
                                        <option>Property Tax</option>
                                        <option>Federal Income Tax</option>
                                        <option>State Income Tax</option>
                                        <option>Business Tax</option>
                                        <option>Capital Gains Tax</option>
                                        <option>Sales &amp; Use Tax</option>
                                        <option>Mortgage Recording Tax</option>
                                        <option>Transfer Tax</option>
                                        <option>Other Tax</option>
                                        <option>Grant</option>
                                        <option>Financing</option>
                                        <option>Custom Duty</option>
                                        <option>Permit Fee</option>
                                        <option>Utility Cost</option>
                                        <option>Zoning Bonus</option>
                                        <option>Bidding Priority</option>
                                        <option>Rebate</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="control-program_alert">
                            <Form.Label><b>Program Alert</b></Form.Label>
                            <Form.Control ref='program_alert' name="program_alert" as="textarea" value={this.state.program.program_alert || ' '} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-eligible_project_type">
                            {eligProjTypeCheck}
                            {eligProjType}
                        </Form.Group>

                        <Form.Group controlId="control-eligible_project_status">
                            {eligProjStatusCheck}
                            {eligProjStatus}
                        </Form.Group>

                        <Form.Group controlId="control-program_approval_process" md={4}>
                            {progAppProcessCheck}
                            <Col md={4} style={{padding:'0px'}}>
                                <Form.Control as="select" onChange={this.handleApprovalChange}>
                                    {this.state.program.program_approval_process === 'Competitive' &&
                                        <option>Competitive / Discretionary</option>
                                    }
                                    
                                    {this.state.program.program_approval_process === 'As-of-right' &&
                                        <option>As-of-right</option>
                                    }
                                    <option value='As-of-right' > As-of-right</option>
                                    <option value='Competitive'>Competitive / Discretionary</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group controlId="control-program_beneficiary">
                            {progBenefitCheck}
                            {projBenefit}
                        </Form.Group>

                        <Form.Group controlId="control-program_loan-type">
                            <Form.Label><b>Loan Type</b></Form.Label>
                            {loanType}
                        </Form.Group>

                        <Form.Group controlId="control-program_loan-seniority">
                            <Form.Label><b>Loan Seniority</b></Form.Label>
                            {loanSeniorioty}
                        </Form.Group>

                        <Form.Group controlId="control-program_forgivable">
                            <Form.Label><b>Forgivable</b></Form.Label>
                            <Form.Check custom type="checkbox" name='forgivable' label='' defaultChecked={this.state.program.forgivable} onChange={this.handleCheckOnChange}/>
                        </Form.Group>

                        <Form.Group controlId="control-affordable_min_num_units">
                            <Form.Label><b>Min # of units affordable </b></Form.Label>
                            <Form.Control ref='affordable_min_num_units' name="affordable_min_num_units" type="text" value={this.state.program.affordable_min_num_units || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-affordable_min_percent_units">
                            <Form.Label><b>Min % of units affordable </b>eg. as decimal</Form.Label>
                            <Form.Control ref='affordable_min_percent_units' name="affordable_min_percent_units" type="text" value={this.state.program.affordable_min_percent_units || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-affordable_max_percent_ami">
                            <Form.Label><b>Max % AMI </b>eg. as decimal</Form.Label>
                            <Form.Control ref='affordable_max_percent_ami' name="affordable_max_percent_ami" type="text" value={this.state.program.affordable_max_percent_ami || ''} onChange={this.onInputChange} />
                        </Form.Group>

                        <Form.Group controlId="control-program_start_date">
                            <Form.Label><b>Program Inception </b>eg. yyyy-mm-dd</Form.Label>
                            {startDateInp}
                        </Form.Group>

                        <Form.Group controlId="control-program_end_date">
                            <Form.Label><b>Program Ended </b>eg. yyyy-mm-dd</Form.Label>
                            {endDateInp}
                        </Form.Group>

                        <Form.Group controlId="control-program_related_conflict">
                            <Form.Label><b>Related / Conflicting Programs</b></Form.Label>
                            <div style={{display:'flex', marginBottom:'10px', justifyContent:'space-between'}}>
                                {/* <Form.Control ref='program_related_conflict' name='program_related_conflict' type='text' value='' onChange={this.onInputChange} style={{width:'500px'}}/> */}

                            <Autocomplete
                                options={programList}
                                getOptionLabel={(option) => `${option.program_name} / ${option.program_admin}`}
                                onChange={this.handleSuggestRel}
                                style={{width:'500px'}}
                                renderInput={(params) => <TextField {...params} label='Program Name / Program Admin' variant='outlined' />}
                            />
                                <Button variant='success' onClick={this.handleProgRel}>Add Related</Button>
                                <Button variant='danger' onClick={this.handleProgCon}>Add Conflict</Button>
                            </div>
                            <div style={{border:'solid 1px', height:'200px', overflowY: 'scroll'}}>
                                    {relConEntries}
                            </div>
                        </Form.Group>

                    </Form>
                </Container>
            </>
        );
    }
}

// const strategyCat = {
//     strat_reno: 'Renovation',
//     strat_new: 'New Construction',
//     strat_exist: 'Existing',
// }

const typeCat = {
    type_property_tax: 'Property Tax',
    type_federal_income_tax: 'Federal Income Tax',
    type_state_income_tax: 'State Income Tax',
    type_business_tax: 'Business Tax',
    type_capital_gains_tax: 'Capital Gains Tax',
    type_sales_tax: 'Sales & Use Tax',
    type_mortgage_recording_tax: 'Mortgage Recording Tax',
    type_transfer_tax: 'Transfer Tax',
    type_other_tax: 'Other Tax',
    type_grant: 'Grant',
    type_financing: 'Financing',
    type_customs_duty: 'Custom Duty',
    type_permit_fee: 'Permit Fee',
    type_energy: 'Utility Cost',
    type_zoning: 'Zoning Bonus',
    type_bidding: 'Bidding Priority',
    type_rebate: 'Rebate'
}

const assetClassCat = {
    class_multi_lg: 'Multifamily >50 units',
    class_multi_sm: 'Multifamily <50 units',
    class_other_residential: 'Other Residential / Homeowner',
    class_retail: 'Retail',
    class_office: 'Office',
    class_other_commercial: 'Other Commercial',
    class_manufacturing: 'Manufacturing',
    class_other_industrial: 'Other Industrial'
}

const additionalCat = {
    cat_affordable: 'Affordable Housing',
    // cat_asofright: 'As-of-right',
    cat_bldg_preservation: 'Building Preservation',
    // cat_design_standards: 'Design & Construction',
    cat_cleanup: 'Environmental Cleanup',
    cat_green: 'Green Building',
    // cat_operating: 'Operating Business',
    // cat_sci_tech: 'Science & Technology',
    cat_public_partner: 'Public / Nonprofit Partner',
    cat_syndicated: 'Can be syndicated'
}

const newProgram = {
    id: '',
    program_name: '',
    program_region: '',
    program_jurisdiction: '',
    program_link: '',
    program_headline: '',
    program_summary: '',
    program_requirements: '',
    program_benefit: '',
    program_notes: '',
    program_conflicts: '',
    program_related: '',
    program_application_steps: '',
    program_application_link: '',
    program_application_fee: '',
    program_timeframe: '',
    program_date_start: '',
    program_date_end: false,
    program_legislation_name: '',
    program_legislation_links: '',
    program_admin: '',
    program_admin_phone: '',
    program_admin_email: '',
    boundary_specification: '',
    program_alert: '',
    program_boundary: '',
    boundary_implementation: '',
    boundary_implementation_notes: '',
    boundary_specification_notes: '',
    boundary_incomplete: false,
    strat_new: false,
    strat_reno: false,
    strat_exist: false,
    class_multi_lg: false,
    class_multi_sm: false,
    class_other_residential: false,
    class_retail: false,
    class_office: false,
    class_other_commercial: false,
    class_manufacturing: false,
    class_other_industrial: false,
    cat_design_standards: false,
    cat_affordable: false,
    cat_asofright: false,
    cat_public_partner: false,
    cat_bldg_preservation: false,
    cat_operating: false,
    cat_cleanup: false,
    cat_green: false,
    cat_sci_tech: false,
    type_property_tax: false,
    type_federal_income_tax: false,
    type_state_income_tax: false,
    type_business_tax: false,
    type_capital_gains_tax: false,
    type_sales_tax: false,
    type_mortgage_recording_tax: false,
    type_transfer_tax: false,
    type_other_tax: false,
    type_grant: false,
    type_financing: false,
    type_customs_duty: false,
    type_permit_fee: false,
    type_energy: false,
    type_zoning: false,
    type_bidding: false,
    type_rebate: false,
    eligible_project_type: [],
    eligible_project_status: [],
    loan_type: [],
    loan_seniority: [],
    forgivable: false,
    program_beneficiary: [],
    program_primary_type: '',
    program_start_date: '',
    program_end_date: '',
    affordable_min_num_units: null,
    affordable_min_percent_units: null,
    affordable_max_percent_ami: null,
    program_approval_process: '',
    news_link:'',
    cat_syndicated: false,
}

const multiSelectProject = {
    "New Construction": "eligible_project_type",
    "Renovation": "eligible_project_type",
    "Stabilized": "eligible_project_type",
    "Pre-development": "eligible_project_status",
    "Development": "eligible_project_status",
    "Post-development": "eligible_project_status",
    "Owner": "program_beneficiary",
    "Tenant": "program_beneficiary",
    "Contractor": "program_beneficiary",
    "Construction": "loan_type",
    "Construction-to-Permanent" : "loan_type",
    "Permanent" : "loan_type",
    "Senior" : "loan_seniority",
    "Subordinate" : "loan_seniority"
}

const eligible_project_type = ['New Construction', 'Renovation', 'Stabilized']
const eligible_project_status = ['Pre-development', 'Post-development'] // 'Development' removed
const program_beneficiary = ['Owner', 'Tenant', 'Contractor']
const loan_type = ['Construction', 'Construction-to-Permanent', 'Permanent']
const loan_seniority = ['Senior', 'Subordinate']

function makeCatCheckbox(state, obj, handleFunc){
    let result = []
    for(const cat in obj){
        if(state[cat]){
            result.push(<Form.Check key={cat} id={cat} custom type='checkbox' name={cat} label={obj[cat]} defaultChecked={true} onChange={handleFunc}/>)
        } else {
            result.push(<Form.Check key={cat} id={cat} custom type='checkbox' name={cat} label={obj[cat]} onChange={handleFunc}/>)
        }
    }
    return result
}

function makeMultiTypeCheckbox(state, arr, projType, handleFunc){
    let result = []

    arr.forEach(e => {
        if(state[projType] && state[projType].includes(e)){
            result.push(<Form.Check key={e} id={e} custom type='checkbox' name={e} label={e} defaultChecked={true} onChange={handleFunc}/>)
        } else {
            result.push(<Form.Check key={e} id={e} custom type='checkbox' name={e} label={e} onChange={handleFunc}/>)
        }
    })
    return result
}

const regionArray = ['United States', 'Connecticut', 'New Jersey', 'New York', 'Massachusetts','Pennsylvania', 'Virginia', 'Florida', 'Washington D.C.', 'Maryland', 'North Carolina', 'South Carolina', 'Ohio', 'Georgia', 'Texas', 'Illinois', 'California', 'Oregon', 'Washington','Michigan']

function progIdList(arr, func){
    let idArr = []
    let result = {}

    regionArray.forEach(i => {
        result[i] = []
    })

    arr.forEach((i) => {
        idArr.push(i.id)
    })
    idArr.sort()

    idArr.forEach((e) => {
        const chrs = e.slice(0,2)
        switch(chrs){
            case 'NY':
                result['New York'].push(e)
                break;
            case 'NJ':
                result['New Jersey'].push(e)
                break;
            case 'CT':
                result['Connecticut'].push(e)
                break;
            case 'US':
                result['United States'].push(e)
                break;
            case 'MA':
                result['Massachusetts'].push(e)
                break;
            case 'PA':
                result['Pennsylvania'].push(e)
                break;
            case 'VA':
                result['Virginia'].push(e)
                break;
            case 'FL':
                result['Florida'].push(e)
                break;
            case 'DC':
                result['Washington D.C.'].push(e)
                break;
            case 'NC':
                result['North Carolina'].push(e)
                break;
            case 'SC':
                result['South Carolina'].push(e)
                break;
            case 'OH':
                result['Ohio'].push(e)
                break;
            case 'GA':
                result['Georgia'].push(e)
                break;
            case 'TX':
                result['Texas'].push(e)
                break;
            case 'IL':
                result['Illinois'].push(e)
                break;
            case 'CA':
                result['California'].push(e)
                break;
            case 'OR':
                result['Oregon'].push(e)
                break;
            case 'MD':
                result['Maryland'].push(e)
                break;
            case 'WA':
                result['Washington'].push(e)
                break;
            case 'MI':
                result['Michigan'].push(e)
                break;
            default:
                //
        }
    })

    func(result)
}

function makeRegionOptionList(arr){
    const result = []
    arr.forEach((i,x) => {
        result.push(
            <option key={x}>{i}</option>
        )
    })
    return result
}

function formatProgramNames(arr){
    let result = []

    arr.forEach(e => {
        if(e.program_name){
            result.push(e)
        }
    })

    return result
}


function createRelProgEntry(progsArr, relArr, conArr, relFunc, conFunc){
    let relEntryArr = []
    let conEntryArr = []
    let result = []
    
    progsArr.forEach((i) => {
        if(relArr.includes(i.id)){
            relEntryArr.push(i)
        }
        if(conArr.includes(i.id)){
            conEntryArr.push(i)
        }
    })

    if(relEntryArr.length < 1 && conEntryArr.length < 1){return ''}

    relEntryArr.forEach((e,dex) => {
        result.push(
            <Form.Group controlId={'control-'+ e.program_name} key={dex} style={{display:'flex', marginBottom:'.25rem'}}>
                <Form.Control key={dex} type="text" value={`${e.program_name} / ${e.program_admin}`} style={{color:'green'}} disabled/>
                <Button variant="danger" onClick={() => relFunc(e.id)}><HighlightOffOutlinedIcon/></Button>
            </Form.Group>
        )
    })

    conEntryArr.forEach((e,dex) => {
        result.push(
            <Form.Group controlId={'control-'+ e.program_name} key={dex+20} style={{display:'flex', marginBottom:'.25rem'}}>
                <Form.Control key={dex+10} type="text" value={`${e.program_name} / ${e.program_admin}`} style={{color:'red'}} disabled/>
                <Button variant="danger" onClick={() => conFunc(e.id)}><HighlightOffOutlinedIcon/></Button>
            </Form.Group>
        )
    })

    return result
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    builder: state.builder.menuConfig
    }
}

export default connect(mapStateToProps, builderActions)(AdminProgram);