
const { googleMapApiKey } = require('../../../config');
const initGoogleMap = () => {
    if(document.getElementById('google-maps-api')){return Promise.resolve()}
    const googleMapScript = document.createElement('script')
    googleMapScript.id = 'google-maps-api'
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=places,drawing`
    window.document.body.appendChild(googleMapScript)

    return new Promise((resolve)=>{
        googleMapScript.addEventListener('load', resolve)
    })
}



module.exports = {
    initGoogleMap
}