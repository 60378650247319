import React, { Component } from 'react';
import { connect } from 'react-redux';
import {actions as builderActions} from "../../../_metronic/ducks/builder";
import { getSearchedLocationData, getSearchedLocationBBL, getShallowProgramMatch, getUtilGasData, createNotionProject, incrementSubUsedAmount, getUserAccount } from '../../crud/report.crud';
import Container from 'react-bootstrap/Container';
import MenuConfig from "../../../_metronic/layout/MenuConfig.js";
import AdminMenuConfig from "../../../_metronic/layout/AdminMenuConfig.js";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../../../_metronic/_assets/sass/pages/report/generate-report.scss';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Address from './address';
import GenProjectParameters from './gen-project-parameters';
import Spinner from 'react-bootstrap/Spinner';
const {initGoogleMap} = require('./googleMapApi');


class GenerateNotionProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user:{},
            notionClicked: false,
            project_parameters: {},
            googleMapsLoaded: false,
            addressValidatedMsg: false,
            out_of_tri_state_msg: false,
            sub_limit_msg: false,
            a_la_carte_msg: false,
            setValidated: false,
            showSearchedPoint: false,
            searchedPoint: {
                address: '',
                latlng: [],
                admin_area_lvl_1: '',
                admin_area_lvl_2: '',
                locality: '',
                sublocality: '',
                postal_code: '',
                country: '',
                street_number: '',
                route: '',
                report_name: '',
                censusTractName: '',
                censusTractGeoId: '',
                zcta: '',
                bbl: null,
                borough: null,
                borough_code: null,
                block: null,
                lot: null,
                zone_dist_1: null,
                zone_dist_2: null,
                zone_dist_3: null,
                zone_dist_4: null,
                QPZ: false,
                NMTC: false,
                LIHTC: false,
                DDA: false,
                outOfTriState: false,
                util_gas: null,
                util_electric: null,
                project_name: '',
                type: [],
                status: '',
                asset_class: [],
                approval_process: [],
                affordable_housing: true,
                affordable_max_percent_units: 0,
                affordable_min_percent_ami: 0,
                green_building: true,
                env_remediation: true,
                historic_preservation: true,
                public_non_profit_partner: true
            },
            parsePolyInfo: {
                covers: false,
                intersect: false,
                census: []
            },
            modalShow: false,
            report_id: null,
            move_pin: 0
        };
        this.googleMapRef = React.createRef();
    }

    componentDidMount() {
      initGoogleMap()
        .then(()=>{
            this.googleMap = this.createGoogleMap();
            this.input = this.getinput();
          })
        .catch(err => {
          console.log('error googlemap center', err.message);
        })
      
      this.handleGetUserAccount()

      if(this.props.user.is_admin){
        this.props.setMenuConfig(AdminMenuConfig)
      } else {
        this.props.setMenuConfig(MenuConfig)
      }

      this.reloadInterval = setInterval(() => {
        window.location.reload();
      }, 1800000);
    }

    componentWillUnmount() {
      clearInterval(this.reloadInterval);
    }

    handleUserLocationMap = () =>{
      const navPromise = new Promise((resolve, reject) => {
        //takes a while to load on local, keep an eye on
        if(navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            }, error => {
                resolve({ lat: 40.7549, lng: -73.9840 })
                // console.error(error)
              }, {
                timeout: 9000,
                maximumAge: 10000,
                enableHighAccuracy: true
              })
        } else {
            resolve({ lat: 40.7549, lng: -73.9840 })
          }
      })

      return navPromise
        .then((center) => {
          initGoogleMap()
            .then(()=>{
                this.googleMap = this.createGoogleMap(center);
                this.input = this.getinput();
              })
            })
        .catch(err => {
          console.log('error googlemap center', err.message);
        })
    }
    
    createGoogleMap = () =>  {
      return new window.google.maps.Map(this.googleMapRef.current, {
        zoom: 4.8,
        center: { lat: 37.5, lng: -98.35},
        disableDefaultUI: true,
        scrollwheel: true,
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_CENTER
        },
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#fafafa"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#000000"
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#b4b4b4"
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "water",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c3cdd7"
              }
            ]
          }
        ]
      })
    }

    handleGetUserAccount = () => {
      getUserAccount()
        .then(res => {
          this.setState({
            user: res
          })
        })
        .catch(err => {

        })
    }

    handleSubmit = (e) => {
      if(this.state.searchedPoint.outOfTriState){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          out_of_tri_state_msg: true
        })
        return undefined
      }
      
      if (this.state.searchedPoint.address === '') {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          addressValidatedMsg: true
        })
      }

      if(this.state.searchedPoint.address !== '') {
        e.preventDefault();
        const data = this.state.searchedPoint

        //helper function to handle account credit check
        //maybe just make routes instead of relying on redux store
        const userData = this.state.user
        if(userData.is_sub){
            incrementSubUsedAmount()
              .then(res => {
                createNotionProject(data)
                this.setState({
                    notionClicked: true
                })
    
                setTimeout(() => {
                    window.location.reload()
                }, 5000)
              })
              .catch(err => {
                alert('Something went wrong, refresh and try again later')
              })
        } else {
          if(userData.a_la_carte_credit > 0){
            createNotionProject(data)
            
            this.setState({
            notionClicked: true
            })

            setTimeout(() => {
                window.location.reload()
            }, 5000)
        
            } else {
              this.setState({
                a_la_carte_msg: true
              })
            }
        }
      }
    }

    handleAddress = (e) => {
      const address = e.target.value

      this.setState(prevState => ({
        searchedPoint: {
            ...prevState.searchedPoint,
            address: address
        }
      }))

      if(this.state.out_of_tri_state_msg){
        this.setState({
          out_of_tri_state_msg: false
        })
      }

      if(this.state.addressValidatedMsg) {
        this.setState({
          addressValidatedMsg: false
        })
      }
    }

    handleReportName = (e) => {
      const reportName = e.target.value

      this.setState(prevState => ({
        searchedPoint: {
            ...prevState.searchedPoint,
            report_name: reportName
        }
      }))
    }
    
    getinput = () => {
        const map = this.googleMap;
        let input = document.getElementById('pac-input');
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        const geocoder = new window.google.maps.Geocoder();
        const contentStr = '<h6>Click on Map to move pin</h6>'
        const infowindow = new window.google.maps.InfoWindow({
          content: contentStr,
        });


        const marker = new window.google.maps.Marker({
          icon: {
            url: require("../../assets/r-map-downArrow.svg"),
            scaledSize: new window.google.maps.Size(30,30)
          },
          map: map
        });

        const geocodePosition = (pos) => {
          geocoder.geocode({
            latLng: pos
          }, function(responses) {
            if (responses && responses.length > 0) {
              input.value = responses[0].formatted_address
              organizeLocationData(responses[0])
            } else {
              console.log('Error Cannot determine address at this location.');
            }
          });
        }


        const collectPointInfo = ( searchedaddress, searchedLatLng, admin1, admin2, local, postal, country_name, streetNumber, route, sublocal, outOfTriState) => {
          this.setState(prevState => ({
                searchedPoint: {
                    ...prevState.searchedPoint,
                    address: searchedaddress,
                    latlng: searchedLatLng,
                    admin_area_lvl_1: admin1,
                    admin_area_lvl_2: admin2,
                    locality: local,
                    postal_code: postal,
                    country: country_name,
                    street_number: streetNumber,
                    route: route,
                    sublocality: sublocal,
                    outOfTriState: outOfTriState
                },
                showSearchedPoint: true
            }))
        }

        const handleSearchGisData = (lat,lng, admin2, admin1) =>{
          return getSearchedLocationData(lat,lng)
            .then(res => {
              const [{geoid, namelsad, zcta5ce10}] = res
              this.setState(prevState => ({
                searchedPoint: {
                  ...prevState.searchedPoint,
                  censusTractName: namelsad,
                  censusTractGeoId: geoid,
                  zcta: zcta5ce10,
                  QPZ: false,
                  NMTC: false,
                  LIHTC: false,
                  DDA: false
                }
              }))
              //gets program ids matched by censustract
              //if any of them match the important 4 they are set in state as true
              return getShallowProgramMatch(geoid, zcta5ce10, admin2, admin1)
                .then(shallowRes => {
                  //'US0030', 'US0029', 'US0027','US0026' checks
                  shallowRes.forEach(progObj =>{
                    if(progObj.program_id ==='US0030') {
                      this.setState(prevState => ({
                        searchedPoint: {
                          ...prevState.searchedPoint,
                          QPZ: true
                        }
                      }))
                    }
                    if(progObj.program_id === 'US0029') {
                      this.setState(prevState => ({
                        searchedPoint: {
                          ...prevState.searchedPoint,
                          NMTC: true
                        }
                      }))
                    }
                    if(progObj.program_id === 'US0027') {
                      this.setState(prevState => ({
                        searchedPoint: {
                          ...prevState.searchedPoint,
                          LIHTC: true
                        }
                      }))
                    }
                    if(progObj.program_id === 'US0026') {
                      this.setState(prevState => ({
                        searchedPoint: {
                          ...prevState.searchedPoint,
                          DDA: true
                        }
                      }))
                    }
                  })
                })
                .catch(err => {
                  console.log('err in get shallow programMatch', err.message)
                })
            })
            .catch(err => {
              console.log('error in handleSearchGisData' ,err.message)
              this.setState(prevState => ({
                searchedPoint: {
                  ...prevState.searchedPoint,
                  censusTractName: '',
                  censusTractGeoId: '',
                  zcta: ''
                }
              }))
            })
        }
        //Is making the api req for bbl data
        //If err or no bbl state will set as null 
        const handleBblData = (lat, lng, srid, geoType, spatialRel) => {
          getSearchedLocationBBL(lat, lng, srid, geoType, spatialRel)
            .then(res => {
              //set state with bbl info from api req
              const {BBL, Borough, BoroCode, Block, Lot, ZoneDist1, ZoneDist2, ZoneDist3, ZoneDist4} = res
                this.setState(prevState => ({
                  searchedPoint: {
                    ...prevState.searchedPoint,
                    bbl: BBL,
                    borough: Borough,
                    borough_code: BoroCode,
                    block: Block,
                    lot: Lot,
                    zone_dist_1: ZoneDist1,
                    zone_dist_2: ZoneDist2,
                    zone_dist_3: ZoneDist3,
                    zone_dist_4: ZoneDist4
                  }
                })) 
            })
            .catch(err => {
              console.log('err in handleBblData NO BBL data (ignore if not in NYC)', err.message)
              this.setState(prevState => ({
                searchedPoint: {
                  ...prevState.searchedPoint,
                  bbl: null,
                  borough: null,
                  borough_code: null,
                  block: null,
                  lot: null,
                  zone_dist_1: null,
                  zone_dist_2: null,
                  zone_dist_3: null,
                  zone_dist_4: null
                }
              })) 
            })
        }
        //Used if user types in un autocompleted address and hits enter
        const handleIncAddress = (e) => {
          if(e){
            this.setState({
              addressValidatedMsg: true
            })
          } else {
              this.setState({
                addressValidatedMsg: false
              })
          }
        }

        const handleUtilGas = (lat,lng) => {
          getUtilGasData(lat,lng)
            .then(res => {
              const [{comp_short}] = res

              if(comp_short){
                this.setState(prevState => ({
                  searchedPoint: {
                    ...prevState.searchedPoint,
                    util_gas: comp_short
                  }
                }))
              }
            })
            .catch(err => {
              console.log('error in handleUtilGas: ' ,err.message)
            })
        }

        const organizeLocationData = (placeData) => {
          let searchedaddress = '';
          let searchedLatLng = [];
          let admin1 = '';
          let admin2 = '';
          let local = '';
          let postal = '';
          let country_name = '';
          let sublocal = '';
          let streetNumber = '';
          let route = '';
          
          const triState = ['New York', 'New Jersey', 'Connecticut', 'Massachusetts','Pennsylvania', 'Virginia', 'Florida', 'Washington D.C.', 'Maryland', 'North Carolina', 'South Carolina', 'Ohio', 'Georgia', 'Texas', 'Illinois']
          let outOfTriState = false;

          //removes validate msg if user previously had an incorrect address
          const latCoor = placeData.geometry.location.lat();
          const lngCoor = placeData.geometry.location.lng();
          const addressArr = placeData.address_components;
          const trackPinCount = this.state.move_pin
          searchedaddress = input.value;
          searchedLatLng = [latCoor, lngCoor]
          // console.log('addy', placeData)
          //plots marker on autocompleted address
          marker.setPosition(placeData.geometry.location);
          marker.setVisible(true);

          if(trackPinCount <= 0){
            infowindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          }

          this.setState({
            move_pin: this.state.move_pin + 1
          })
          window.google.maps.event.trigger(map, "resize");
          map.panTo(marker.getPosition());
          map.setZoom(17)
          
          // pulls desired data (address_component) from googles autocomplete search 
          addressArr.map((obj) => {
            if(obj.types.includes('locality')) {
                return local = obj.long_name
            } else if(obj.types.includes('administrative_area_level_2')) {
                return  admin2 = obj.long_name
            } else if(obj.types.includes('administrative_area_level_1')) {
              //D.C. -> Washington D.C.
              if(obj.long_name === 'District of Columbia'){
                return admin1 = 'Washington D.C.'
              }
                return admin1 = obj.long_name
            } else if(obj.types.includes('sublocality_level_1')) {
                return sublocal = obj.long_name
            } else if(obj.types.includes('country')) {
                return country_name = obj.long_name
            } else if(obj.types.includes('postal_code')) {
                return postal = obj.long_name
            } else if(obj.types.includes('street_number')) {
                return streetNumber = obj.long_name
            } else if(obj.types.includes('route')) {
                return route = obj.long_name
            }
            return undefined
          })

          //Check if address is outside of the tristate
          if(!triState.includes(admin1)) {
            outOfTriState = true
          }

          //hit data.ny.gov for util_gas comp_short
          handleUtilGas(latCoor,lngCoor)

          //hitting api to return which data on entered address /zcta,censustract name and id, taking admin2/1 for shallow check
          handleSearchGisData(latCoor,lngCoor, admin2, admin1)
          
          //Checking every address for bbl data to also reset bbl state data to null
          handleBblData(latCoor, lngCoor, 4326, 'esriGeometryPoint', 'esriSpatialRelWithin')

          //Sets state with data from searched location
          collectPointInfo(searchedaddress, searchedLatLng, admin1, admin2, local, postal, country_name, streetNumber, route, sublocal, outOfTriState);
        }

        autocomplete.setFields(
            ['address_components', 'geometry']
        );
        //Update info and location if an address is typed in
        autocomplete.addListener('place_changed', function(){
          const placeInfo = autocomplete.getPlace();

          if(placeInfo.geometry){
            //removes validate msg if user previously had an incorrect address
            handleIncAddress(false)
            organizeLocationData(placeInfo)
            
          } else {
            //triggers address validation msg to use an autocomplete option for the address
            handleIncAddress(true)
          }
        });
        
        // Update current position info if a loation is clicked.   
        map.addListener('click', function(mapsMouseEvent) {
          geocodePosition(mapsMouseEvent.latLng)
          infowindow.close()
        })
    }

    handleShowModal = () =>{
      this.setState({
        modalShow: true
      })
    }

    //upon canceling report creating process, onhide will trigger, reseting the form and necessary values
    handleHideModal = () => {
      // let input = document.getElementById('pac-input');
      // input.value= ''

      // this.handleUserLocationMap()
      
      this.setState({
        modalShow: false,
        // searchedPoint: newSearchedPoint
      })

      //handle decrement sub used, increment alacarte used on project cancelation
    }

    render(){
        // console.log('userstuff?', this.props.user)
        const censusTractGeoId = this.state.searchedPoint.censusTractGeoId;
        const addressBbl = this.state.searchedPoint.bbl;
        const addressInputMsg = this.state.addressValidatedMsg? <div style={{color: 'red'}}>Please autocomplete or click a location*</div> : null
        const outOfTriStateMsg = this.state.out_of_tri_state_msg? <p style={{color: 'red'}}> Selected location is outside of currently supported markets</p>: null
        const subLimitMsg = this.state.sub_limit_msg? <div style={{color: 'red'}}>Account has exceeded usage limits — please contact support</div> : null
        const aLaCarteMsg = this.state.a_la_carte_msg? <div style={{color: 'red'}}>Account has exceeded usage limits — please contact support</div> : null
        const qpzValue = this.state.searchedPoint.QPZ ? <h6 className='designation--bold'><CheckCircleOutlinedIcon color='secondary' style={{marginRight: '5px'}}/>QUALIFIED OPPORTUNITY ZONE (QOZ)</h6> : <h6 className='designation--bullet-dis'><HighlightOffOutlinedIcon style={{marginRight: '5px'}}/>QUALIFIED OPPORTUNITY ZONE (QOZ)</h6>
        const nmtcValue = this.state.searchedPoint.NMTC ? <h6 className='designation--bold'><CheckCircleOutlinedIcon color='secondary' style={{marginRight: '5px'}}/>NMTC LOW-INCOME COMMUNITY (LIC)</h6> : <h6 className='designation--bullet-dis'><HighlightOffOutlinedIcon style={{marginRight: '5px'}}/>NMTC LOW-INCOME COMMUNITY (LIC)</h6>
        const lihtcValue = this.state.searchedPoint.LIHTC ? <h6 className='designation--bold'><CheckCircleOutlinedIcon color='secondary' style={{marginRight: '5px'}}/>LIHTC QUALIFIED CENSUS TRACT (QCT)</h6> : <h6 className='designation--bullet-dis'><HighlightOffOutlinedIcon style={{marginRight: '5px'}}/>LIHTC QUALIFIED CENSUS TRACT (QCT)</h6>
        const ddaValue = this.state.searchedPoint.DDA ? <h6 className='designation--bold'><CheckCircleOutlinedIcon color='secondary' style={{marginRight: '5px'}}/>LIHTC DIFFICULT DEVELOPMENT AREA (DDA)</h6> : <h6 className='designation--bullet-dis'><HighlightOffOutlinedIcon style={{marginRight: '5px'}}/>LIHTC DIFFICULT DEVELOPMENT AREA (DDA)</h6>
        const notionButtonState = this.state.notionClicked ? <Spinner animation='border' variant='success' style={{height:'60px', width:'60px'}}/> : <div>Generate Notion Project</div>

        return (
            <Container fluid style={{padding:'0'}}>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                  <div className='map-output'>
                    <Col md={5} style={{position:'absolute', marginTop:'20px', zIndex:'1', maxWidth: '440px'}}>
                      <Form.Group controlId='pac-input'>
                        <Form.Control 
                          type='input' 
                          placeholder='Enter an address...'
                          onChange={this.handleAddress}
                        />
                      </Form.Group>
                    </Col>
                    <div
                        className='map-canvas'
                        ref={this.googleMapRef}
                    >
                    </div>
                    <div className= 'output'>
                      <div>
                        <h2 style={{margin:'20px', textAlign:'center'}}>Creates Notion Project</h2>
                        <p style={{margin:'20px', textAlign:'center'}}>
                          <b>Operational Markets</b><br/>
                          CT, DC, FL, GA, IL, MA, MD, NJ, NC, NY, OH, PA, SC, VA, TX
                        </p>

                        {/* <p style={{margin:'20px', textAlign:'center'}}>
                          <b>Upcoming Markets</b><br/>
                            CA, MI, OR, WA
                        </p> */}
                      </div>
                      <div>
                            {this.state.showSearchedPoint &&
                            <Card border='light'>
                              <Card.Body>
                                <Address
                                  address={this.state.searchedPoint.address}
                                  street_number={this.state.searchedPoint.street_number}
                                  route={this.state.searchedPoint.route}
                                  locality={this.state.searchedPoint.locality}
                                  admin_lvl_1={this.state.searchedPoint.admin_area_lvl_1}
                                  zcta={this.state.searchedPoint.zcta}
                                />
                              {censusTractGeoId !== '' &&
                                <h6 className='address-input-field'><b>CENSUS TRACT</b> {censusTractGeoId}</h6>
                              }
                                {addressBbl &&
                                <h6 className='address-input-field'><b>BLOCK &amp; LOT</b> {addressBbl}</h6>
                                }
                              </Card.Body>
                            </Card>
                          }
                          {censusTractGeoId && 
                            <Card border='light'>
                                <Card.Body style={{fontWeight:'600'}}>
                                  <h5 className='designation--bold'>Featured Incentives</h5>
                                  {qpzValue}
                                  {nmtcValue}
                                  {lihtcValue}
                                  {ddaValue}
                                </Card.Body>
                            </Card> 
                          }
                        </div>
                        <div style={{padding:'1rem'}}>
                          <div style={{textAlign: 'center', fontSize:'14px'}}>
                            {outOfTriStateMsg}
                            {subLimitMsg}
                            {aLaCarteMsg}
                            {addressInputMsg}
                          </div>
                          <Button variant='success' type="submit" style={{width: '100%'}}>
                              {notionButtonState}
                          </Button>
                          {/* <Button variant='warning' type='button' onClick={this.handleShowModal}>
                            Modal
                          </Button> */}
                        </div>
                    </div>
                  </div>
                  <GenProjectParameters
                    show={this.state.modalShow}
                    onHide={this.handleHideModal}
                    report_id={this.state.report_id}
                    reportPage={false}
                    address={this.state.searchedPoint.address}
                    backdrop='static'
                  />
              </Form>
            </Container>
        );
    }
}

// const newSearchedPoint = {
//     address: '',
//     latlng: [],
//     admin_area_lvl_1: '',
//     admin_area_lvl_2: '',
//     locality: '',
//     sublocality: '',
//     postal_code: '',
//     country: '',
//     street_number: '',
//     route: '',
//     report_name: '',
//     censusTractName: '',
//     censusTractGeoId: '',
//     zcta: '',
//     bbl: null,
//     borough: null,
//     borough_code: null,
//     block: null,
//     lot: null,
//     zone_dist_1: null,
//     zone_dist_2: null,
//     zone_dist_3: null,
//     zone_dist_4: null,
//     QPZ: false,
//     NMTC: false,
//     LIHTC: false,
//     DDA: false,
//     outOfTriState: false,
//     util_gas: null,
//     util_electric: null
// }

const mapStateToProps = state => {
  return {
  user: state.auth.user,
  builder: state.builder.menuConfig
  }
}

export default connect(mapStateToProps, builderActions)(GenerateNotionProject);