import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
// import { getUserReport, getReportProgram } from '../../../app/crud/report.crud';
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";

class Header extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      myReportAddress: '',
      myProgram: []
    }
  }
  headerCommonRef = React.createRef();

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
    // const pathName = this.props.history.location.pathname.split('/')
    // const reportStr = pathName[1]
    // console.log('rep str',reportStr)
    // if(reportStr == 'report_id'){
    //   console.log('param' ,paramRep_id)
    //   const paramRep_id = parseInt(pathName[2])
    //   getUserReport(paramRep_id)
    //     .then(res => {
    //       const [{address}] = res
    //       console.log('address', address)
    //         this.setState({
    //             myReportAddress: address
    //         })
    //     })
    //     .catch((error) => {
    //         // Handle error
    //         console.log('myreport comp error', error);
    //         console.log('error msg', error.message);
    //     });
    //   }
      // getReportProgram(paramRep_id, paramProgram_id)
      //   .then(res => {
      //       this.setState({
      //           program: res
      //       })
      //   })
      //   .catch((error) => {
      //       // Handle error
      //       console.log('myreport comp error', error);
      //       console.log('error msg', error.message);
      //   });
  }

  render() {
    const {
      menuHeaderDisplay,
      headerAttributes,
      headerClasses,
    } = this.props;

    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <AnimateLoading />
        {/* <!-- begin: Header Menu --> */}
        {menuHeaderDisplay && <HMenu />}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
        {!menuHeaderDisplay && <div />}
        <Topbar />
        {/* <!-- end:: Header Topbar --> */}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid"
});

export default connect(mapStateToProps)(Header);
