import axios from "axios";


export function createProjectParameters(formObj){
    let {project_name, type, asset_class, approval_process, affordable_housing, affordable_max_percent_units, affordable_min_percent_ami, green_building, env_remediation, historic_preservation, public_non_profit_partner} = formObj

    if(affordable_max_percent_units === ''){
      affordable_max_percent_units = 0
    } else {
      affordable_max_percent_units = parseInt(affordable_max_percent_units)
    }
  
    if(affordable_min_percent_ami === ''){
      affordable_min_percent_ami = 0
    } else {
      affordable_min_percent_ami = parseInt(affordable_min_percent_ami)
    }

    return axios.post(`users/current/createProjectParameters`, {
        project_name: project_name,
        type: type,
        asset_class: asset_class,
        approval_process: approval_process,
        affordable_housing: affordable_housing,
        affordable_max_percent_units: affordable_max_percent_units,
        affordable_min_percent_ami: affordable_min_percent_ami,
        green_building: green_building,
        env_remediation: env_remediation,
        historic_preservation: historic_preservation,
        public_non_profit_partner: public_non_profit_partner,
    })
    .then(res => {
        return true
    })
    .catch(err => {
        console.log('Unsuccessful proj params: '+ err.message)
        return false
    })
}

export function createReportProjectParameters(formObj, report_id){
  let {project_name, type, asset_class, affordable_housing, affordable_max_percent_units, affordable_min_percent_ami, green_building, env_remediation, historic_preservation, public_non_profit_partner} = formObj

  if(affordable_max_percent_units === ''){
    affordable_max_percent_units = 0
  } else {
    affordable_max_percent_units = parseInt(affordable_max_percent_units)
  }

  if(affordable_min_percent_ami === ''){
    affordable_min_percent_ami = 0
  } else {
    affordable_min_percent_ami = parseInt(affordable_min_percent_ami)
  }

  const id = report_id

  return axios.post(`users/current/createReportProjectParameters`, {
      id:id,
      project_name: project_name,
      type: type,
      asset_class: asset_class,
      affordable_housing: affordable_housing,
      affordable_max_percent_units: affordable_max_percent_units,
      affordable_min_percent_ami: affordable_min_percent_ami,
      green_building: green_building,
      env_remediation: env_remediation,
      historic_preservation: historic_preservation,
      public_non_profit_partner: public_non_profit_partner,
  })
  .then(res => {
      return true
  })
  .catch(err => {
      console.log('Unsuccessful rep proj param: '+ err.message)
      return false
  })
}

export function getUserReportProjectParameters(report_id) {
  return axios.get(`/users/current/reportProjectParameters/${report_id}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('user rep proj parameters err:', error.message);
    })
}

export function getUserProjectParameters() {
  return axios.get(`/users/current/projectParameters`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log('user proj parameters err:', error.message);
    })
}

export function deletePreset(preset_name){
  return axios.delete(`/users/current/deletePreset/${preset_name}`)
  .then((res) => {
    return true
  })
  .catch((error) => {
    return false
  })
}


export function getRedistimateText(){
  return axios.get(`/users/current/redistitmate_text`)
    .then((res) => {
      let result = {}
      
      if(res.data && res.data.length > 0){
        res.data.forEach(obj => {
          result[obj.program_id] = obj.estimate_value
        })
      }
      return result
    })
    .catch((error) => {
      console.log('getRedistimateText err:', error.message);
    })
}

export function getEstimateInputs(report_id){
  return axios.get(`/users/current/getEstimateInputs/${report_id}`)
    .then((res) => {
      let [data] = res.data
      
      if(data){
        for(let prop in data ){
          if(data[prop] === null){
              data[prop] = ''
          }
        }
      }
      
      return data
    })
    .catch((error) => {
      console.log('getEstimateInputs err:', error.message);
    })
}

export function getEstimation(formObj){
  let {report_id, afford_percent, afford_ami, progList} = formObj
  
  if(afford_percent === ''){
    afford_percent = 0
  } else {
    afford_percent = parseInt(afford_percent)
  }

  if(afford_ami === ''){
    afford_ami = 0
  } else {
    afford_ami = parseInt(afford_ami)
  }

  return axios.post(`/users/current/getEstimation`, {
    report_id:report_id,
    afford_percent: afford_percent,
    afford_ami: afford_ami,
    progList: progList
  })
    .then((res) => {
      return res.data

    })
    .catch((error) => {
      console.log('getEstimation err:', error.message);
    })
}

export function createEstimatorInput(formObj){
  // console.log('beforeform', formObj)
  for(let prop in formObj ){
    if(formObj[prop] === '' || formObj[prop] === 0){
        formObj[prop] = null
    }
  }
  // console.log('form', formObj)

  let { report_id, unit_ct, sqft_gross, sqft_green_roof, assess_value_land, assess_value_bldg, assess_value_total, prop_tax_total, prop_tax_land, int_rate, morg_total, lease_term, base_rent_annual, emp_count, emp_cost, rd_exp, dev_cost_land, dev_cost_other_acq, dev_cost_hard, dev_cost_soft, dev_cost_total, proj_cost, dev_cost_mep_only, dev_cost_rehab_only, dev_cost_renew_only, env_cost_rem, env_cost_eng, affordable_max_percent_units, affordable_min_percent_ami, progList } = formObj

  affordable_max_percent_units = parseInt(affordable_max_percent_units)
  affordable_min_percent_ami = parseInt(affordable_min_percent_ami)

  return axios.post(`users/current/createEstimatorInput`, {
    report_id:report_id,
    unit_ct:unit_ct,
    sqft_gross:sqft_gross,
    sqft_green_roof:sqft_green_roof,
    assess_value_land:assess_value_land,
    assess_value_bldg:assess_value_bldg,
    assess_value_total:assess_value_total,
    prop_tax_total:prop_tax_total,
    prop_tax_land:prop_tax_land,
    int_rate:int_rate,
    morg_total:morg_total,
    lease_term:lease_term,
    base_rent_annual:base_rent_annual,
    emp_count:emp_count,
    emp_cost:emp_cost,
    rd_exp:rd_exp,
    dev_cost_land:dev_cost_land,
    dev_cost_other_acq:dev_cost_other_acq,
    dev_cost_hard:dev_cost_hard,
    dev_cost_soft:dev_cost_soft,
    dev_cost_total:dev_cost_total,
    proj_cost:proj_cost,
    dev_cost_mep_only:dev_cost_mep_only,
    dev_cost_rehab_only:dev_cost_rehab_only,
    dev_cost_renew_only:dev_cost_renew_only,
    env_cost_rem:env_cost_rem,
    env_cost_eng:env_cost_eng,
    afford_percent: affordable_max_percent_units,
    afford_ami: affordable_min_percent_ami,
    progList: progList
  })
  .then(res => {
      return res.data
  })
  .catch(err => {
      console.log('Unsuccessful proj estimator inputs: '+ err.message)
      return false
  })
}